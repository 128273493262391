import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import { Configuration, MailApiFactory } from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const mailApi = MailApiFactory(config, baseURL, axios);

export interface PaginatedMailsParams {
  page?: number;
  limit?: number;
  search?: string;
}

enum QUERY_KEYS {
  list = 'mails',
}

export function useMails(params: PaginatedMailsParams) {
  const { page, limit } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, { page, limit, search }],
    async () => {
      const response = await mailApi.findAllMails(page, limit, search);
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your mails.', {
          variant: 'error',
        });
      },
    }
  );
}
