import React, { useCallback, useEffect, useState } from 'react';
import '../global.css';

import '../utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

// fullcalendar
import '@fullcalendar/common/main.min.css';
import '@fullcalendar/daygrid/main.min.css';

import cookie from 'cookie';
import { ReactElement, ReactNode } from 'react';
// next
import { NextPage } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { Provider as ReduxProvider } from 'react-redux';
//
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TagManager from 'react-gtm-module';

// react-query
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
// redux
import { store } from '../redux/store';
// utils
import { SettingsValueProps } from '../components/settings/type';
import { getSettings } from '../utils/settings';
// contexts

import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext';
// theme
import ThemeProvider from '../theme';
// components
import NotistackProvider from '../components/NotistackProvider';
import ProgressBar from '../components/ProgressBar';
import MotionLazyContainer from '../components/animate/MotionLazyContainer';
import { ChartStyle } from '../components/chart';

import { Box, Button, GlobalStyles, Stack } from '@mui/material';
import { SessionProvider, signIn } from 'next-auth/react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import palette from '@subflow-frontend/theme/palette';
import { NextPageWithLayout } from '@subflow-frontend/layouts/types';

import '@subflow-frontend/sections/chat/styles/index.scss';
import '@subflow-frontend/sections/feed/react-activity-feed/styles/index.scss';
import ErrorBoundary from '@subflow-frontend/components/error/ErrorBoundary';
import {
  isProductionBuild,
  isProductionEnvironment,
  SESSION_CONFIG,
} from '@subflow-frontend/config';
import { ApiError } from '@subflow/data-access';
import { useSnackbar } from 'notistack';

if (
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN !== 'localhost' &&
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN !== 'subflow.local'
) {
  datadogRum.init({
    applicationId: '791a0db2-9d4b-40cd-a8ad-3ded142468fc',
    clientToken: 'pub14542e7de1322c8cf6a1fc7b4eb0f488',
    site: 'datadoghq.com',
    service: 'frontend',
    env: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    allowedTracingUrls: [
      (url) =>
        url.startsWith('https://api.subflow.com') ||
        url.startsWith('https://api.dev.subflow.dev') ||
        url.startsWith('https://api.dev.subflow.com'),
    ],
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', // TODO: get this during build or read from package.json
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || 'N/A';
const appEnv = process.env.NEXT_PUBLIC_APP_ENV || 'N/A';

console.log(`Subflow env: ${appEnv}`);
console.log(`Subflow version: ${appVersion}`);

if (
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN !== 'localhost' &&
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN !== 'subflow.local'
) {
  datadogLogs.init({
    clientToken: 'pubfce786d94195091bef8a519c688eacc1',
    site: 'datadoghq.com',
    env: appEnv,
    service: `subflow-fe-${appEnv}`,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    version: appVersion,
  });
}

interface MyAppProps extends AppProps {
  settings: SettingsValueProps;
  Component: NextPageWithLayout;
}

const growSurfCampaign = process.env.NEXT_PUBLIC_GROWSURF_CAMPAIGN;

function RecaptchaStyle() {
  return (
    <GlobalStyles
      styles={{
        '&.grecaptcha-badge': { visibility: 'hidden' },
      }}
    />
  );
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps, settings } = props;

  const getLayout = Component.getLayout ?? ((page) => page);
  const renderMetaTags = () => {
    if (Component.getMetaTags) {
      try {
        return Component.getMetaTags(pageProps);
      } catch (e) {
        if (typeof window !== 'undefined') {
          datadogLogs.logger.error(
            'Error occurred while rendering meta tags',
            {},
            e
          );
        }
        return <></>;
      }
    } else {
      return <></>;
    }
  };
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TVSQK5D' });
  }, []);

  const insertGrowsurfScript = () => {
    const script = document.createElement('script');
    script.src = 'https://growsurf.com/growsurf.js?v=2.0.0';
    script.setAttribute('grsf-campaign', growSurfCampaign);
    script.async = true;
    document.head.appendChild(script);
  };

  const handleGrowSurfReady = async () => {
    // remove our listener
    window.removeEventListener('grsfReady', handleGrowSurfReady, false);
  };

  useEffect(() => {
    if (!window?.growsurf) {
      // insert the GrowSurf script
      insertGrowsurfScript();
      // Listen and wait for the Growsurf Universal Code to initialize
      window.addEventListener('grsfReady', handleGrowSurfReady);
    } else {
      // Authorize authenticate your participant (Optional)
      handleGrowSurfReady();
    }
  }, []);

  // Refresh session every 7minutes. Prevents session from expiring when tab is open.
  // This value should be smaller than the KC access token expiry time.
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <SessionProvider
        /* @ts-ignore */
        session={pageProps.session}
        refetchInterval={SESSION_CONFIG.CLIENT_REFETCH_SESSION_INTERVAL}
        refetchOnWindowFocus={true}
      >
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CollapseDrawerProvider>
              <ThemeProvider
                appTheme={Component.appTheme}
                defaultSettings={settings}
              >
                <NotistackProvider>
                  <MotionLazyContainer>
                    <ChartStyle />
                    <ProgressBar />
                    <RecaptchaStyle />
                    <ReactQueryProvider>
                      <ErrorBoundary>
                        {renderMetaTags()}

                        {getLayout(
                          <ErrorBoundary>
                            <Component {...pageProps} />
                          </ErrorBoundary>,
                          pageProps
                        )}
                      </ErrorBoundary>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </ReactQueryProvider>
                  </MotionLazyContainer>
                </NotistackProvider>
              </ThemeProvider>
            </CollapseDrawerProvider>
          </LocalizationProvider>
        </ReduxProvider>
      </SessionProvider>
    </>
  );
}

interface ReactQueryProviderProps {
  children: ReactNode;
}

export const ReactQueryProvider: React.FC<ReactQueryProviderProps> = ({
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const apiErrorHandler = useCallback((error: ApiError) => {
    if (error?.errorCode === 'sessionExpired') {
      enqueueSnackbar(
        <Stack alignItems={'center'} gap={1} flexDirection={'row'}>
          <Box>
            The authentication session has expired. Please sign-in again{' '}
          </Box>
          <Button
            onClick={() => {
              signIn('keycloak');
            }}
            variant="contained"
          >
            Sign in
          </Button>
        </Stack>,
        {
          key: 'sessionExpired',
          variant: 'error',
          persist: true,
          preventDuplicate: true,
        }
      );
    }
  }, []);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError: apiErrorHandler,
        }),
        mutationCache: new MutationCache({
          onError: apiErrorHandler,
        }),
      })
  );
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context: AppContext) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie
  );

  const settings = getSettings(cookies);

  return {
    ...appProps,
    settings,
  };
};
