import { baseURL, axios } from './axios';
import {
  Configuration,
  ProvisionRequestDto,
  ProvisionsApiFactory,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const provisionApi = ProvisionsApiFactory(config, baseURL, axios);

export const provisionAccount = async (
  body: ProvisionRequestDto
): Promise<any> => {
  const response = await provisionApi.provision(body);
  return response;
};
