/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Broadcast } from './broadcast';
import { ChannelSegment } from './channel-segment';
import { Contact } from './contact';
import { Merchant } from './merchant';
import { ScheduleSegment } from './schedule-segment';
import { Tag } from './tag';

/**
 * 
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof Segment
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'type': SegmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'merchantId': string;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'deletedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'description': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Segment
     */
    'published': boolean;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'productId': string;
    /**
     * 
     * @type {Merchant}
     * @memberof Segment
     */
    'merchant': Merchant;
    /**
     * 
     * @type {Array<Broadcast>}
     * @memberof Segment
     */
    'broadcasts': Array<Broadcast>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Segment
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'tagsQuery': SegmentTagsQueryEnum;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof Segment
     */
    'contacts': Array<Contact>;
    /**
     * 
     * @type {Array<ScheduleSegment>}
     * @memberof Segment
     */
    'scheduleSegments': Array<ScheduleSegment>;
    /**
     * 
     * @type {Array<ChannelSegment>}
     * @memberof Segment
     */
    'channelSegments': Array<ChannelSegment>;
}

export const SegmentTypeEnum = {
    All: 'all',
    Product: 'product',
    Custom: 'custom'
} as const;

export type SegmentTypeEnum = typeof SegmentTypeEnum[keyof typeof SegmentTypeEnum];
export const SegmentTagsQueryEnum = {
    IsAnyOf: 'isAnyOf',
    IsAllOf: 'isAllOf',
    IsNotEqualTo: 'isNotEqualTo'
} as const;

export type SegmentTagsQueryEnum = typeof SegmentTagsQueryEnum[keyof typeof SegmentTagsQueryEnum];


