import { createSlice } from '@reduxjs/toolkit';
import { ConversationDto } from '@subflow/api-client';
import { fetchMessages, getConversationsList } from '@subflow/data-access';
import { ChatState } from '../../@types/chat';
import { dispatch } from '../store';
import objFromArray from '../../utils/objFromArray';
import { CONVERSATIONS_PAGE_LIMIT } from '@subflow-frontend/utils/conversations';

const initialState: ChatState = {
  error: null,
  conversations: {
    hasAnyConversations: {
      state: 'init',
    },
    state: 'init',
    byId: {},
    allIds: [],
    page: 1,
    limit: CONVERSATIONS_PAGE_LIMIT,
    totalCount: 0,
    filtered: false,
    searchQuery: '',
    searchTags: [],
    unreadOnly: false,
    selectedTags: [],
  },
  messages: {
    byConversationId: {},
    state: 'init',
  },
  activeConversationId: null,
  participants: [],
  recipients: [],
  segments: [],
};

const sortConversations = (conversations: ConversationDto[]) => {
  return conversations
    .sort((c1, c2) => {
      return (
        +new Date(c2.lastMessage?.createdAt) -
        +new Date(c1.lastMessage?.createdAt)
      );
    })
    .sort((c1, c2) => c2.unreadMessagesCount - c1.unreadMessagesCount);
};

const initializeMessagesIfEmpty = (
  state: ChatState,
  conversationId: string
) => {
  if (!state.messages.byConversationId[conversationId]) {
    state.messages.byConversationId[conversationId] = {
      messages: [],
    };
  }
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    startLoadingConversations(state) {
      state.conversations.state = 'loading';
      if (state.conversations.hasAnyConversations.state === 'init') {
        state.conversations.hasAnyConversations.state = 'loading';
      }
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;

      console.error(action);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload.results;

      state.conversations.byId = objFromArray(conversations);
      state.conversations.allIds = Object.keys(state.conversations.byId);
      state.conversations.page = action.payload.page || 1;
      state.conversations.limit =
        action.payload.limit || CONVERSATIONS_PAGE_LIMIT;
      state.conversations.totalCount = action.payload.totalCount;

      if (
        action.payload.search ||
        action.payload.tags?.length ||
        action.payload.unreadOnly
      ) {
        state.conversations.filtered = true;
      } else {
        state.conversations.filtered = false;
      }
      state.conversations.state = 'loaded';
      if (
        state.conversations.hasAnyConversations.state === 'init' ||
        state.conversations.hasAnyConversations.state === 'loading'
      ) {
        state.conversations.hasAnyConversations = {
          value: state.conversations.allIds.length > 0,
          state: 'loaded',
        };
      }
    },

    startLoadingMessages(state) {
      state.messages.state = 'loading';
    },

    getMessagesSuccess(state, action) {
      if (action.payload) {
        initializeMessagesIfEmpty(state, action.payload.conversationId);

        const messagesStateByConversationId =
          state.messages.byConversationId[action.payload.conversationId];

        if (!action.payload.requestedCursor) {
          state.messages.byConversationId[action.payload.conversationId] = {
            messages: action.payload.messages,
            nextCursor: action.payload.nextCursor,
          };
        } else {
          state.messages.byConversationId[action.payload.conversationId] = {
            messages: [
              ...action.payload.messages,
              ...(messagesStateByConversationId
                ? messagesStateByConversationId.messages
                : []),
            ],
            nextCursor: action.payload.nextCursor,
          };
        }
        state.activeConversationId = action.payload.conversationId;
      } else {
        state.activeConversationId = null;
      }
      state.messages.state = 'loaded';
    },

    onMessagesCreated(state, action) {
      const { conversationId, messages } = action.payload;

      initializeMessagesIfEmpty(state, conversationId);

      const lastMessage = messages[messages.length - 1];
      state.conversations.byId[conversationId].lastMessage = lastMessage;
      state.messages.byConversationId[conversationId].messages.push(
        ...messages
      );

      const sortedConversations = sortConversations(
        Object.values(state.conversations.byId)
      );

      state.conversations.byId = objFromArray(sortedConversations);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    onMessageUpdated(state, action) {
      const { message: updatedMessage } = action.payload;

      const conversationId = Object.keys(state.conversations.byId).find(
        (id) => {
          return state.messages.byConversationId[id]?.messages.find(
            (message) => message.id === updatedMessage.id
          );
        }
      );

      if (conversationId) {
        initializeMessagesIfEmpty(state, conversationId);
        const messageToUpdateIndex = state.messages.byConversationId[
          conversationId
        ].messages.findIndex((message) => message.id === updatedMessage.id);

        state.messages.byConversationId[conversationId].messages = [
          ...state.messages.byConversationId[conversationId].messages.slice(
            0,
            messageToUpdateIndex
          ),
          updatedMessage,
          ...state.messages.byConversationId[conversationId].messages.slice(
            messageToUpdateIndex + 1
          ),
        ];
      }
    },

    onConversationCreated(state, action) {
      const { conversation } = action.payload;
      state.conversations.byId[conversation.id] = conversation;
      initializeMessagesIfEmpty(state, conversation.id);
    },

    onConversationUpdated(state, action) {
      const { conversation } = action.payload;
      state.conversations.byId[conversation.id] = {
        ...state.conversations.byId[conversation.id],
        ...conversation,
      };
      initializeMessagesIfEmpty(state, conversation.id);
    },

    onConversationDeleted(state, action) {
      const { conversation } = action.payload;
      delete state.conversations.byId[conversation.id];
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    onSearchQueryUpdated(state, action) {
      state.conversations.searchQuery = action.payload;
    },

    onSearchTagsUpdated(state, action) {
      state.conversations.searchTags = action.payload;
    },

    onUnreadOnlyUpdated(state, action) {
      state.conversations.unreadOnly = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onMessagesCreated,
  onMessageUpdated,
  onConversationCreated,
  onConversationUpdated,
  onConversationDeleted,
  resetActiveConversation,
  onSearchQueryUpdated,
  onSearchTagsUpdated,
  onUnreadOnlyUpdated,
} = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export interface PaginatedConversationsParams {
  page?: number;
  search?: string;
  tags?: string[];
  unreadOnly?: boolean;
}

export function getConversations({
  page = 1,
  search,
  tags,
  unreadOnly,
}: PaginatedConversationsParams) {
  return async () => {
    dispatch(slice.actions.startLoadingConversations());
    try {
      const response = await getConversationsList(
        CONVERSATIONS_PAGE_LIMIT,
        page,
        search,
        tags,
        unreadOnly
      );
      dispatch(
        slice.actions.getConversationsSuccess({
          ...response,
          search,
          tags,
          unreadOnly,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMessages(conversationId: string, cursor?: string) {
  return async () => {
    try {
      dispatch(slice.actions.startLoadingMessages());
      const response = await fetchMessages(conversationId, 25, cursor);

      dispatch(
        slice.actions.getMessagesSuccess({
          messages: response.results,
          conversationId: conversationId,
          requestedCursor: cursor,
          nextCursor: response.nextCursor,
        })
      );
    } catch (error) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}
