import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL } from './axios';
import { Configuration, CustomersApiFactory } from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const customersApi = CustomersApiFactory(config, baseURL, axios);

export interface PaginatedCustomerInvoiceHistoryParams {
  limit?: number;
  starting_after?: string;
}
export interface PaginatedCustomersParams {
  limit?: number;
  search?: string;
  nextCursor?: string;
  previousCursor?: string;
  direction?: string;
}
enum QUERY_KEYS {
  list = 'customers',
  single = 'customer',
  coupon = 'code',
}

export { QUERY_KEYS as CUSTOMER_QUERY_KEYS };

export async function validateCode(code: string, merchantId: string) {
  const { data } = await customersApi.validateCode(code, merchantId);

  return data;
}

export const useCustomerSubscription = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [],
    async () => {
      const response = await customersApi.findOneSubscription();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your subscription.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteCustomers = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (ids: string[]) => customersApi.deleteAllCustomers({ ids }),
    {
      onSuccess: () => {
        enqueueSnackbar('The customer was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting the customer.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useCancelSubscription = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (subscriptionId: string) => customersApi.cancelSubscription(subscriptionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('The subscription was successfully canceled!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem canceling the subscription.', {
          variant: 'error',
        });
      },
    }
  );
};
