/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EmailTemplateRequestDto } from '../models';
// @ts-ignore
import { EmailTemplateResponseDto } from '../models';
// @ts-ignore
import { EmailTemplatesResponseDto } from '../models';
/**
 * EmailTemplatesApi - axios parameter creator
 * @export
 */
export const EmailTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an email template
         * @param {EmailTemplateRequestDto} emailTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmailTemplate: async (emailTemplateRequestDto: EmailTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailTemplateRequestDto' is not null or undefined
            assertParamExists('createEmailTemplate', 'emailTemplateRequestDto', emailTemplateRequestDto)
            const localVarPath = `/v1/email-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an email template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmailTemplate', 'id', id)
            const localVarPath = `/v1/email-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple email templates
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEmailTemplates: async (page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/email-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a single email template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmailTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findEmailTemplate', 'id', id)
            const localVarPath = `/v1/email-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an email template
         * @param {string} id 
         * @param {EmailTemplateRequestDto} emailTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailTemplate: async (id: string, emailTemplateRequestDto: EmailTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmailTemplate', 'id', id)
            // verify required parameter 'emailTemplateRequestDto' is not null or undefined
            assertParamExists('updateEmailTemplate', 'emailTemplateRequestDto', emailTemplateRequestDto)
            const localVarPath = `/v1/email-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailTemplatesApi - functional programming interface
 * @export
 */
export const EmailTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an email template
         * @param {EmailTemplateRequestDto} emailTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmailTemplate(emailTemplateRequestDto: EmailTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmailTemplate(emailTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an email template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmailTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmailTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple email templates
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllEmailTemplates(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplatesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllEmailTemplates(page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find a single email template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmailTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEmailTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an email template
         * @param {string} id 
         * @param {EmailTemplateRequestDto} emailTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailTemplate(id: string, emailTemplateRequestDto: EmailTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailTemplate(id, emailTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailTemplatesApi - factory interface
 * @export
 */
export const EmailTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an email template
         * @param {EmailTemplateRequestDto} emailTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmailTemplate(emailTemplateRequestDto: EmailTemplateRequestDto, options?: any): AxiosPromise<EmailTemplateResponseDto> {
            return localVarFp.createEmailTemplate(emailTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an email template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailTemplate(id: string, options?: any): AxiosPromise<EmailTemplateResponseDto> {
            return localVarFp.deleteEmailTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple email templates
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEmailTemplates(page?: number, limit?: number, search?: string, options?: any): AxiosPromise<EmailTemplatesResponseDto> {
            return localVarFp.findAllEmailTemplates(page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a single email template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmailTemplate(id: string, options?: any): AxiosPromise<EmailTemplateResponseDto> {
            return localVarFp.findEmailTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an email template
         * @param {string} id 
         * @param {EmailTemplateRequestDto} emailTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailTemplate(id: string, emailTemplateRequestDto: EmailTemplateRequestDto, options?: any): AxiosPromise<EmailTemplateResponseDto> {
            return localVarFp.updateEmailTemplate(id, emailTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailTemplatesApi - object-oriented interface
 * @export
 * @class EmailTemplatesApi
 * @extends {BaseAPI}
 */
export class EmailTemplatesApi extends BaseAPI {
    /**
     * 
     * @summary Create an email template
     * @param {EmailTemplateRequestDto} emailTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public createEmailTemplate(emailTemplateRequestDto: EmailTemplateRequestDto, options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).createEmailTemplate(emailTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an email template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public deleteEmailTemplate(id: string, options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).deleteEmailTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple email templates
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public findAllEmailTemplates(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).findAllEmailTemplates(page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a single email template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public findEmailTemplate(id: string, options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).findEmailTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an email template
     * @param {string} id 
     * @param {EmailTemplateRequestDto} emailTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public updateEmailTemplate(id: string, emailTemplateRequestDto: EmailTemplateRequestDto, options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).updateEmailTemplate(id, emailTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
