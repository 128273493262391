/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CheckPageExistsBySlugResponseDto } from '../models';
// @ts-ignore
import { ChildrenPageTemplatesResponseDto } from '../models';
// @ts-ignore
import { ChildrenPagesResponseDto } from '../models';
// @ts-ignore
import { CreatePageRequestDto } from '../models';
// @ts-ignore
import { CreatePageTemplateRequestDto } from '../models';
// @ts-ignore
import { DeletePagesRequestDto } from '../models';
// @ts-ignore
import { FindPagesInTreeResponseDto } from '../models';
// @ts-ignore
import { PageResponseDto } from '../models';
// @ts-ignore
import { PageTemplateResponseDto } from '../models';
// @ts-ignore
import { SendPageRequestDto } from '../models';
// @ts-ignore
import { UpdatePageRequestDto } from '../models';
// @ts-ignore
import { UpdatePageTemplateRequestDto } from '../models';
/**
 * PagesApi - axios parameter creator
 * @export
 */
export const PagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if page with same slug exists
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPageExistsBySlug: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('checkPageExistsBySlug', 'slug', slug)
            const localVarPath = `/v1/pages/slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a page
         * @param {CreatePageRequestDto} createPageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPage: async (createPageRequestDto: CreatePageRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPageRequestDto' is not null or undefined
            assertParamExists('createPage', 'createPageRequestDto', createPageRequestDto)
            const localVarPath = `/v1/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPageRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a page template
         * @param {CreatePageTemplateRequestDto} createPageTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPageTemplate: async (createPageTemplateRequestDto: CreatePageTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPageTemplateRequestDto' is not null or undefined
            assertParamExists('createPageTemplate', 'createPageTemplateRequestDto', createPageTemplateRequestDto)
            const localVarPath = `/v1/pages/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPageTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a page
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePage: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePage', 'id', id)
            const localVarPath = `/v1/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a page template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePageTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePageTemplate', 'id', id)
            const localVarPath = `/v1/pages/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete pages
         * @param {DeletePagesRequestDto} deletePagesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePages: async (deletePagesRequestDto: DeletePagesRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePagesRequestDto' is not null or undefined
            assertParamExists('deletePages', 'deletePagesRequestDto', deletePagesRequestDto)
            const localVarPath = `/v1/pages/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePagesRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all pages
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPages: async (page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve children page templates
         * @param {string} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChildrenPageTemplates: async (parentId?: string, page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pages/templates/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve children pages
         * @param {string} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChildrenPages: async (parentId?: string, page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pages/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a page
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOnePage: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOnePage', 'id', id)
            const localVarPath = `/v1/pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a page template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOnePageTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOnePageTemplate', 'id', id)
            const localVarPath = `/v1/pages/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a page
         * @param {SendPageRequestDto} sendPageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPage: async (sendPageRequestDto: SendPageRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendPageRequestDto' is not null or undefined
            assertParamExists('sendPage', 'sendPageRequestDto', sendPageRequestDto)
            const localVarPath = `/v1/pages/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPageRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a page
         * @param {string} id 
         * @param {UpdatePageRequestDto} updatePageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePage: async (id: string, updatePageRequestDto: UpdatePageRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePage', 'id', id)
            // verify required parameter 'updatePageRequestDto' is not null or undefined
            assertParamExists('updatePage', 'updatePageRequestDto', updatePageRequestDto)
            const localVarPath = `/v1/pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePageRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a page template
         * @param {string} id 
         * @param {UpdatePageTemplateRequestDto} updatePageTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePageTemplate: async (id: string, updatePageTemplateRequestDto: UpdatePageTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePageTemplate', 'id', id)
            // verify required parameter 'updatePageTemplateRequestDto' is not null or undefined
            assertParamExists('updatePageTemplate', 'updatePageTemplateRequestDto', updatePageTemplateRequestDto)
            const localVarPath = `/v1/pages/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePageTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PagesApi - functional programming interface
 * @export
 */
export const PagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if page with same slug exists
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPageExistsBySlug(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckPageExistsBySlugResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPageExistsBySlug(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a page
         * @param {CreatePageRequestDto} createPageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPage(createPageRequestDto: CreatePageRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPage(createPageRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a page template
         * @param {CreatePageTemplateRequestDto} createPageTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPageTemplate(createPageTemplateRequestDto: CreatePageTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPageTemplate(createPageTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a page
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePage(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a page template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePageTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePageTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete pages
         * @param {DeletePagesRequestDto} deletePagesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePages(deletePagesRequestDto: DeletePagesRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePages(deletePagesRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all pages
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllPages(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindPagesInTreeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllPages(page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve children page templates
         * @param {string} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findChildrenPageTemplates(parentId?: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildrenPageTemplatesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findChildrenPageTemplates(parentId, page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve children pages
         * @param {string} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findChildrenPages(parentId?: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildrenPagesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findChildrenPages(parentId, page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a page
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOnePage(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOnePage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a page template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOnePageTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOnePageTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a page
         * @param {SendPageRequestDto} sendPageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPage(sendPageRequestDto: SendPageRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPage(sendPageRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a page
         * @param {string} id 
         * @param {UpdatePageRequestDto} updatePageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePage(id: string, updatePageRequestDto: UpdatePageRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePage(id, updatePageRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a page template
         * @param {string} id 
         * @param {UpdatePageTemplateRequestDto} updatePageTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePageTemplate(id: string, updatePageTemplateRequestDto: UpdatePageTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePageTemplate(id, updatePageTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PagesApi - factory interface
 * @export
 */
export const PagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if page with same slug exists
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPageExistsBySlug(slug: string, options?: any): AxiosPromise<CheckPageExistsBySlugResponseDto> {
            return localVarFp.checkPageExistsBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a page
         * @param {CreatePageRequestDto} createPageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPage(createPageRequestDto: CreatePageRequestDto, options?: any): AxiosPromise<PageResponseDto> {
            return localVarFp.createPage(createPageRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a page template
         * @param {CreatePageTemplateRequestDto} createPageTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPageTemplate(createPageTemplateRequestDto: CreatePageTemplateRequestDto, options?: any): AxiosPromise<PageTemplateResponseDto> {
            return localVarFp.createPageTemplate(createPageTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a page
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePage(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a page template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePageTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePageTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete pages
         * @param {DeletePagesRequestDto} deletePagesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePages(deletePagesRequestDto: DeletePagesRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.deletePages(deletePagesRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all pages
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPages(page?: number, limit?: number, search?: string, options?: any): AxiosPromise<FindPagesInTreeResponseDto> {
            return localVarFp.findAllPages(page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve children page templates
         * @param {string} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChildrenPageTemplates(parentId?: string, page?: number, limit?: number, search?: string, options?: any): AxiosPromise<ChildrenPageTemplatesResponseDto> {
            return localVarFp.findChildrenPageTemplates(parentId, page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve children pages
         * @param {string} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChildrenPages(parentId?: string, page?: number, limit?: number, search?: string, options?: any): AxiosPromise<ChildrenPagesResponseDto> {
            return localVarFp.findChildrenPages(parentId, page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a page
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOnePage(id: string, options?: any): AxiosPromise<PageResponseDto> {
            return localVarFp.findOnePage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a page template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOnePageTemplate(id: string, options?: any): AxiosPromise<PageTemplateResponseDto> {
            return localVarFp.findOnePageTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a page
         * @param {SendPageRequestDto} sendPageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPage(sendPageRequestDto: SendPageRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendPage(sendPageRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a page
         * @param {string} id 
         * @param {UpdatePageRequestDto} updatePageRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePage(id: string, updatePageRequestDto: UpdatePageRequestDto, options?: any): AxiosPromise<PageResponseDto> {
            return localVarFp.updatePage(id, updatePageRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a page template
         * @param {string} id 
         * @param {UpdatePageTemplateRequestDto} updatePageTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePageTemplate(id: string, updatePageTemplateRequestDto: UpdatePageTemplateRequestDto, options?: any): AxiosPromise<PageTemplateResponseDto> {
            return localVarFp.updatePageTemplate(id, updatePageTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PagesApi - object-oriented interface
 * @export
 * @class PagesApi
 * @extends {BaseAPI}
 */
export class PagesApi extends BaseAPI {
    /**
     * 
     * @summary Check if page with same slug exists
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public checkPageExistsBySlug(slug: string, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).checkPageExistsBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a page
     * @param {CreatePageRequestDto} createPageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public createPage(createPageRequestDto: CreatePageRequestDto, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).createPage(createPageRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a page template
     * @param {CreatePageTemplateRequestDto} createPageTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public createPageTemplate(createPageTemplateRequestDto: CreatePageTemplateRequestDto, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).createPageTemplate(createPageTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a page
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public deletePage(id: string, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).deletePage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a page template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public deletePageTemplate(id: string, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).deletePageTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete pages
     * @param {DeletePagesRequestDto} deletePagesRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public deletePages(deletePagesRequestDto: DeletePagesRequestDto, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).deletePages(deletePagesRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all pages
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public findAllPages(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).findAllPages(page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve children page templates
     * @param {string} [parentId] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public findChildrenPageTemplates(parentId?: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).findChildrenPageTemplates(parentId, page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve children pages
     * @param {string} [parentId] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public findChildrenPages(parentId?: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).findChildrenPages(parentId, page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a page
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public findOnePage(id: string, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).findOnePage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a page template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public findOnePageTemplate(id: string, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).findOnePageTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a page
     * @param {SendPageRequestDto} sendPageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public sendPage(sendPageRequestDto: SendPageRequestDto, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).sendPage(sendPageRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a page
     * @param {string} id 
     * @param {UpdatePageRequestDto} updatePageRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public updatePage(id: string, updatePageRequestDto: UpdatePageRequestDto, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).updatePage(id, updatePageRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a page template
     * @param {string} id 
     * @param {UpdatePageTemplateRequestDto} updatePageTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public updatePageTemplate(id: string, updatePageTemplateRequestDto: UpdatePageTemplateRequestDto, options?: AxiosRequestConfig) {
        return PagesApiFp(this.configuration).updatePageTemplate(id, updatePageTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
