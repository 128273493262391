/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMeetingRequestDto } from '../models';
// @ts-ignore
import { MeetingResponseDto } from '../models';
// @ts-ignore
import { PaginatedMeetingsResponseDto } from '../models';
// @ts-ignore
import { SendMeetingLinkRequestDto } from '../models';
// @ts-ignore
import { SendMeetingLinkResponseDto } from '../models';
// @ts-ignore
import { ShareMeetingRequestDto } from '../models';
// @ts-ignore
import { ShareMeetingResponseDto } from '../models';
// @ts-ignore
import { UpdateMeetingRequestDto } from '../models';
/**
 * MeetingsApi - axios parameter creator
 * @export
 */
export const MeetingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single meeting
         * @param {CreateMeetingRequestDto} createMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeeting: async (createMeetingRequestDto: CreateMeetingRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMeetingRequestDto' is not null or undefined
            assertParamExists('createMeeting', 'createMeetingRequestDto', createMeetingRequestDto)
            const localVarPath = `/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMeetingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single meeting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeeting: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMeeting', 'id', id)
            const localVarPath = `/v1/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicate a single meeting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateMeeting: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateMeeting', 'id', id)
            const localVarPath = `/v1/meetings/{id}/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve single meeting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneMeeting: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneMeeting', 'id', id)
            const localVarPath = `/v1/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple meetings
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPaginatedMeetings: async (page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a meeting link
         * @param {SendMeetingLinkRequestDto} sendMeetingLinkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMeetingLink: async (sendMeetingLinkRequestDto: SendMeetingLinkRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMeetingLinkRequestDto' is not null or undefined
            assertParamExists('sendMeetingLink', 'sendMeetingLinkRequestDto', sendMeetingLinkRequestDto)
            const localVarPath = `/v1/meetings/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMeetingLinkRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share a meeting
         * @param {ShareMeetingRequestDto} shareMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareMeeting: async (shareMeetingRequestDto: ShareMeetingRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareMeetingRequestDto' is not null or undefined
            assertParamExists('shareMeeting', 'shareMeetingRequestDto', shareMeetingRequestDto)
            const localVarPath = `/v1/meetings/share`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareMeetingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single meeting
         * @param {string} id 
         * @param {UpdateMeetingRequestDto} updateMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting: async (id: string, updateMeetingRequestDto: UpdateMeetingRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMeeting', 'id', id)
            // verify required parameter 'updateMeetingRequestDto' is not null or undefined
            assertParamExists('updateMeeting', 'updateMeetingRequestDto', updateMeetingRequestDto)
            const localVarPath = `/v1/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMeetingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetingsApi - functional programming interface
 * @export
 */
export const MeetingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeetingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single meeting
         * @param {CreateMeetingRequestDto} createMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeeting(createMeetingRequestDto: CreateMeetingRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMeeting(createMeetingRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single meeting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeeting(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Duplicate a single meeting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateMeeting(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve single meeting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneMeeting(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple meetings
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPaginatedMeetings(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMeetingsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPaginatedMeetings(page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a meeting link
         * @param {SendMeetingLinkRequestDto} sendMeetingLinkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMeetingLink(sendMeetingLinkRequestDto: SendMeetingLinkRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendMeetingLinkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMeetingLink(sendMeetingLinkRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share a meeting
         * @param {ShareMeetingRequestDto} shareMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareMeeting(shareMeetingRequestDto: ShareMeetingRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareMeetingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareMeeting(shareMeetingRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single meeting
         * @param {string} id 
         * @param {UpdateMeetingRequestDto} updateMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeeting(id: string, updateMeetingRequestDto: UpdateMeetingRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeeting(id, updateMeetingRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeetingsApi - factory interface
 * @export
 */
export const MeetingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeetingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single meeting
         * @param {CreateMeetingRequestDto} createMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeeting(createMeetingRequestDto: CreateMeetingRequestDto, options?: any): AxiosPromise<MeetingResponseDto> {
            return localVarFp.createMeeting(createMeetingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single meeting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeeting(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicate a single meeting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateMeeting(id: string, options?: any): AxiosPromise<MeetingResponseDto> {
            return localVarFp.duplicateMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve single meeting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneMeeting(id: string, options?: any): AxiosPromise<MeetingResponseDto> {
            return localVarFp.findOneMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple meetings
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPaginatedMeetings(page?: number, limit?: number, search?: string, options?: any): AxiosPromise<PaginatedMeetingsResponseDto> {
            return localVarFp.findPaginatedMeetings(page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a meeting link
         * @param {SendMeetingLinkRequestDto} sendMeetingLinkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMeetingLink(sendMeetingLinkRequestDto: SendMeetingLinkRequestDto, options?: any): AxiosPromise<SendMeetingLinkResponseDto> {
            return localVarFp.sendMeetingLink(sendMeetingLinkRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share a meeting
         * @param {ShareMeetingRequestDto} shareMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareMeeting(shareMeetingRequestDto: ShareMeetingRequestDto, options?: any): AxiosPromise<ShareMeetingResponseDto> {
            return localVarFp.shareMeeting(shareMeetingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single meeting
         * @param {string} id 
         * @param {UpdateMeetingRequestDto} updateMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting(id: string, updateMeetingRequestDto: UpdateMeetingRequestDto, options?: any): AxiosPromise<MeetingResponseDto> {
            return localVarFp.updateMeeting(id, updateMeetingRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeetingsApi - object-oriented interface
 * @export
 * @class MeetingsApi
 * @extends {BaseAPI}
 */
export class MeetingsApi extends BaseAPI {
    /**
     * 
     * @summary Create a single meeting
     * @param {CreateMeetingRequestDto} createMeetingRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public createMeeting(createMeetingRequestDto: CreateMeetingRequestDto, options?: AxiosRequestConfig) {
        return MeetingsApiFp(this.configuration).createMeeting(createMeetingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single meeting
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public deleteMeeting(id: string, options?: AxiosRequestConfig) {
        return MeetingsApiFp(this.configuration).deleteMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicate a single meeting
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public duplicateMeeting(id: string, options?: AxiosRequestConfig) {
        return MeetingsApiFp(this.configuration).duplicateMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve single meeting
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public findOneMeeting(id: string, options?: AxiosRequestConfig) {
        return MeetingsApiFp(this.configuration).findOneMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple meetings
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public findPaginatedMeetings(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return MeetingsApiFp(this.configuration).findPaginatedMeetings(page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a meeting link
     * @param {SendMeetingLinkRequestDto} sendMeetingLinkRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public sendMeetingLink(sendMeetingLinkRequestDto: SendMeetingLinkRequestDto, options?: AxiosRequestConfig) {
        return MeetingsApiFp(this.configuration).sendMeetingLink(sendMeetingLinkRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share a meeting
     * @param {ShareMeetingRequestDto} shareMeetingRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public shareMeeting(shareMeetingRequestDto: ShareMeetingRequestDto, options?: AxiosRequestConfig) {
        return MeetingsApiFp(this.configuration).shareMeeting(shareMeetingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single meeting
     * @param {string} id 
     * @param {UpdateMeetingRequestDto} updateMeetingRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public updateMeeting(id: string, updateMeetingRequestDto: UpdateMeetingRequestDto, options?: AxiosRequestConfig) {
        return MeetingsApiFp(this.configuration).updateMeeting(id, updateMeetingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
