import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL } from './axios';
import {
  Configuration,
  ContactDetailsRequestDto,
  ContactSubscriberResponseDto,
  ContactsApiFactory,
  FilteredContactsRequestDto,
  FindSelectedRecipientsRequestDto,
} from '@subflow/api-client';
import { MERCHANT_QUERY_KEYS } from './merchants';
import { TAG_QUERY_KEYS } from 'libs/data-access/src/tags';

const config = new Configuration({
  basePath: baseURL,
});

const contactsApi = ContactsApiFactory(config, baseURL, axios);

export interface PaginatedContactsParams {
  limit?: number;
  search?: string;
  nextCursor?: string;
  previousCursor?: string;
  direction?: string;
}
enum QUERY_KEYS {
  aggregatedCustomers = 'aggregated-customers',
  list = 'list',
  filteredList = 'filteredList',
  selectedRecipients = 'selectedRecipients',
  single = 'contact',
  contactTags = 'contactTags',
}

export interface FindContactsParams {
  limit?: number;
  page?: number;
  search?: string;
}

export const useContact = (phone: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, phone],
    async () => {
      const response = await contactsApi.findOneContact(
        encodeURIComponent(phone)
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your contact.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useUpsertContact = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ phone, body }: { phone: string; body: ContactDetailsRequestDto }) =>
      contactsApi.upsertContact(phone, body),
    {
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries([
          QUERY_KEYS.single,
          variables.phone,
        ]);
        queryClient.invalidateQueries([QUERY_KEYS.list]);
        queryClient.invalidateQueries([MERCHANT_QUERY_KEYS.single]);
        queryClient.invalidateQueries(TAG_QUERY_KEYS.list);

        enqueueSnackbar('The contact was successfully updated!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating the contact.', {
          variant: 'error',
        });
      },
    }
  );
};

export function useFilteredContacts(filters: FilteredContactsRequestDto) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.filteredList, filters],
    async () => {
      const response = await contactsApi.findFilteredContacts(filters);
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar(
          'There was a problem fetching your filtered contacts.',
          {
            variant: 'error',
          }
        );
      },
    }
  );
}

export function useContacts({ limit, page, search }: FindContactsParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, limit, page, search],
    async () => {
      const response = await contactsApi.findContacts(limit, page, search);
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your contacts.', {
          variant: 'error',
        });
      },
    }
  );
}

export function useContactsByProduct(productId: string) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, productId],
    async () => {
      const response = await contactsApi.findContactsByProductId(productId);
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your contacts.', {
          variant: 'error',
        });
      },
    }
  );
}

export function useSelectedRecipients(
  filters: FindSelectedRecipientsRequestDto | undefined
) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.selectedRecipients, filters],
    async () => {
      if (!filters) throw new Error('Something went wrong');
      const response = await contactsApi.findSelectedRecipients(filters);
      return response.data;
    },
    {
      enabled: !!filters,
      onError: () => {
        enqueueSnackbar(
          'There was a problem fetching your selected recipients.',
          {
            variant: 'error',
          }
        );
      },
    }
  );
}

export const getSubscriberName = (
  subscriber: Pick<ContactSubscriberResponseDto, 'firstName' | 'lastName'>
) => `${subscriber.firstName ?? ''} ${subscriber.lastName ?? ''}`;

export function useContactTags() {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.contactTags],
    async () => {
      const response = await contactsApi.findAllUniqueContactTags();
      const responseData = response.data;
      return responseData;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your contact tags.', {
          variant: 'error',
        });
      },
    }
  );
}
