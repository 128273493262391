import {
  Configuration,
  GetstreamGatewayApiFactory,
  CreateChannelRequestDto,
  UpdateChannelRequestDto,
  ChannelResponseDto,
} from '@subflow/api-client';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL } from './axios';
import { ApiError } from 'libs/data-access/src/utils/error';

const config = new Configuration({
  basePath: baseURL,
});

enum QUERY_KEYS {
  single = 'stream-channel',
}

export const getstreamApi = GetstreamGatewayApiFactory(config, baseURL, axios);

export const useStreamChatToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['stream-chat-token'],
    async () => {
      const response = await getstreamApi.getChatToken();
      return response.data;
    },
    {
      onError: (e: Error) => {
        enqueueSnackbar('There was a problem fetching your chat token.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useChannel = (channelId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, channelId],
    async () => {
      const response = await getstreamApi.getChannel(channelId);
      return response.data;
    },
    {
      enabled: !!channelId,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your channel.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useCreateChannel = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: CreateChannelRequestDto) => getstreamApi.createChannel(request),
    {
      onSuccess: async (response, variables) => {
        enqueueSnackbar(`Channel ${variables.name} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: (error: ApiError) => {
        if (error?.errorCode === 'channelAlreadyExists') {
          enqueueSnackbar('A channel with that name already exists', {
            variant: 'error',
          });
        } else if (error?.errorCode === 'channelWithSubscribersAndSegments') {
          enqueueSnackbar(
            'A channel cannot be created with both subscribers and segments',
            {
              variant: 'error',
            }
          );
        } else {
          enqueueSnackbar('There was a problem creating your channel.', {
            variant: 'error',
          });
        }
      },
    }
  );
};

export const useUpdateChannel = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: { body: UpdateChannelRequestDto; id: string }) =>
      getstreamApi.updateChannel(request.id, request.body),
    {
      onSuccess: async (response, variables) => {
        enqueueSnackbar(
          `Channel ${variables.body.name} was successfully updated!`,
          {
            variant: 'success',
          }
        );
      },
      onError: (error: ApiError) => {
        if (error?.errorCode === 'channelWithSubscribersAndSegments') {
          enqueueSnackbar(
            'A channel cannot be created with both subscribers and segments',
            {
              variant: 'error',
            }
          );
        } else {
          enqueueSnackbar('There was a problem updating your channel.', {
            variant: 'error',
          });
        }
      },
    }
  );
};

export const useDeleteChannel = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: { id: string; name: string }) =>
      getstreamApi.deleteChannel(request.id),
    {
      onSuccess: async (response, variables) => {
        enqueueSnackbar(`Channel ${variables.name} was successfully deleted!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your channel.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useStreamFeedToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['stream-feed-token'],
    async () => {
      const response = await getstreamApi.getFeedToken();
      return response.data;
    },
    {
      staleTime: Infinity,
      onError: (e: Error) => {
        enqueueSnackbar('There was a problem fetching your feed token.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useStreamVideoAudioToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['stream-video-audio-token'],
    async () => {
      const response = await getstreamApi.getVideoAudioToken();
      return response.data;
    },
    {
      staleTime: Infinity,
      onError: (e: Error) => {
        enqueueSnackbar(
          'There was a problem fetching your video audio token.',
          {
            variant: 'error',
          }
        );
      },
    }
  );
};
