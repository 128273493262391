import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { baseURL, axios } from './axios';
import { Configuration, TwilioGatewayApiFactory } from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const twilioGatewayApi = TwilioGatewayApiFactory(config, baseURL, axios);

export const useTollFreeNumber = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['toll-free-number'],
    async () => {
      const response = await twilioGatewayApi.fetchTollFreeNumber();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your toll-free number.', {
          variant: 'error',
        });
      },
    }
  );
};
