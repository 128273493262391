/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateTemplateRequestDto } from '../models';
// @ts-ignore
import { ScheduleDetailsResponseDto } from '../models';
// @ts-ignore
import { ScheduleTemplateRequestDto } from '../models';
// @ts-ignore
import { SchedulesResponseDto } from '../models';
// @ts-ignore
import { SendTemplateRequestDto } from '../models';
// @ts-ignore
import { TagResponseDto } from '../models';
// @ts-ignore
import { TemplateRequestDto } from '../models';
// @ts-ignore
import { TemplateResponseDto } from '../models';
// @ts-ignore
import { TemplatesResponseDto } from '../models';
// @ts-ignore
import { UpdateScheduleRequestDto } from '../models';
// @ts-ignore
import { UpdateTemplateRequestDto } from '../models';
/**
 * TemplatesApi - axios parameter creator
 * @export
 */
export const TemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSchedule: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelSchedule', 'id', id)
            const localVarPath = `/v1/templates/schedule/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single template
         * @param {CreateTemplateRequestDto} createTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate: async (createTemplateRequestDto: CreateTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTemplateRequestDto' is not null or undefined
            assertParamExists('createTemplate', 'createTemplateRequestDto', createTemplateRequestDto)
            const localVarPath = `/v1/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Permanently delete a single template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTemplate', 'id', id)
            const localVarPath = `/v1/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicate a single template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateTemplate', 'id', id)
            const localVarPath = `/v1/templates/{id}/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple schedules
         * @param {string} [sequenceId] 
         * @param {string} [contactId] 
         * @param {string} [segmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSchedules: async (sequenceId?: string, contactId?: string, segmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/templates/schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sequenceId !== undefined) {
                localVarQueryParameter['sequenceId'] = sequenceId;
            }

            if (contactId !== undefined) {
                localVarQueryParameter['contactId'] = contactId;
            }

            if (segmentId !== undefined) {
                localVarQueryParameter['segmentId'] = segmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple templates
         * @param {TemplateRequestDto} templateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTemplates: async (templateRequestDto: TemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateRequestDto' is not null or undefined
            assertParamExists('findAllTemplates', 'templateRequestDto', templateRequestDto)
            const localVarPath = `/v1/templates/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple template tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUniqueTemplateTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/templates/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSchedule: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneSchedule', 'id', id)
            const localVarPath = `/v1/templates/schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneTemplate', 'id', id)
            const localVarPath = `/v1/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry a schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrySchedule: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrySchedule', 'id', id)
            const localVarPath = `/v1/templates/schedule/{id}/retry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule a template
         * @param {ScheduleTemplateRequestDto} scheduleTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleTemplate: async (scheduleTemplateRequestDto: ScheduleTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleTemplateRequestDto' is not null or undefined
            assertParamExists('scheduleTemplate', 'scheduleTemplateRequestDto', scheduleTemplateRequestDto)
            const localVarPath = `/v1/templates/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a template
         * @param {SendTemplateRequestDto} sendTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTemplate: async (sendTemplateRequestDto: SendTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendTemplateRequestDto' is not null or undefined
            assertParamExists('sendTemplate', 'sendTemplateRequestDto', sendTemplateRequestDto)
            const localVarPath = `/v1/templates/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch a schedule
         * @param {string} id 
         * @param {UpdateScheduleRequestDto} updateScheduleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchedule: async (id: string, updateScheduleRequestDto: UpdateScheduleRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSchedule', 'id', id)
            // verify required parameter 'updateScheduleRequestDto' is not null or undefined
            assertParamExists('updateSchedule', 'updateScheduleRequestDto', updateScheduleRequestDto)
            const localVarPath = `/v1/templates/schedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateScheduleRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single template
         * @param {string} id 
         * @param {UpdateTemplateRequestDto} updateTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (id: string, updateTemplateRequestDto: UpdateTemplateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTemplate', 'id', id)
            // verify required parameter 'updateTemplateRequestDto' is not null or undefined
            assertParamExists('updateTemplate', 'updateTemplateRequestDto', updateTemplateRequestDto)
            const localVarPath = `/v1/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplatesApi - functional programming interface
 * @export
 */
export const TemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel a schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSchedule(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSchedule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single template
         * @param {CreateTemplateRequestDto} createTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplate(createTemplateRequestDto: CreateTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplate(createTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Permanently delete a single template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Duplicate a single template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple schedules
         * @param {string} [sequenceId] 
         * @param {string} [contactId] 
         * @param {string} [segmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSchedules(sequenceId?: string, contactId?: string, segmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchedulesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSchedules(sequenceId, contactId, segmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple templates
         * @param {TemplateRequestDto} templateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTemplates(templateRequestDto: TemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplatesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllTemplates(templateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple template tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllUniqueTemplateTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllUniqueTemplateTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneSchedule(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneSchedule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry a schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrySchedule(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrySchedule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedule a template
         * @param {ScheduleTemplateRequestDto} scheduleTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleTemplate(scheduleTemplateRequestDto: ScheduleTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleTemplate(scheduleTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a template
         * @param {SendTemplateRequestDto} sendTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTemplate(sendTemplateRequestDto: SendTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTemplate(sendTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch a schedule
         * @param {string} id 
         * @param {UpdateScheduleRequestDto} updateScheduleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSchedule(id: string, updateScheduleRequestDto: UpdateScheduleRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSchedule(id, updateScheduleRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single template
         * @param {string} id 
         * @param {UpdateTemplateRequestDto} updateTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(id: string, updateTemplateRequestDto: UpdateTemplateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplate(id, updateTemplateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplatesApi - factory interface
 * @export
 */
export const TemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel a schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSchedule(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single template
         * @param {CreateTemplateRequestDto} createTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(createTemplateRequestDto: CreateTemplateRequestDto, options?: any): AxiosPromise<TemplateResponseDto> {
            return localVarFp.createTemplate(createTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Permanently delete a single template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(id: string, options?: any): AxiosPromise<TemplateResponseDto> {
            return localVarFp.deleteTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicate a single template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateTemplate(id: string, options?: any): AxiosPromise<TemplateResponseDto> {
            return localVarFp.duplicateTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple schedules
         * @param {string} [sequenceId] 
         * @param {string} [contactId] 
         * @param {string} [segmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSchedules(sequenceId?: string, contactId?: string, segmentId?: string, options?: any): AxiosPromise<SchedulesResponseDto> {
            return localVarFp.findAllSchedules(sequenceId, contactId, segmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple templates
         * @param {TemplateRequestDto} templateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTemplates(templateRequestDto: TemplateRequestDto, options?: any): AxiosPromise<TemplatesResponseDto> {
            return localVarFp.findAllTemplates(templateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple template tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUniqueTemplateTags(options?: any): AxiosPromise<Array<TagResponseDto>> {
            return localVarFp.findAllUniqueTemplateTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSchedule(id: string, options?: any): AxiosPromise<ScheduleDetailsResponseDto> {
            return localVarFp.findOneSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneTemplate(id: string, options?: any): AxiosPromise<TemplateResponseDto> {
            return localVarFp.findOneTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry a schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrySchedule(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.retrySchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule a template
         * @param {ScheduleTemplateRequestDto} scheduleTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleTemplate(scheduleTemplateRequestDto: ScheduleTemplateRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.scheduleTemplate(scheduleTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a template
         * @param {SendTemplateRequestDto} sendTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTemplate(sendTemplateRequestDto: SendTemplateRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendTemplate(sendTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch a schedule
         * @param {string} id 
         * @param {UpdateScheduleRequestDto} updateScheduleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchedule(id: string, updateScheduleRequestDto: UpdateScheduleRequestDto, options?: any): AxiosPromise<ScheduleDetailsResponseDto> {
            return localVarFp.updateSchedule(id, updateScheduleRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single template
         * @param {string} id 
         * @param {UpdateTemplateRequestDto} updateTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(id: string, updateTemplateRequestDto: UpdateTemplateRequestDto, options?: any): AxiosPromise<TemplateResponseDto> {
            return localVarFp.updateTemplate(id, updateTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplatesApi - object-oriented interface
 * @export
 * @class TemplatesApi
 * @extends {BaseAPI}
 */
export class TemplatesApi extends BaseAPI {
    /**
     * 
     * @summary Cancel a schedule
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public cancelSchedule(id: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).cancelSchedule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single template
     * @param {CreateTemplateRequestDto} createTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public createTemplate(createTemplateRequestDto: CreateTemplateRequestDto, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).createTemplate(createTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Permanently delete a single template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public deleteTemplate(id: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).deleteTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicate a single template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public duplicateTemplate(id: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).duplicateTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple schedules
     * @param {string} [sequenceId] 
     * @param {string} [contactId] 
     * @param {string} [segmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public findAllSchedules(sequenceId?: string, contactId?: string, segmentId?: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).findAllSchedules(sequenceId, contactId, segmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple templates
     * @param {TemplateRequestDto} templateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public findAllTemplates(templateRequestDto: TemplateRequestDto, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).findAllTemplates(templateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple template tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public findAllUniqueTemplateTags(options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).findAllUniqueTemplateTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single schedule
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public findOneSchedule(id: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).findOneSchedule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public findOneTemplate(id: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).findOneTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry a schedule
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public retrySchedule(id: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).retrySchedule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule a template
     * @param {ScheduleTemplateRequestDto} scheduleTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public scheduleTemplate(scheduleTemplateRequestDto: ScheduleTemplateRequestDto, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).scheduleTemplate(scheduleTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a template
     * @param {SendTemplateRequestDto} sendTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public sendTemplate(sendTemplateRequestDto: SendTemplateRequestDto, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).sendTemplate(sendTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch a schedule
     * @param {string} id 
     * @param {UpdateScheduleRequestDto} updateScheduleRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public updateSchedule(id: string, updateScheduleRequestDto: UpdateScheduleRequestDto, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).updateSchedule(id, updateScheduleRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single template
     * @param {string} id 
     * @param {UpdateTemplateRequestDto} updateTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public updateTemplate(id: string, updateTemplateRequestDto: UpdateTemplateRequestDto, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).updateTemplate(id, updateTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
