import { useSnackbar } from 'notistack';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { axios, baseURL } from './axios';
import {
  Configuration,
  ContentResponseDto,
  ContentsApiFactory,
  CreateDirectoryRequestDto,
  MoveContentRequestDto,
  SendContentRequestDto,
  UpdateContentPositionRequestDto,
  UpdateContentRequestDto,
} from '@subflow/api-client';
import { IFile } from '@subflow-frontend/@types/file';
import { fileFormatByContentType } from '@subflow-frontend/components/file-thumbnail';
import { AxiosRequestConfig } from 'axios';

const config = new Configuration({
  basePath: baseURL,
});

export const contentsApi = ContentsApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  descendants = 'descendants',
  children = 'children',
  list = 'list',
  content = 'content',
}
export interface FindChildrenRequestParams {
  parentId?: number;
  page?: number;
  limit?: number;
  search?: string;
  contentTypes?: string[];
  nodeType: 'file' | 'directory';
  includeDirectories?: boolean;
  sortBy?: 'createdDate' | 'position';
}

export interface FindDescendantsRequestParams
  extends FindChildrenRequestParams {
  merchantId?: string;
}

export type FindAllRequestParams = Omit<FindChildrenRequestParams, 'parentId'>;

export function useChildren({
  parentId,
  page,
  limit,
  search,
  contentTypes,
  nodeType,
  includeDirectories,
  sortBy,
}: FindChildrenRequestParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [
      'children',
      {
        parentId,
        page,
        limit,
        search,
        contentTypes,
        nodeType,
        includeDirectories,
        sortBy,
      },
    ],
    async () => {
      const response = await contentsApi.findChildren(
        parentId,
        page,
        limit,
        search,
        contentTypes,
        nodeType,
        includeDirectories,
        sortBy
      );
      const files: IFile[] = response.data.results.map(mapContentToFile);
      return {
        ...response.data,
        results: files,
      };
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching your contents.', {
          variant: 'error',
        });
      },
    }
  );
}

export function useContents({
  page,
  limit,
  search,
  contentTypes,
  nodeType,
}: FindAllRequestParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, { page, limit, search, contentTypes, nodeType }],
    async () => {
      const response = await contentsApi.findAllContents(
        page,
        limit,
        search,
        contentTypes,
        nodeType
      );
      const files: IFile[] = response.data.results.map(mapContentToFile);
      return {
        ...response.data,
        results: files,
      };
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching your contents.', {
          variant: 'error',
        });
      },
    }
  );
}

export const virtualRootDirectory: IFile = {
  id: -1,
  name: 'My Library',
  fileType: 'folder',
  description: 'Root folder',
  dateCreated: '',
  dateModified: '',
  thumbnailUrl: '',
  size: 0,
  fileId: undefined,
  totalFiles: 0,
  shared: [],
  isFavorited: false,
  url: '',
  fileFormat: 'folder',
  type: 'folder',
  tags: [],
  merchantSubdomain: '',
  merchantId: '',
  contentType: '',
  published: true,
  accessType: 'public',
  position: -1,
};

export const mapContentToFile = (content: ContentResponseDto): IFile => {
  const contentType =
    content.type === 'directory' ? 'directory' : content.file?.contentType;
  return {
    id: content.id,
    name: content.name,
    description: content.description,
    fileId: content.type === 'directory' ? undefined : content.file?.id,
    size: content.file?.size ?? 0,
    totalFiles: 0,
    shared: [],
    isFavorited: false,
    url: content.file?.url ?? '',
    thumbnailUrl: content.file?.thumbnail?.url ?? '',
    dateCreated: content.createdAt,
    dateModified: content.updatedAt,
    fileType: content.type === 'directory' ? 'folder' : 'file',
    fileFormat: fileFormatByContentType(contentType),
    type: content.type === 'directory' ? 'folder' : content.type,
    tags: [],
    merchantId: content.merchant?.id,
    merchantSubdomain: content.merchant?.subDomain,
    contentType,
    published: content.published,
    accessType: content.accessType,
    position: content.position,
  };
};

export const useCreateDirectory = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: CreateDirectoryRequestDto) => contentsApi.createDirectory(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar(`${variables.name} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating directory.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useCreateFile = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({
      file,
      fileName,
      contentType,
      parentId,
    }: {
      file: any;
      fileName: string;
      contentType: string;
      parentId?: number;
    }) => {
      return contentsApi.createFile(file, fileName, contentType, parentId);
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        enqueueSnackbar(`File was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating file.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useUploadThumbnail = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({
      thumbnail,
      fileName,
      contentType,
      contentId,
    }: {
      thumbnail: any;
      fileName: string;
      contentType: string;
      contentId: number;
    }) => {
      const options: AxiosRequestConfig = {
        headers: {
          // this header disables AWS WAF - we need it to disable XSS check as some file uploads failed cause of that check
          'x-waf-uuid': '962f989c-0133-456c-81d6-cf46fa6aaa63',
        },
      };

      return contentsApi.uploadThumbnail(
        thumbnail,
        fileName,
        contentType,
        contentId,
        options
      );
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        enqueueSnackbar(`Thumbnail was successfully uploaded!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem uploading file.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteContent = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (contentId: number) => contentsApi.deleteContent(contentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Your content was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your content.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSendContent = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (sendContentRequest: SendContentRequestDto) =>
      contentsApi.sendContent(sendContentRequest),
    {
      onSuccess: () => {
        enqueueSnackbar('Your content was successfully sent!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem sending your content.', {
          variant: 'error',
        });
      },
    }
  );
};

interface UpdateContentRequest {
  contentId: number;
  body: UpdateContentRequestDto;
}

export const useUpdateContent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (request: UpdateContentRequest) => {
      return contentsApi.updateContent(request.contentId, request.body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUERY_KEYS.children);
        await queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Your content was successfully updated!', {
          variant: 'success',
        });
      },
      onError: () => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('There was a problem updating your content.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useMoveContent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (request: MoveContentRequestDto) => contentsApi.moveContent(request),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        queryClient.removeQueries({
          queryKey: [
            QUERY_KEYS.children,
            { parentId: variables.targetContentId },
          ],
        });
        enqueueSnackbar('Your content was successfully updated!', {
          variant: 'success',
        });
      },
      onError: () => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        enqueueSnackbar('There was a problem updating your content.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useUpdateContentPosition = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (request: UpdateContentPositionRequestDto) =>
      contentsApi.updateContentPosition(request),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QUERY_KEYS.children);
        enqueueSnackbar('Your content position was successfully updated!', {
          variant: 'success',
        });
      },
      onError: () => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        enqueueSnackbar('There was a problem updating your content position.', {
          variant: 'error',
        });
      },
    }
  );
};

interface ICreateLargeFileUploadRequest {
  fileName: string;
  contentType: string;
  fileSize: number;
}

export const useCreateLargeFileUpload = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (request: ICreateLargeFileUploadRequest) => {
      return contentsApi.createLargeFileUpload({
        fileName: request.fileName,
        contentType: request.contentType,
        fileSize: request.fileSize,
      });
    },
    {
      onError: (error: { statusCode: number; message: string }) => {
        console.log('error', error);
        if (error.statusCode) {
          enqueueSnackbar(
            'There was a problem uploading your file. ' + error.message,
            {
              variant: 'error',
            }
          );
        } else {
          enqueueSnackbar('There was a problem uploading your file.', {
            variant: 'error',
          });
        }
      },
    }
  );
};

interface IUploadLargeFilePartRequest {
  chunk: Blob;
  chunkNumber: number;
  uploadId: string;
  fileObjectKey: string;
}

export const useUploadLargeFilePart = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (request: IUploadLargeFilePartRequest) => {
      const options: AxiosRequestConfig = {
        headers: {
          // this header disables AWS WAF - we need it to disable XSS check as some file uploads failed cause of that check
          'x-waf-uuid': '962f989c-0133-456c-81d6-cf46fa6aaa63',
        },
      };
      return contentsApi.uploadLargeFilePart(
        request.chunk,
        request.chunkNumber,
        request.uploadId,
        request.fileObjectKey,
        options
      );
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem uploading part of your file.', {
          variant: 'error',
        });
      },
    }
  );
};

interface ICompleteLargeFileRequest {
  chunks: { chunkNumber: number; entityTag: string }[];
  uploadId: string;
  fileObjectKey: string;
  fileName: string;
  contentType: string;
  parentId?: number;
}

export const useCompleteLargeFileUpload = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (request: ICompleteLargeFileRequest) => {
      return contentsApi.completeLargeFileUpload({
        uploadId: request.uploadId,
        fileObjectKey: request.fileObjectKey,
        chunks: request.chunks,
        fileName: request.fileName,
        contentType: request.contentType,
        parentId: request.parentId,
      });
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        enqueueSnackbar(`File was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem completing upload of file.', {
          variant: 'error',
        });
      },
    }
  );
};

interface IAbortLargeFileUploadRequest {
  uploadId: string;
  fileObjectKey: string;
}

export const useAbortLargeFileUpload = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (request: IAbortLargeFileUploadRequest) => {
      return contentsApi.abortLargeFileUpload({
        uploadId: request.uploadId,
        fileObjectKey: request.fileObjectKey,
      });
    },
    {
      onSuccess: async (data, variables) => {
        enqueueSnackbar(`File upload was successfully aborted!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem aborting file upload.', {
          variant: 'error',
        });
      },
    }
  );
};

interface IPublishedContent {
  contentId: number;
}
export function usePublishedContent(
  variables: IPublishedContent,
  { enabled }: { enabled: boolean }
) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    [QUERY_KEYS.content, variables],
    async () => {
      const response = await contentsApi.findOnePublishedContent(
        variables.contentId
      );
      return {
        ...response.data,
      };
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching your contents.', {
          variant: 'error',
        });
      },
      enabled,
    }
  );
}

export interface FindPublishedContentsParams {
  parentId?: number;
  page?: number;
  limit?: number;
  search?: string;
  contentTypes?: string[];
  nodeType: 'file' | 'directory';
  includeDirectories?: boolean;
}

export function usePublishedContents(params: FindPublishedContentsParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, params],
    async () => {
      const response = await contentsApi.findPublishedContents(
        params.parentId,
        params.page,
        params.limit,
        params.search,
        params.contentTypes,
        params.nodeType,
        params.includeDirectories
      );
      const files: IFile[] = response.data.results.map(mapContentToFile);
      return {
        ...response.data,
        results: files,
      };
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching your contents.', {
          variant: 'error',
        });
      },
    }
  );
}
