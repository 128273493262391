// ----------------------------------------------------------------------

import { fileFormatByContentType } from '@subflow-frontend/components/file-thumbnail';

export type IFileShared = {
  id: string;
  name: string;
  email: string;
  avatar: string;
  permission: string;
};

export type IFileBase<ID = number> = {
  id: ID;
  description: string;
  merchantId: string;
  merchantSubdomain: string;
  fileId?: string;
  contentType?: string;
  fileFormat?: ReturnType<typeof fileFormatByContentType>;
  published: boolean;
  accessType?: 'public' | 'restricted';
  thumbnailUrl: string;
  dateCreated: Date | number | string;
  dateModified: Date | number | string;
  url: string;
  fileType: string;
  position?: number;
  size: number;
  name: string;
};

export type IFolderManager<ID> = IFileBase<ID> & {
  fileType: 'folder';
  type: string;
  tags: string[];
  totalFiles?: number;
  isFavorited: boolean;
  shared: IFileShared[] | null;
};

export type IFileManager<ID> = IFileBase<ID> & {
  fileType: 'file';
  type: string;
  tags: string[];
  isFavorited: boolean;
  shared: IFileShared[] | null;
};

export type IFile<ID = number> = IFileManager<ID> | IFolderManager<ID>;

export function isFile<ID extends string | number = number>(
  file: unknown
): file is IFileBase<ID> {
  return (file as IFile)?.merchantId !== undefined;
}
