/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CurrentPlanResponseDto } from '../models';
// @ts-ignore
import { FundsAvailableResponseDto } from '../models';
// @ts-ignore
import { TotalSubscribersResponseDto } from '../models';
/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current plan organization is subscribed to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/plan/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available funds from stripe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundsAvailable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/funds/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get total contacts from all Products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalContacts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/contacts/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get total pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalPages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/pages/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get total segments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSegments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/segments/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get total sequences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSequences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/sequences/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get total subscriptions (products)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSubscriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/subscriptions/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get current plan organization is subscribed to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentPlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentPlanResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available funds from stripe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundsAvailable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FundsAvailableResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundsAvailable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get total contacts from all Products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalContacts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalSubscribersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalContacts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get total pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalPages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalSubscribersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalPages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get total segments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalSegments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalSubscribersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalSegments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get total sequences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalSequences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalSubscribersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalSequences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get total subscriptions (products)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalSubscriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalSubscribersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalSubscriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Get current plan organization is subscribed to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPlan(options?: any): AxiosPromise<CurrentPlanResponseDto> {
            return localVarFp.getCurrentPlan(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available funds from stripe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundsAvailable(options?: any): AxiosPromise<FundsAvailableResponseDto> {
            return localVarFp.getFundsAvailable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get total contacts from all Products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalContacts(options?: any): AxiosPromise<TotalSubscribersResponseDto> {
            return localVarFp.getTotalContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get total pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalPages(options?: any): AxiosPromise<TotalSubscribersResponseDto> {
            return localVarFp.getTotalPages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get total segments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSegments(options?: any): AxiosPromise<TotalSubscribersResponseDto> {
            return localVarFp.getTotalSegments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get total sequences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSequences(options?: any): AxiosPromise<TotalSubscribersResponseDto> {
            return localVarFp.getTotalSequences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get total subscriptions (products)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSubscriptions(options?: any): AxiosPromise<TotalSubscribersResponseDto> {
            return localVarFp.getTotalSubscriptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Get current plan organization is subscribed to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getCurrentPlan(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getCurrentPlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available funds from stripe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getFundsAvailable(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getFundsAvailable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get total contacts from all Products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getTotalContacts(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getTotalContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get total pages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getTotalPages(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getTotalPages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get total segments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getTotalSegments(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getTotalSegments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get total sequences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getTotalSequences(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getTotalSequences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get total subscriptions (products)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getTotalSubscriptions(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getTotalSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }
}
