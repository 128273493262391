import {
  AssignRoleRequestDto,
  AuthenticationApiFactory,
  Configuration,
  InviteMemberRequestDto,
  UpdateAccountRequestDto,
  CreateAccountRequestDto,
} from '@subflow/api-client';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL } from './axios';

const config = new Configuration({
  basePath: baseURL,
});

export const authApi = AuthenticationApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  invitations = 'auth/invitations',
  members = 'auth/members',
  account = 'auth/settings/account',
  devices = 'auth/settings/devices',
  credentials = 'auth/settings/credentials',
}

export const useCreateAccount = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation((body: CreateAccountRequestDto) => authApi.signup(body), {
    onSuccess: async (data, variables) => {
      enqueueSnackbar(`Account successfully created!`, {
        variant: 'success',
      });
      return data;
    },
    onError: (error: Error) => {
      enqueueSnackbar(
        `There was a problem creating your account.
          ${error.message}`,
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        }
      );
    },
  });
};

export const useInviteMember = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: InviteMemberRequestDto) => authApi.inviteOrgMember(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.invitations);
        enqueueSnackbar(`Invitation successfully created!`, {
          variant: 'success',
        });
      },
      onError: (error: Error) => {
        enqueueSnackbar(
          `There was a problem sending your invitation.
          ${error.message}`,
          {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
          }
        );
      },
    }
  );
};

export const useAccountSettings = (disabled?: boolean) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    QUERY_KEYS.account,
    async () => {
      const response = await authApi.getAccountSettings();
      return response.data;
    },
    {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar(`There was a problem getting your account settings.`, {
          variant: 'error',
        });
      },
      enabled: !disabled,
    }
  );
};

export const useOrgInvitations = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    QUERY_KEYS.invitations,
    async () => {
      const response = await authApi.getOrgInvitations();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar(
          `There was a problem getting your organization's invitations.`,
          {
            variant: 'error',
          }
        );
      },
    }
  );
};

export const useOrgMembers = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    QUERY_KEYS.members,
    async () => {
      const response = await authApi.getOrgMembers();
      return response.data;
    },
    {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar(
          `There was a problem getting your organization's members.`,
          {
            variant: 'error',
          }
        );
      },
    }
  );
};

export const useUpdateAccountSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    (body: UpdateAccountRequestDto) => authApi.updateAccountSettings(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.account);
        enqueueSnackbar(`Account updated successfully!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(`There was a problem updating your account.`, {
          variant: 'error',
        });
      },
    }
  );
};

interface IChangeEmailAddressVariables {
  email: string;
}

export const useChangeEmailAddress = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    (body: IChangeEmailAddressVariables) =>
      authApi.changeEmail({
        email: body.email,
      }),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(QUERY_KEYS.account);
      },
      onError: () => {
        enqueueSnackbar(`There was a problem updating your email address.`, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRemoveMember = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((userId: string) => authApi.removeOrgMember(userId), {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS.members);
      enqueueSnackbar(`Member removed successfully!`, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(`There was a problem removing the member.`, {
        variant: 'error',
      });
    },
  });
};

export const useRevokeSession = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((sessionId: string) => authApi.revokeSession(sessionId), {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS.devices);
      enqueueSnackbar(`Session signed out successfully!`, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(`There was a problem removing the sesssion.`, {
        variant: 'error',
      });
    },
  });
};

export const useGetDevices = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    QUERY_KEYS.devices,
    async () => {
      const response = await authApi.getDevices();
      return response.data;
    },
    {
      placeholderData: [],
      onError: () => {
        enqueueSnackbar(`There was a problem getting your device activity.`, {
          variant: 'error',
        });
      },
    }
  );
};

export const useResendInvitation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((userId: string) => authApi.resendOrgInvitation(userId), {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS.invitations);
      enqueueSnackbar(`Invitation successfully resent!`, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(`There was a problem resending your invitation.`, {
        variant: 'error',
      });
    },
  });
};

export const useCancelInvitation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((userId: string) => authApi.cancelOrgInvitation(userId), {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS.invitations);
      enqueueSnackbar(`Invitation canceled successfully!`, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(`There was a problem cancelling the invitation.`, {
        variant: 'error',
      });
    },
  });
};

interface IAssignRoleToMemberVariables {
  memberId: string;
  body: AssignRoleRequestDto;
}

export const useAssignRoleToMember = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (variables: IAssignRoleToMemberVariables) =>
      authApi.assignRole(variables.memberId, variables.body),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(QUERY_KEYS.members);
        enqueueSnackbar(`Role successfully updated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(`There was a problem updating a role.`, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAccountCredentials = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    QUERY_KEYS.credentials,
    async () => {
      const response = await authApi.getCredentials();
      return response.data;
    },
    {
      placeholderData: {
        results: [],
        totalCount: 0,
      },
      onError: () => {
        enqueueSnackbar(
          `There was a problem getting your account credentials.`,
          {
            variant: 'error',
          }
        );
      },
    }
  );
};

interface IRemoveCredentialVariables {
  credentialId: string;
}

export const useAccountDeleteCredential = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (variables: IRemoveCredentialVariables) =>
      authApi.removeCredential(variables.credentialId),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(QUERY_KEYS.credentials);
        enqueueSnackbar(`Credential successfully removed!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(`There was a problem removing your credential.`, {
          variant: 'error',
        });
      },
    }
  );
};
