import { baseURL, axios } from './axios';
import {
  Configuration,
  TollFreeNumberVerificationFormRequestDto,
  GoogleApiFactory,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const googleApi = GoogleApiFactory(config, baseURL, axios);

export const submitTollFreeNumberVerificationForm = async (
  body: TollFreeNumberVerificationFormRequestDto
): Promise<any> => {
  const response = await googleApi.submitTollFreeNumberVerificationForm(body);
  return response;
};
