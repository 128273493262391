import { useSnackbar } from 'notistack';
import { useQuery, useQueryClient } from 'react-query';
import { DateTime } from 'luxon';
import { axios, baseURL } from './axios';
import { Configuration, ConversationsApiFactory } from '@subflow/api-client';
import { PaginatedConversationsResponseDto } from '@subflow/api-client';
import { useCallback } from 'react';
import { debounce } from 'lodash';

const config = new Configuration({
  basePath: baseURL,
});

export const conversationApi = ConversationsApiFactory(config, baseURL, axios);

export interface UpdateConversationParams {
  conversationId: string | string[];
  body: any;
}
export enum CONVERSATIONS_QUERY_KEYS {
  list = 'conversations',
  single = 'conversation',
  usage = 'usage',
  totalUnreadMessages = 'totalUnreadMessages',
}

export const getConversation = async (conversationId: string): Promise<any> => {
  const response = await conversationApi.getConversation(conversationId);
  return response.data;
};

export function useConversation(conversationId: string) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [CONVERSATIONS_QUERY_KEYS.single, conversationId],
    async () => {
      const response = await conversationApi.getConversation(conversationId);
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your conversation.', {
          variant: 'error',
        });
      },
    }
  );
}

export const getConversationsList = async (
  limit: number,
  page?: number,
  search?: string,
  tags?: string[],
  unreadOnly?: boolean
): Promise<PaginatedConversationsResponseDto> => {
  const response = await conversationApi.getAllConversations(
    limit ? limit : 150,
    page ? page : 1,
    search ? search : undefined,
    tags ? tags : [],
    unreadOnly ? unreadOnly : false
  );
  return response.data;
};

export function useConversations(
  limit: number,
  page?: number,
  search?: string
) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [CONVERSATIONS_QUERY_KEYS.list, limit, page, search],
    async () => getConversationsList(limit, page, search),
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your conversations.', {
          variant: 'error',
        });
      },
    }
  );
}

export const fetchMessages = async (
  conversationId: string,
  limit: number,
  cursor?: string
): Promise<any> => {
  const response = await conversationApi.getMessages(
    conversationId,
    cursor,
    limit
  );
  return response.data;
};

export function useMessages(
  conversationId: string,
  limit: number,
  cursor?: string
) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [
      CONVERSATIONS_QUERY_KEYS.single,
      conversationId,
      'messages',
      limit,
      cursor,
    ],
    async () => {
      const response = await conversationApi.getMessages(
        conversationId,
        cursor,
        limit
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your messages.', {
          variant: 'error',
        });
      },
    }
  );
}

export function use30DaysUsage() {
  const { enqueueSnackbar } = useSnackbar();
  const endTimestamp = +DateTime.utc();
  const startTimestamp = +DateTime.utc().minus({ days: 29 });

  return useQuery(
    [CONVERSATIONS_QUERY_KEYS.usage],
    async () => {
      const response = await conversationApi.getUsage(
        startTimestamp,
        endTimestamp
      );
      return response.data as any;
    },
    {
      onError: () => {
        enqueueSnackbar(
          'There was a problem fetching your conversation usage.',
          {
            variant: 'error',
          }
        );
      },
    }
  );
}

export function useTotalUnreadMessages(disabled?: boolean) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    [CONVERSATIONS_QUERY_KEYS.totalUnreadMessages],
    async () => {
      const response = await conversationApi.getTotalUnreadMessages();
      return response.data?.count;
    },
    {
      enabled: !disabled,
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar(
          'Unable to retrieve the total count of unread messages.',
          {
            variant: 'error',
          }
        );
      },
    }
  );
}

export function useInvalidateTotalUnreadMessages() {
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const invalidateTotalUnreadMessages = useCallback(
    debounce(() => {
      queryClient?.invalidateQueries([
        CONVERSATIONS_QUERY_KEYS.totalUnreadMessages,
      ]);
    }, 250),
    [queryClient]
  );

  return { invalidateTotalUnreadMessages };
}
