import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import {
  Configuration,
  CreateSequenceRequestDto,
  SequencesApiFactory,
  UpdateSequenceRequestDto,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const sequencesApi = SequencesApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'sequences',
  infiniteList = 'infiniteSequences',
  single = 'sequence',
  sequenceSubscribers = 'sequenceSubscribers',
  sequenceContacts = 'sequenceContacts',
  sequenceSchedulesBySequenceAndContact = 'sequenceSchedulesBySequenceAndContact',
}

export const useSequence = (sequenceId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, sequenceId],
    async () => {
      const response = await sequencesApi.findOneSequence(sequenceId);
      return response.data;
    },
    {
      enabled: Boolean(sequenceId),
      onError: () => {
        enqueueSnackbar('There was a problem fetching your sequence.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface PaginatedSequencesParams {
  page?: number;
  limit?: number;
  search?: string;
}

export function useSequences(params: PaginatedSequencesParams) {
  const { page, limit } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, { page, limit, search }],
    async () => {
      const response = await sequencesApi.findAllSequences(page, limit, search);
      return {
        ...response.data,
        results: response.data?.results || [],
      };
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your sequences.', {
          variant: 'error',
        });
      },
    }
  );
}

export interface PaginatedSequenceSchedulesParams {
  sequenceId: string;
  page?: number;
  limit?: number;
  search?: string;
}

export function useSequenceContacts(params: PaginatedSequenceSchedulesParams) {
  const { sequenceId, page, limit } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.sequenceContacts, { sequenceId, page, limit, search }],
    async () => {
      const response = await sequencesApi.findPaginatedContactsForSequence(
        sequenceId,
        page,
        limit,
        search
      );
      return {
        ...response.data,
        results: response.data?.results || [],
      };
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your contacts.', {
          variant: 'error',
        });
      },
    }
  );
}

export function useInfiniteSequences(
  params: Omit<PaginatedSequencesParams, 'page'>
) {
  const { limit } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useInfiniteQuery(
    [QUERY_KEYS.infiniteList, limit, search],
    async ({ pageParam = 1 }) => {
      const response = await sequencesApi.findAllSequences(
        pageParam,
        limit,
        search
      );
      return {
        ...response.data,
        results: response.data?.results || [],
      };
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your sequences.', {
          variant: 'error',
        });
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.results.length === 0) return undefined;

        return lastPage.page + 1 ?? undefined;
      },
      getPreviousPageParam: (lastPage) => {
        if (lastPage.results.length === 0) return undefined;

        return lastPage.page - 1 ?? undefined;
      },
    }
  );
}

export const useCreateSequence = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: CreateSequenceRequestDto) => sequencesApi.createSequence(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar(`${variables.name} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your sequence.', {
          variant: 'error',
        });
      },
    }
  );
};

interface DuplicateSequenceRequest {
  originalSequenceId: string;
}

export const useDuplicateSequence = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: DuplicateSequenceRequest) =>
      sequencesApi.duplicateSequence(request.originalSequenceId),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar(`Sequence was successfully duplicated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem duplicating your sequence.', {
          variant: 'error',
        });
      },
    }
  );
};

interface updateSequenceRequestId {
  body: UpdateSequenceRequestDto;
  sequenceId: string;
}

export const useUpdateSequence = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ body, sequenceId }: updateSequenceRequestId) =>
      sequencesApi.updateSequence(sequenceId, body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        queryClient.invalidateQueries([
          QUERY_KEYS.single,
          variables.sequenceId,
        ]);
        enqueueSnackbar(
          `Sequence ${variables.body.name ?? ''} was successfully updated!`,
          {
            variant: 'success',
          }
        );
      },
      onError: async () => {
        enqueueSnackbar('There was a problem updating your sequence.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteSequence = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (sequenceId: string) => sequencesApi.deleteSequence(sequenceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar('Your sequence was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your sequence.', {
          variant: 'error',
        });
      },
    }
  );
};

interface scheduleSequenceParams {
  sequenceId: string;
  contactIds?: string[];
  segmentIds?: string[];
  initialSendTime: {
    year: number;
    month: number;
    day: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
}

export const scheduleSequence = async ({
  sequenceId,
  contactIds = [],
  segmentIds = [],
  initialSendTime,
}: scheduleSequenceParams): Promise<any> => {
  const response = await sequencesApi.scheduleSequence({
    sequenceId,
    contactIds,
    segmentIds,
    initialSendTime,
  });
  return response;
};

interface ISendSequenceParams {
  sequenceId: string;
  contactIds?: string[];
  segmentIds?: string[];
}

export const sendSequence = async ({
  sequenceId,
  contactIds = [],
  segmentIds = [],
}: ISendSequenceParams) => {
  return sequencesApi.send({
    sequenceId,
    contactIds,
    segmentIds,
  });
};

interface CancelSequenceByContact {
  sequenceId: string;
  contactId: string;
}

export const useCancelSequenceByContact = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: CancelSequenceByContact) =>
      sequencesApi.cancelSequenceByContact(
        request.sequenceId,
        request.contactId
      ),
    {
      onSuccess: async (data, variables) => {},
      onError: () => {
        enqueueSnackbar('There was a problem canceling this sequence.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface SequenceSchedulesBySequenceAndContactParams {
  sequenceId: string;
  contactId: string;
}

export function useSequenceSchedulesBySequenceAndSubscriber(
  params: SequenceSchedulesBySequenceAndContactParams
) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.sequenceSchedulesBySequenceAndContact, params],
    async () => {
      const response =
        await sequencesApi.findSequenceSchedulesBySequenceAndContact(
          params.sequenceId,
          params.contactId
        );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your schedules.', {
          variant: 'error',
        });
      },
    }
  );
}
