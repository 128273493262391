/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbortLargeFileUploadRequestDto } from '../models';
// @ts-ignore
import { ChildrenResponseDto } from '../models';
// @ts-ignore
import { CompleteLargeFileUploadRequestDto } from '../models';
// @ts-ignore
import { ContentResponseDto } from '../models';
// @ts-ignore
import { CreateDirectoryRequestDto } from '../models';
// @ts-ignore
import { CreateLargeFileUploadRequestDto } from '../models';
// @ts-ignore
import { CreateLargeFileUploadResponseDto } from '../models';
// @ts-ignore
import { MoveContentRequestDto } from '../models';
// @ts-ignore
import { SendContentRequestDto } from '../models';
// @ts-ignore
import { UpdateContentPositionRequestDto } from '../models';
// @ts-ignore
import { UpdateContentRequestDto } from '../models';
// @ts-ignore
import { UploadLargeFilePartResponseDto } from '../models';
/**
 * ContentsApi - axios parameter creator
 * @export
 */
export const ContentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  Abort large file upload
         * @param {AbortLargeFileUploadRequestDto} abortLargeFileUploadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortLargeFileUpload: async (abortLargeFileUploadRequestDto: AbortLargeFileUploadRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'abortLargeFileUploadRequestDto' is not null or undefined
            assertParamExists('abortLargeFileUpload', 'abortLargeFileUploadRequestDto', abortLargeFileUploadRequestDto)
            const localVarPath = `/v1/contents/large-file/abort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(abortLargeFileUploadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete upload of large file
         * @param {CompleteLargeFileUploadRequestDto} completeLargeFileUploadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeLargeFileUpload: async (completeLargeFileUploadRequestDto: CompleteLargeFileUploadRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeLargeFileUploadRequestDto' is not null or undefined
            assertParamExists('completeLargeFileUpload', 'completeLargeFileUploadRequestDto', completeLargeFileUploadRequestDto)
            const localVarPath = `/v1/contents/large-file/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeLargeFileUploadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single directory-type content node
         * @param {CreateDirectoryRequestDto} createDirectoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory: async (createDirectoryRequestDto: CreateDirectoryRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDirectoryRequestDto' is not null or undefined
            assertParamExists('createDirectory', 'createDirectoryRequestDto', createDirectoryRequestDto)
            const localVarPath = `/v1/contents/directory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDirectoryRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single file-type content node
         * @param {any} file 
         * @param {string} fileName 
         * @param {string} contentType 
         * @param {number} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (file: any, fileName: string, contentType: string, parentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createFile', 'file', file)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('createFile', 'fileName', fileName)
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('createFile', 'contentType', contentType)
            const localVarPath = `/v1/contents/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (parentId !== undefined) { 
                localVarFormParams.append('parentId', parentId as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('contentType', contentType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single large file upload
         * @param {CreateLargeFileUploadRequestDto} createLargeFileUploadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLargeFileUpload: async (createLargeFileUploadRequestDto: CreateLargeFileUploadRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLargeFileUploadRequestDto' is not null or undefined
            assertParamExists('createLargeFileUpload', 'createLargeFileUploadRequestDto', createLargeFileUploadRequestDto)
            const localVarPath = `/v1/contents/large-file/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLargeFileUploadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete content
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContent', 'id', id)
            const localVarPath = `/v1/contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple contents
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllContents: async (page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (contentTypes) {
                localVarQueryParameter['contentTypes'] = contentTypes;
            }

            if (nodeType !== undefined) {
                localVarQueryParameter['nodeType'] = nodeType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve children
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {boolean} [includeDirectories] 
         * @param {'createdDate' | 'position'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChildren: async (parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', includeDirectories?: boolean, sortBy?: 'createdDate' | 'position', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contents/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (contentTypes) {
                localVarQueryParameter['contentTypes'] = contentTypes;
            }

            if (nodeType !== undefined) {
                localVarQueryParameter['nodeType'] = nodeType;
            }

            if (includeDirectories !== undefined) {
                localVarQueryParameter['includeDirectories'] = includeDirectories;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one published content
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOnePublishedContent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOnePublishedContent', 'id', id)
            const localVarPath = `/v1/contents/published/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find published contents
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {boolean} [includeDirectories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublishedContents: async (parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', includeDirectories?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contents/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (contentTypes) {
                localVarQueryParameter['contentTypes'] = contentTypes;
            }

            if (nodeType !== undefined) {
                localVarQueryParameter['nodeType'] = nodeType;
            }

            if (includeDirectories !== undefined) {
                localVarQueryParameter['includeDirectories'] = includeDirectories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move a content
         * @param {MoveContentRequestDto} moveContentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContent: async (moveContentRequestDto: MoveContentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moveContentRequestDto' is not null or undefined
            assertParamExists('moveContent', 'moveContentRequestDto', moveContentRequestDto)
            const localVarPath = `/v1/contents/move`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveContentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a content
         * @param {SendContentRequestDto} sendContentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContent: async (sendContentRequestDto: SendContentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendContentRequestDto' is not null or undefined
            assertParamExists('sendContent', 'sendContentRequestDto', sendContentRequestDto)
            const localVarPath = `/v1/contents/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendContentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a content
         * @param {number} id 
         * @param {UpdateContentRequestDto} updateContentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContent: async (id: number, updateContentRequestDto: UpdateContentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContent', 'id', id)
            // verify required parameter 'updateContentRequestDto' is not null or undefined
            assertParamExists('updateContent', 'updateContentRequestDto', updateContentRequestDto)
            const localVarPath = `/v1/contents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a content position
         * @param {UpdateContentPositionRequestDto} updateContentPositionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentPosition: async (updateContentPositionRequestDto: UpdateContentPositionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateContentPositionRequestDto' is not null or undefined
            assertParamExists('updateContentPosition', 'updateContentPositionRequestDto', updateContentPositionRequestDto)
            const localVarPath = `/v1/contents/position`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContentPositionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a single part of large file
         * @param {any} chunk 
         * @param {number} chunkNumber 
         * @param {string} uploadId 
         * @param {string} fileObjectKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLargeFilePart: async (chunk: any, chunkNumber: number, uploadId: string, fileObjectKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chunk' is not null or undefined
            assertParamExists('uploadLargeFilePart', 'chunk', chunk)
            // verify required parameter 'chunkNumber' is not null or undefined
            assertParamExists('uploadLargeFilePart', 'chunkNumber', chunkNumber)
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('uploadLargeFilePart', 'uploadId', uploadId)
            // verify required parameter 'fileObjectKey' is not null or undefined
            assertParamExists('uploadLargeFilePart', 'fileObjectKey', fileObjectKey)
            const localVarPath = `/v1/contents/large-file/upload-part`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (chunk !== undefined) { 
                localVarFormParams.append('chunk', chunk as any);
            }
    
            if (chunkNumber !== undefined) { 
                localVarFormParams.append('chunkNumber', chunkNumber as any);
            }
    
            if (uploadId !== undefined) { 
                localVarFormParams.append('uploadId', uploadId as any);
            }
    
            if (fileObjectKey !== undefined) { 
                localVarFormParams.append('fileObjectKey', fileObjectKey as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a thumbnail for a single content
         * @param {any} thumbnail 
         * @param {string} fileName 
         * @param {string} contentType 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadThumbnail: async (thumbnail: any, fileName: string, contentType: string, contentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thumbnail' is not null or undefined
            assertParamExists('uploadThumbnail', 'thumbnail', thumbnail)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('uploadThumbnail', 'fileName', fileName)
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('uploadThumbnail', 'contentType', contentType)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('uploadThumbnail', 'contentId', contentId)
            const localVarPath = `/v1/contents/thumbnail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (thumbnail !== undefined) { 
                localVarFormParams.append('thumbnail', thumbnail as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('contentType', contentType as any);
            }
    
            if (contentId !== undefined) { 
                localVarFormParams.append('contentId', contentId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentsApi - functional programming interface
 * @export
 */
export const ContentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  Abort large file upload
         * @param {AbortLargeFileUploadRequestDto} abortLargeFileUploadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abortLargeFileUpload(abortLargeFileUploadRequestDto: AbortLargeFileUploadRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abortLargeFileUpload(abortLargeFileUploadRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete upload of large file
         * @param {CompleteLargeFileUploadRequestDto} completeLargeFileUploadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeLargeFileUpload(completeLargeFileUploadRequestDto: CompleteLargeFileUploadRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeLargeFileUpload(completeLargeFileUploadRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single directory-type content node
         * @param {CreateDirectoryRequestDto} createDirectoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDirectory(createDirectoryRequestDto: CreateDirectoryRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDirectory(createDirectoryRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single file-type content node
         * @param {any} file 
         * @param {string} fileName 
         * @param {string} contentType 
         * @param {number} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(file: any, fileName: string, contentType: string, parentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(file, fileName, contentType, parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single large file upload
         * @param {CreateLargeFileUploadRequestDto} createLargeFileUploadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLargeFileUpload(createLargeFileUploadRequestDto: CreateLargeFileUploadRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateLargeFileUploadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLargeFileUpload(createLargeFileUploadRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete content
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContent(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple contents
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllContents(page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildrenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllContents(page, limit, search, contentTypes, nodeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve children
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {boolean} [includeDirectories] 
         * @param {'createdDate' | 'position'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findChildren(parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', includeDirectories?: boolean, sortBy?: 'createdDate' | 'position', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildrenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findChildren(parentId, page, limit, search, contentTypes, nodeType, includeDirectories, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one published content
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOnePublishedContent(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOnePublishedContent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find published contents
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {boolean} [includeDirectories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPublishedContents(parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', includeDirectories?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildrenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPublishedContents(parentId, page, limit, search, contentTypes, nodeType, includeDirectories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move a content
         * @param {MoveContentRequestDto} moveContentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContent(moveContentRequestDto: MoveContentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveContent(moveContentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a content
         * @param {SendContentRequestDto} sendContentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendContent(sendContentRequestDto: SendContentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendContent(sendContentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a content
         * @param {number} id 
         * @param {UpdateContentRequestDto} updateContentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContent(id: number, updateContentRequestDto: UpdateContentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContent(id, updateContentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a content position
         * @param {UpdateContentPositionRequestDto} updateContentPositionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentPosition(updateContentPositionRequestDto: UpdateContentPositionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContentPosition(updateContentPositionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a single part of large file
         * @param {any} chunk 
         * @param {number} chunkNumber 
         * @param {string} uploadId 
         * @param {string} fileObjectKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLargeFilePart(chunk: any, chunkNumber: number, uploadId: string, fileObjectKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLargeFilePartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLargeFilePart(chunk, chunkNumber, uploadId, fileObjectKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a thumbnail for a single content
         * @param {any} thumbnail 
         * @param {string} fileName 
         * @param {string} contentType 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadThumbnail(thumbnail: any, fileName: string, contentType: string, contentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadThumbnail(thumbnail, fileName, contentType, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentsApi - factory interface
 * @export
 */
export const ContentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentsApiFp(configuration)
    return {
        /**
         * 
         * @summary  Abort large file upload
         * @param {AbortLargeFileUploadRequestDto} abortLargeFileUploadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortLargeFileUpload(abortLargeFileUploadRequestDto: AbortLargeFileUploadRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.abortLargeFileUpload(abortLargeFileUploadRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete upload of large file
         * @param {CompleteLargeFileUploadRequestDto} completeLargeFileUploadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeLargeFileUpload(completeLargeFileUploadRequestDto: CompleteLargeFileUploadRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.completeLargeFileUpload(completeLargeFileUploadRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single directory-type content node
         * @param {CreateDirectoryRequestDto} createDirectoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory(createDirectoryRequestDto: CreateDirectoryRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.createDirectory(createDirectoryRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single file-type content node
         * @param {any} file 
         * @param {string} fileName 
         * @param {string} contentType 
         * @param {number} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(file: any, fileName: string, contentType: string, parentId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.createFile(file, fileName, contentType, parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single large file upload
         * @param {CreateLargeFileUploadRequestDto} createLargeFileUploadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLargeFileUpload(createLargeFileUploadRequestDto: CreateLargeFileUploadRequestDto, options?: any): AxiosPromise<CreateLargeFileUploadResponseDto> {
            return localVarFp.createLargeFileUpload(createLargeFileUploadRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete content
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple contents
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllContents(page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', options?: any): AxiosPromise<ChildrenResponseDto> {
            return localVarFp.findAllContents(page, limit, search, contentTypes, nodeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve children
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {boolean} [includeDirectories] 
         * @param {'createdDate' | 'position'} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChildren(parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', includeDirectories?: boolean, sortBy?: 'createdDate' | 'position', options?: any): AxiosPromise<ChildrenResponseDto> {
            return localVarFp.findChildren(parentId, page, limit, search, contentTypes, nodeType, includeDirectories, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one published content
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOnePublishedContent(id: number, options?: any): AxiosPromise<ContentResponseDto> {
            return localVarFp.findOnePublishedContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find published contents
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {boolean} [includeDirectories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublishedContents(parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', includeDirectories?: boolean, options?: any): AxiosPromise<ChildrenResponseDto> {
            return localVarFp.findPublishedContents(parentId, page, limit, search, contentTypes, nodeType, includeDirectories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move a content
         * @param {MoveContentRequestDto} moveContentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContent(moveContentRequestDto: MoveContentRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.moveContent(moveContentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a content
         * @param {SendContentRequestDto} sendContentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContent(sendContentRequestDto: SendContentRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendContent(sendContentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a content
         * @param {number} id 
         * @param {UpdateContentRequestDto} updateContentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContent(id: number, updateContentRequestDto: UpdateContentRequestDto, options?: any): AxiosPromise<ContentResponseDto> {
            return localVarFp.updateContent(id, updateContentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a content position
         * @param {UpdateContentPositionRequestDto} updateContentPositionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentPosition(updateContentPositionRequestDto: UpdateContentPositionRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateContentPosition(updateContentPositionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a single part of large file
         * @param {any} chunk 
         * @param {number} chunkNumber 
         * @param {string} uploadId 
         * @param {string} fileObjectKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLargeFilePart(chunk: any, chunkNumber: number, uploadId: string, fileObjectKey: string, options?: any): AxiosPromise<UploadLargeFilePartResponseDto> {
            return localVarFp.uploadLargeFilePart(chunk, chunkNumber, uploadId, fileObjectKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a thumbnail for a single content
         * @param {any} thumbnail 
         * @param {string} fileName 
         * @param {string} contentType 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadThumbnail(thumbnail: any, fileName: string, contentType: string, contentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.uploadThumbnail(thumbnail, fileName, contentType, contentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentsApi - object-oriented interface
 * @export
 * @class ContentsApi
 * @extends {BaseAPI}
 */
export class ContentsApi extends BaseAPI {
    /**
     * 
     * @summary  Abort large file upload
     * @param {AbortLargeFileUploadRequestDto} abortLargeFileUploadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public abortLargeFileUpload(abortLargeFileUploadRequestDto: AbortLargeFileUploadRequestDto, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).abortLargeFileUpload(abortLargeFileUploadRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete upload of large file
     * @param {CompleteLargeFileUploadRequestDto} completeLargeFileUploadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public completeLargeFileUpload(completeLargeFileUploadRequestDto: CompleteLargeFileUploadRequestDto, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).completeLargeFileUpload(completeLargeFileUploadRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single directory-type content node
     * @param {CreateDirectoryRequestDto} createDirectoryRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public createDirectory(createDirectoryRequestDto: CreateDirectoryRequestDto, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).createDirectory(createDirectoryRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single file-type content node
     * @param {any} file 
     * @param {string} fileName 
     * @param {string} contentType 
     * @param {number} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public createFile(file: any, fileName: string, contentType: string, parentId?: number, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).createFile(file, fileName, contentType, parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single large file upload
     * @param {CreateLargeFileUploadRequestDto} createLargeFileUploadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public createLargeFileUpload(createLargeFileUploadRequestDto: CreateLargeFileUploadRequestDto, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).createLargeFileUpload(createLargeFileUploadRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete content
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public deleteContent(id: number, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).deleteContent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple contents
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {Array<string>} [contentTypes] 
     * @param {'directory' | 'file'} [nodeType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public findAllContents(page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).findAllContents(page, limit, search, contentTypes, nodeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve children
     * @param {number} [parentId] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {Array<string>} [contentTypes] 
     * @param {'directory' | 'file'} [nodeType] 
     * @param {boolean} [includeDirectories] 
     * @param {'createdDate' | 'position'} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public findChildren(parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', includeDirectories?: boolean, sortBy?: 'createdDate' | 'position', options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).findChildren(parentId, page, limit, search, contentTypes, nodeType, includeDirectories, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one published content
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public findOnePublishedContent(id: number, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).findOnePublishedContent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find published contents
     * @param {number} [parentId] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {Array<string>} [contentTypes] 
     * @param {'directory' | 'file'} [nodeType] 
     * @param {boolean} [includeDirectories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public findPublishedContents(parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', includeDirectories?: boolean, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).findPublishedContents(parentId, page, limit, search, contentTypes, nodeType, includeDirectories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move a content
     * @param {MoveContentRequestDto} moveContentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public moveContent(moveContentRequestDto: MoveContentRequestDto, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).moveContent(moveContentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a content
     * @param {SendContentRequestDto} sendContentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public sendContent(sendContentRequestDto: SendContentRequestDto, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).sendContent(sendContentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a content
     * @param {number} id 
     * @param {UpdateContentRequestDto} updateContentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public updateContent(id: number, updateContentRequestDto: UpdateContentRequestDto, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).updateContent(id, updateContentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a content position
     * @param {UpdateContentPositionRequestDto} updateContentPositionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public updateContentPosition(updateContentPositionRequestDto: UpdateContentPositionRequestDto, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).updateContentPosition(updateContentPositionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a single part of large file
     * @param {any} chunk 
     * @param {number} chunkNumber 
     * @param {string} uploadId 
     * @param {string} fileObjectKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public uploadLargeFilePart(chunk: any, chunkNumber: number, uploadId: string, fileObjectKey: string, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).uploadLargeFilePart(chunk, chunkNumber, uploadId, fileObjectKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a thumbnail for a single content
     * @param {any} thumbnail 
     * @param {string} fileName 
     * @param {string} contentType 
     * @param {number} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public uploadThumbnail(thumbnail: any, fileName: string, contentType: string, contentId: number, options?: AxiosRequestConfig) {
        return ContentsApiFp(this.configuration).uploadThumbnail(thumbnail, fileName, contentType, contentId, options).then((request) => request(this.axios, this.basePath));
    }
}
