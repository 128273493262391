import { Theme } from '@mui/material/styles';
import { Box, Tooltip, SxProps, Stack } from '@mui/material';
//
import { fileData, filePlaceholderByFileFormat, fileThumb } from './utils';
import DownloadButton from './DownloadButton';
import { IFileBase } from '@subflow-frontend/@types/file';
import Image from '@subflow-frontend/components/image';
import NextImage from 'next/future/image';

// ----------------------------------------------------------------------

type FileIconProps<ID extends string | number = number> = {
  file: File | string | IFileBase<ID>;
  tooltip?: boolean;
  imageView?: boolean;
  showPreview?: boolean;
  onDownload?: VoidFunction;
  sx?: SxProps<Theme>;
  imgSx?: SxProps<Theme>;
  placeholder?: boolean;
};

const SUPPORTED_THUMBNAIL_FORMATS = [
  'image',
  'video',
  'pdf',
  'audio',
  'folder',
];
const FileThumbnail = <ID extends string | number = number>({
  file,
  tooltip,
  imageView,
  showPreview,
  onDownload,
  sx,
  imgSx,
  placeholder,
}: FileIconProps<ID>) => {
  const { name = '', preview = '', format, thumbnail } = fileData(file);
  const renderPreview = () => {
    if (thumbnail && SUPPORTED_THUMBNAIL_FORMATS.includes(format)) {
      return (
        <Image
          src={thumbnail}
          sx={{
            width: 32,
            height: 32,
            flexShrink: 0,
            ...sx,
          }}
        />
      );
    } else {
      return (
        <Box
          component="img"
          src={fileThumb(format)}
          sx={{
            width: 32,
            height: 32,
            flexShrink: 0,
            ...sx,
          }}
        />
      );
    }
  };

  const renderContent =
    format === 'image' && imageView ? (
      <Image
        src={preview}
        sx={{
          width: 1,
          height: 1,
          flexShrink: 0,
          objectFit: 'cover',
          ...imgSx,
        }}
      />
    ) : showPreview ? (
      <>{renderPreview()}</>
    ) : (
      <Box
        sx={{
          width: 32,
          height: 32,
          flexShrink: 0,
          position: 'relative',
          ...sx,
        }}
      >
        <NextImage
          src={
            placeholder
              ? filePlaceholderByFileFormat(format)
              : fileThumb(format)
          }
          alt=""
          fill
          style={{
            objectFit: 'contain',
            filter: 'drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.12))',
          }}
        />
      </Box>
    );

  if (tooltip) {
    return (
      <Tooltip title={name}>
        <Stack
          flexShrink={0}
          component="span"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 'fit-content',
            height: 'inherit',
          }}
        >
          {renderContent}
          {onDownload && <DownloadButton onDownload={onDownload} />}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <>
      {renderContent}
      {onDownload && <DownloadButton onDownload={onDownload} />}
    </>
  );
};
export default FileThumbnail;
