import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import {
  Configuration,
  CreateEmailCampaignRequestDto,
  UpdateEmailCampaignRequestDto,
  EmailCampaignsApiFactory,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const emailCampaignsApi = EmailCampaignsApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'email-campaigns',
  single = 'email-campaign',
}

export interface PaginatedEmailCampaignParams {
  page?: number;
  limit?: number;
  search?: string;
}

export const useEmailCampaign = (emailCampaignId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, emailCampaignId],
    async () => {
      const response = await emailCampaignsApi.findOneEmailCampaign(
        emailCampaignId
      );
      return response.data;
    },
    {
      enabled: !!emailCampaignId,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your email campaign.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useEmailCampaigns = (params: PaginatedEmailCampaignParams) => {
  const { page, limit } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, { page, limit, search }],
    async () => {
      const response = await emailCampaignsApi.findAllEmailCampaigns(
        page,
        limit,
        search
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your email campaigns.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useCreateEmailCampaign = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: CreateEmailCampaignRequestDto) =>
      emailCampaignsApi.createEmailCampaign(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar('Your email campaign was successfully created!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your email campaign.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useUpdateEmailCampaign = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (params: { id: string; request: UpdateEmailCampaignRequestDto }) =>
      emailCampaignsApi.updateEmailCampaign(params.id, params.request),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.single, variables.id],
        });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        if (data.data.status === 'draft') {
          enqueueSnackbar('Your email campaign was successfully updated!', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(
            `Your email campaign was successfully ${data.data.status}!`,
            {
              variant: 'success',
            }
          );
        }
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating your email campaign.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteEmailCampaign = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (emailCampaignId: string) =>
      emailCampaignsApi.deleteEmailCampaign(emailCampaignId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar('Your email campaign was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your email campaign.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface PaginatedEmailCampaignRecipientsParams {
  emailCampaignId: string;
  page?: number;
  limit?: number;
  search?: string;
  status?:
    | 'delivered'
    | 'unsubscribed'
    | 'permanent_fail'
    | 'clicked_once'
    | 'opened_once'
    | 'complained';
}

export const useEmailCampaignRecipients = (
  params?: PaginatedEmailCampaignRecipientsParams
) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, params],
    async () => {
      if (!params) throw new Error('Something went wrong');
      const response = await emailCampaignsApi.findEmailCampaignRecipients(
        params.emailCampaignId,
        params.page,
        params.limit,
        params.search,
        params.status
      );
      return response.data;
    },
    {
      enabled: !!params,
      onError: () => {
        enqueueSnackbar(
          'There was a problem fetching your email campaign recipients.',
          {
            variant: 'error',
          }
        );
      },
    }
  );
};
