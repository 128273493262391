import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import {
  Configuration,
  CreateMeetingRequestDto,
  MeetingsApiFactory,
  UpdateMeetingRequestDto,
  SendMeetingLinkRequestDto,
  ShareMeetingRequestDto,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const meetingsApi = MeetingsApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'meetings',
  single = 'meeting',
}

export const useCreateMeeting = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: CreateMeetingRequestDto) => meetingsApi.createMeeting(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar(`${variables.title} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your meeting.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface UpdateMeetingRequestWithId {
  meetingId: string;
  body: UpdateMeetingRequestDto;
}

export const useUpdateMeeting = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ meetingId, body }: UpdateMeetingRequestWithId) =>
      meetingsApi.updateMeeting(meetingId, body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        queryClient.invalidateQueries([QUERY_KEYS.single, variables.meetingId]);
        enqueueSnackbar(`${variables.body.title} was successfully updated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating your meeting.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useMeeting = (meetingId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, meetingId],
    async () => {
      const response = await meetingsApi.findOneMeeting(meetingId);
      return response.data;
    },
    {
      enabled: Boolean(meetingId),
      refetchOnMount: false,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your meeting.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteMeeting = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (meetingId: string) => meetingsApi.deleteMeeting(meetingId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Your meeting was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your meeting.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface PaginatedMeetingsParams {
  page?: number;
  limit?: number;
  search?: string;
}

export function useMeetings({ page, limit, search }: PaginatedMeetingsParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, page, limit, search],
    async () => {
      const response = await meetingsApi.findPaginatedMeetings(
        page,
        limit,
        search
      );
      return response.data;
    },
    {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your meetings.', {
          variant: 'error',
        });
      },
    }
  );
}

export const useShareMeeting = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: ShareMeetingRequestDto) => meetingsApi.shareMeeting(request),
    {
      onSuccess: (res) => {
        enqueueSnackbar('Your meeting was successfully shared!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem sharing your meeting.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSendMeeting = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: SendMeetingLinkRequestDto) =>
      meetingsApi.sendMeetingLink(request),
    {
      onSuccess: () => {
        enqueueSnackbar('Your meeting was successfully sent!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem sending your meeting.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDuplicateMeeting = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: { meetingId: string }) =>
      meetingsApi.duplicateMeeting(request.meetingId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Your meeting was successfully duplicated!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem duplicating your meeting.', {
          variant: 'error',
        });
      },
    }
  );
};
