import {
  Configuration,
  MerchantsApiFactory,
  UpdateMerchantRequestDto,
} from '@subflow/api-client';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL, getOrganizationId, setOrganizationId } from './axios';

const config = new Configuration({
  basePath: baseURL,
});

export const merchantsApi = MerchantsApiFactory(config, baseURL, axios);

interface UpdateMerchantRequestId {
  body: UpdateMerchantRequestDto;
}

enum QUERY_KEYS {
  single = 'merchant',
}

export { QUERY_KEYS as MERCHANT_QUERY_KEYS };

export const checkMerchantExists = async (subdomain: string): Promise<any> => {
  const response = await merchantsApi.checkMerchantBySubdomain(subdomain);
  return response.data;
};

export const useIsMerchantCanceled = () => {
  return useQuery('is-canceled', async () => {
    const response = await merchantsApi.isCanceled();
    return response.data;
  });
};

export const useMerchant = (disabled?: boolean) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single],
    async () => {
      const response = await merchantsApi.findOneMerchant();
      return response.data;
    },
    {
      onError: () => {
        // If we are unable to access the merchant, we should unset the current organization
        // setOrganizationId('personal');

        enqueueSnackbar(
          'There was a problem fetching your organization settings.',
          {
            variant: 'error',
          }
        );
      },
      staleTime: Infinity,
      // only run this query if the user is not in personal mode
      enabled: !disabled && getOrganizationId() !== 'personal',
    }
  );
};

export const usePublicMerchant = (
  { subdomain }: { subdomain: string },
  enabled = true
) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['public-merchant', { subdomain }],
    async () => {
      const response = await merchantsApi.getPublicMerchantBySubdomain(
        subdomain
      );

      return response.data;
    },
    {
      onError: () => {
        // If we are unable to access the merchant, we should unset the current organization
        // setOrganizationId('personal');

        enqueueSnackbar(
          'There was a problem finding the merchant you are looking for.',
          {
            variant: 'error',
          }
        );
      },
      enabled,
    }
  );
};
export const useGetPublicDataForMerchants = (
  { merchantIds }: { merchantIds: string[] },
  enabled = true
) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['public-merchants', { merchantIds }],
    async () => {
      const response = await merchantsApi.getPublicDataForMerchants({
        merchantIds,
      });

      return response.data;
    },
    {
      onError: () => {
        // If we are unable to access the merchant, we should unset the current organization
        // setOrganizationId('personal');

        enqueueSnackbar(
          'There was a problem finding the merchant you are looking for.',
          {
            variant: 'error',
          }
        );
      },
      enabled,
    }
  );
};

export const useUpdateMerchant = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({ body }: UpdateMerchantRequestId) => merchantsApi.updateMerchant(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.single);
        enqueueSnackbar(`Merchant was successfully updated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating user.', {
          variant: 'error',
        });
      },
    }
  );
};

export const updateMerchantLastVisitedResourceId = async (
  lastVisitedResourceId: number
) => {
  const response = await merchantsApi.updateMerchantLastVisitedResourceId({
    lastVisitedResourceId,
  });
  return response.data;
};

export const updateMerchantSkippedPaymentAccount = async () => {
  const response = await merchantsApi.updateMerchantSkippedPaymentAccount();
  return response.data;
};
