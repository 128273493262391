import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import { useQuery } from 'react-query';
import { Configuration, UsersSettingsApiFactory } from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

export const userSettingsApi = UsersSettingsApiFactory(config, baseURL, axios);

export interface PaginatedInvoiceHistoryParams {
  limit?: number;
  startingAfter?: string;
}
enum QUERY_KEYS {
  list = 'settings',
  single = 'setting',
}

// todo do we need to refetch session url on each mount?
export const useBillingPortalSession = (enabled = true) => {
  return useQuery(
    'billing-portal-session',
    async () => {
      const response = await userSettingsApi.createBillingPortalSession();
      return response.data;
    },
    { enabled }
  );
};

export const useAccountPortalSession = (enabled = true) => {
  return useQuery(
    'account-portal-session',
    async () => {
      const response = await userSettingsApi.createAccountPortalSession();
      return response.data;
    },
    { enabled }
  );
};

export const useCardInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single],
    async () => {
      const response = await userSettingsApi.findCardInfo();
      return response.data;
    },
    {
      refetchOnMount: false,
      onError: () => {
        enqueueSnackbar('There was a problem fetching card info.', {
          variant: 'error',
        });
      },
    }
  );
};

export function useInvoiceHistory({
  limit,
  startingAfter,
}: PaginatedInvoiceHistoryParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, limit, startingAfter],
    async () => {
      const response = await userSettingsApi.findCustomerInvoiceHistory(
        limit,
        startingAfter
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching invoice history.', {
          variant: 'error',
        });
      },
    }
  );
}
