/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateSequenceRequestDto } from '../models';
// @ts-ignore
import { ScheduleSequenceRequestDto } from '../models';
// @ts-ignore
import { SendSequenceRequestDto } from '../models';
// @ts-ignore
import { SequenceContactsResponseDto } from '../models';
// @ts-ignore
import { SequenceResponseDto } from '../models';
// @ts-ignore
import { SequenceSchedulesBySequenceAndSubscriberResponseDto } from '../models';
// @ts-ignore
import { SequencesResponseDto } from '../models';
// @ts-ignore
import { UpdateSequenceRequestDto } from '../models';
/**
 * SequencesApi - axios parameter creator
 * @export
 */
export const SequencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel sequence for contact
         * @param {string} id 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSequenceByContact: async (id: string, contactId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelSequenceByContact', 'id', id)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('cancelSequenceByContact', 'contactId', contactId)
            const localVarPath = `/v1/sequences/{id}/contacts/{contactId}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single sequence
         * @param {CreateSequenceRequestDto} createSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequence: async (createSequenceRequestDto: CreateSequenceRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSequenceRequestDto' is not null or undefined
            assertParamExists('createSequence', 'createSequenceRequestDto', createSequenceRequestDto)
            const localVarPath = `/v1/sequences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSequenceRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Permanently delete a single sequence
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSequence: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSequence', 'id', id)
            const localVarPath = `/v1/sequences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicate a single sequence
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateSequence: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateSequence', 'id', id)
            const localVarPath = `/v1/sequences/{id}/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple sequences
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSequences: async (page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sequences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single sequence
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSequence: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneSequence', 'id', id)
            const localVarPath = `/v1/sequences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve paginated contacts for sequence
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPaginatedContactsForSequence: async (id: string, page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPaginatedContactsForSequence', 'id', id)
            const localVarPath = `/v1/sequences/{id}/contacts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary find sequence schedules by sequence and contact
         * @param {string} id 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSequenceSchedulesBySequenceAndContact: async (id: string, contactId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSequenceSchedulesBySequenceAndContact', 'id', id)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('findSequenceSchedulesBySequenceAndContact', 'contactId', contactId)
            const localVarPath = `/v1/sequences/{id}/contacts/{contactId}/schedules`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule a sequence
         * @param {ScheduleSequenceRequestDto} scheduleSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleSequence: async (scheduleSequenceRequestDto: ScheduleSequenceRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleSequenceRequestDto' is not null or undefined
            assertParamExists('scheduleSequence', 'scheduleSequenceRequestDto', scheduleSequenceRequestDto)
            const localVarPath = `/v1/sequences/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleSequenceRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a sequence
         * @param {SendSequenceRequestDto} sendSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send: async (sendSequenceRequestDto: SendSequenceRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendSequenceRequestDto' is not null or undefined
            assertParamExists('send', 'sendSequenceRequestDto', sendSequenceRequestDto)
            const localVarPath = `/v1/sequences/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendSequenceRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single sequence
         * @param {string} id 
         * @param {UpdateSequenceRequestDto} updateSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSequence: async (id: string, updateSequenceRequestDto: UpdateSequenceRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSequence', 'id', id)
            // verify required parameter 'updateSequenceRequestDto' is not null or undefined
            assertParamExists('updateSequence', 'updateSequenceRequestDto', updateSequenceRequestDto)
            const localVarPath = `/v1/sequences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSequenceRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequencesApi - functional programming interface
 * @export
 */
export const SequencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel sequence for contact
         * @param {string} id 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSequenceByContact(id: string, contactId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSequenceByContact(id, contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single sequence
         * @param {CreateSequenceRequestDto} createSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSequence(createSequenceRequestDto: CreateSequenceRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSequence(createSequenceRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Permanently delete a single sequence
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSequence(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSequence(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Duplicate a single sequence
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateSequence(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateSequence(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple sequences
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSequences(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequencesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSequences(page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single sequence
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneSequence(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneSequence(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve paginated contacts for sequence
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPaginatedContactsForSequence(id: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceContactsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPaginatedContactsForSequence(id, page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary find sequence schedules by sequence and contact
         * @param {string} id 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSequenceSchedulesBySequenceAndContact(id: string, contactId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceSchedulesBySequenceAndSubscriberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSequenceSchedulesBySequenceAndContact(id, contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedule a sequence
         * @param {ScheduleSequenceRequestDto} scheduleSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleSequence(scheduleSequenceRequestDto: ScheduleSequenceRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleSequence(scheduleSequenceRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a sequence
         * @param {SendSequenceRequestDto} sendSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async send(sendSequenceRequestDto: SendSequenceRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.send(sendSequenceRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single sequence
         * @param {string} id 
         * @param {UpdateSequenceRequestDto} updateSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSequence(id: string, updateSequenceRequestDto: UpdateSequenceRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSequence(id, updateSequenceRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SequencesApi - factory interface
 * @export
 */
export const SequencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel sequence for contact
         * @param {string} id 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSequenceByContact(id: string, contactId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelSequenceByContact(id, contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single sequence
         * @param {CreateSequenceRequestDto} createSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequence(createSequenceRequestDto: CreateSequenceRequestDto, options?: any): AxiosPromise<SequenceResponseDto> {
            return localVarFp.createSequence(createSequenceRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Permanently delete a single sequence
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSequence(id: string, options?: any): AxiosPromise<SequenceResponseDto> {
            return localVarFp.deleteSequence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicate a single sequence
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateSequence(id: string, options?: any): AxiosPromise<SequenceResponseDto> {
            return localVarFp.duplicateSequence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple sequences
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSequences(page?: number, limit?: number, search?: string, options?: any): AxiosPromise<SequencesResponseDto> {
            return localVarFp.findAllSequences(page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single sequence
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSequence(id: string, options?: any): AxiosPromise<SequenceResponseDto> {
            return localVarFp.findOneSequence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve paginated contacts for sequence
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPaginatedContactsForSequence(id: string, page?: number, limit?: number, search?: string, options?: any): AxiosPromise<SequenceContactsResponseDto> {
            return localVarFp.findPaginatedContactsForSequence(id, page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary find sequence schedules by sequence and contact
         * @param {string} id 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSequenceSchedulesBySequenceAndContact(id: string, contactId: string, options?: any): AxiosPromise<SequenceSchedulesBySequenceAndSubscriberResponseDto> {
            return localVarFp.findSequenceSchedulesBySequenceAndContact(id, contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule a sequence
         * @param {ScheduleSequenceRequestDto} scheduleSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleSequence(scheduleSequenceRequestDto: ScheduleSequenceRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.scheduleSequence(scheduleSequenceRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a sequence
         * @param {SendSequenceRequestDto} sendSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send(sendSequenceRequestDto: SendSequenceRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.send(sendSequenceRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single sequence
         * @param {string} id 
         * @param {UpdateSequenceRequestDto} updateSequenceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSequence(id: string, updateSequenceRequestDto: UpdateSequenceRequestDto, options?: any): AxiosPromise<SequenceResponseDto> {
            return localVarFp.updateSequence(id, updateSequenceRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequencesApi - object-oriented interface
 * @export
 * @class SequencesApi
 * @extends {BaseAPI}
 */
export class SequencesApi extends BaseAPI {
    /**
     * 
     * @summary Cancel sequence for contact
     * @param {string} id 
     * @param {string} contactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public cancelSequenceByContact(id: string, contactId: string, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).cancelSequenceByContact(id, contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single sequence
     * @param {CreateSequenceRequestDto} createSequenceRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public createSequence(createSequenceRequestDto: CreateSequenceRequestDto, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).createSequence(createSequenceRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Permanently delete a single sequence
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public deleteSequence(id: string, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).deleteSequence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicate a single sequence
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public duplicateSequence(id: string, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).duplicateSequence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple sequences
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public findAllSequences(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).findAllSequences(page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single sequence
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public findOneSequence(id: string, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).findOneSequence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve paginated contacts for sequence
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public findPaginatedContactsForSequence(id: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).findPaginatedContactsForSequence(id, page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary find sequence schedules by sequence and contact
     * @param {string} id 
     * @param {string} contactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public findSequenceSchedulesBySequenceAndContact(id: string, contactId: string, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).findSequenceSchedulesBySequenceAndContact(id, contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule a sequence
     * @param {ScheduleSequenceRequestDto} scheduleSequenceRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public scheduleSequence(scheduleSequenceRequestDto: ScheduleSequenceRequestDto, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).scheduleSequence(scheduleSequenceRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a sequence
     * @param {SendSequenceRequestDto} sendSequenceRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public send(sendSequenceRequestDto: SendSequenceRequestDto, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).send(sendSequenceRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single sequence
     * @param {string} id 
     * @param {UpdateSequenceRequestDto} updateSequenceRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public updateSequence(id: string, updateSequenceRequestDto: UpdateSequenceRequestDto, options?: AxiosRequestConfig) {
        return SequencesApiFp(this.configuration).updateSequence(id, updateSequenceRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
