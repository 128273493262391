import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL } from './axios';
import {
  Configuration,
  CoursesApiFactory,
  CreateCourseRequestDto,
  UpdateCourseRequestDto,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const coursesApi = CoursesApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'courses',
  single = 'course',
}

export const useCourse = (id: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, id],
    async () => {
      const response = await coursesApi.findOneCourse(id);
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your course.', {
          variant: 'error',
        });
      },
    }
  );
};

export const checkCourseSlug = async (slug: string): Promise<boolean> => {
  const response = await coursesApi.checkCourseSlug(slug);
  return response.data?.found;
};

export const useCreateCourse = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: CreateCourseRequestDto) => coursesApi.createCourse(body),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar(`${variables.title} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your course.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface UpdateCourseRequestWithId {
  courseId: string;
  body: UpdateCourseRequestDto;
}

export const useUpdateCourse = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ courseId, body }: UpdateCourseRequestWithId) =>
      coursesApi.updateCourse(courseId, body),
    {
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries([
          QUERY_KEYS.single,
          variables.courseId,
        ]);
        await queryClient.invalidateQueries([QUERY_KEYS.list]);

        enqueueSnackbar('The course was successfully updated!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating the course.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface PaginatedCoursesParams {
  page?: number;
  limit?: number;
  search?: string;
}

export function useCourses({ limit, page, search }: PaginatedCoursesParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, limit, page, search],
    async () => {
      const response = await coursesApi.findPaginatedCourses(
        page,
        limit,
        search
      );
      return response.data;
    },
    {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your courses.', {
          variant: 'error',
        });
      },
    }
  );
}

export const useDeleteCourse = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation((courseId: string) => coursesApi.deleteCourse(courseId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.list);
      enqueueSnackbar('Your course was successfully deleted!', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('There was a problem deleting your course.', {
        variant: 'error',
      });
    },
  });
};

export const useDuplicateCourse = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: { courseId: string }) =>
      coursesApi.duplicateCourse(request.courseId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Your course was successfully duplicated!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem duplicating your course.', {
          variant: 'error',
        });
      },
    }
  );
};
