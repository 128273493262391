import Cookies from 'js-cookie';
import React from 'react';
import { cookiesExpires, cookiesKey } from '../config';

const DarkModeContext = React.createContext({
  mode: 'light',
  toggleDarkMode: () => {
    return;
  },
});

export function DarkModeProvider({ children, defaultMode = 'light' }) {
  const [mode, setMode] = React.useState(defaultMode);
  const colorMode = React.useMemo(
    () => ({
      toggleDarkMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  React.useEffect(() => {
    Cookies.set(cookiesKey.themeMode, mode, {
      expires: cookiesExpires,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <DarkModeContext.Provider value={{ mode, ...colorMode }}>
      {children}
    </DarkModeContext.Provider>
  );
}

export function withDarkModeProvider(Component) {
  function DarkModeProviderWrapper({ defaultSettings, ...props }) {
    return (
      <DarkModeProvider defaultMode={defaultSettings.themeMode}>
        <Component {...props} />
      </DarkModeProvider>
    );
  }

  return DarkModeProviderWrapper;
}

export function useDarkMode() {
  const context = React.useContext(DarkModeContext);
  if (context === undefined) {
    throw new Error('DarkModeContext must be within DarkModeProvider');
  }

  return context;
}
