import { useSnackbar } from 'notistack';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { axios, baseURL } from './axios';
import {
  AddSubscriberPaymentMethodRequestDto,
  Configuration,
  CreateListingSubscriptionResponseDto,
  DeleteSubscriberPaymentMethodRequestDto,
  SubscriberApiFactory,
  SubscriberContentResponseDto,
  SubscriberPageResponseDto,
  SubscriberUpdateOnboardingStateRequestDto,
  UnprotectedSubscriberApiFactory,
  UpdateSubscriberRequestDto,
  UpdateSubscriberBillingInfoRequestDto,
  SubscriberCreateListingSubscriptionRequestDto,
  UnprotectedSubscriberCreateListingSubscriptionRequestDto,
  SubscriberFindContentsByIdsRequestDto,
  SetUpSubscriberProfileRequestDto,
} from '@subflow/api-client';
import { IPageUI } from '@subflow-frontend/@types/page';
import { mapFileDtoToFileUI, mapPageMercantResponseToPageUI } from './pages';
import { IFile } from '@subflow-frontend/@types/file';
import { fileFormatByContentType } from '@subflow-frontend/components/file-thumbnail';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import { isSubscriberUser } from '@subflow-frontend/guards/SubscriberGuard';
import { useSubdomain } from '@subflow-frontend/sections/subscriber';

const config = new Configuration({
  basePath: baseURL,
});

export const subscriberApi = SubscriberApiFactory(config, baseURL, axios);

export const unprotectedSubscriberApi = UnprotectedSubscriberApiFactory(
  config,
  baseURL,
  axios
);

enum QUERY_KEYS {
  single = 'subscriber',
  profile = 'profile',
  orgs = 'orgs',
  pages = 'pages',
  contents = 'contents',
  contentByIds = 'contentByIds',
  subscriptions = 'subscriptions',
  subscription = 'subscription',
  product = 'product',
  cardInfo = 'card-info',
  billingInfo = 'billing-info',
  invoiceHistory = 'invoice-history',
  chatToken = 'chat-token',
  feedToken = 'feed-token',
  videoAudioToken = 'video-audio-token',
  meetingVideoAudioToken = 'meeting-video-audio-token',
}

export const useSubscriberProfile = (disabled?: boolean) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, QUERY_KEYS.profile],
    async () => {
      const response = await subscriberApi.getSubscriberProfile();
      return response.data;
    },
    {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your profile.', {
          variant: 'error',
        });
      },
      enabled: !disabled,
    }
  );
};

export const useSubscriberSubscriptions = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.subscriptions],
    async () => {
      const response = await subscriberApi.findAllSubscriberSubscriptions();
      return response.data.results;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your subscriptions.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSubscriberSubscription = (subscriptionId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.subscription, subscriptionId],
    async () => {
      const response = await subscriberApi.findSubscriberSubscription(
        subscriptionId
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your subscription.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useUpdateSubscriberProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (body: UpdateSubscriberRequestDto) =>
      subscriberApi.updateSubscriberProfile(body),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries([
          QUERY_KEYS.single,
          QUERY_KEYS.profile,
        ]);
        enqueueSnackbar(`Subscriber profile was successfully updated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating subscriber profile.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSubscriberOrgs = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, QUERY_KEYS.orgs],
    async () => {
      const response = await subscriberApi.getSubscriberOrgs();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your organizations.', {
          variant: 'error',
        });
      },
    }
  );
};

export const mapSubscriberPageResponseToPageUI = (
  page: SubscriberPageResponseDto
): IPageUI => {
  return {
    id: page.id,
    name: page.name,
    slug: page.slug,
    published: page.published,
    createdAt: page.createdAt,
    updatedAt: page.updatedAt,
    viewConfiguration: page.viewConfiguration,
    // @ts-expect-error open graph image type is incorrect
    openGraphImage: page.openGraphImage
      ? mapFileDtoToFileUI(page.merchant, page.openGraphImage)
      : undefined,
    merchant: mapPageMercantResponseToPageUI(page.merchant),
  };
};

interface SubscriberFindPublicPages {
  subdomain: string;
  page?: number;
  limit?: number;
  search?: string;
}

export const useSubscriberPages = (request: SubscriberFindPublicPages) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, QUERY_KEYS.pages, request],
    async () => {
      const response = await subscriberApi.findAllSubscriberPages(
        request.subdomain,
        request.page,
        request.limit,
        request.search
      );

      const pages: IPageUI[] = response.data.results.map(
        mapSubscriberPageResponseToPageUI
      );
      return {
        ...response.data,
        results: pages,
      };
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching pages.', {
          variant: 'error',
        });
      },
    }
  );
};

interface SubscriberFindContentsRequestParams {
  subdomain: string;
  parentId?: number;
  page?: number;
  limit?: number;
  search?: string;
  contentTypes?: string[];
  nodeType?: 'file' | 'directory';
  includeDirectories?: boolean;
  depth?: number;
}

const mapContentToFile = (content: SubscriberContentResponseDto): IFile => {
  const contentType =
    content.type === 'directory' ? 'directory' : content.file?.contentType;
  return {
    id: content.id,
    name: content.name,
    description: content.description,
    fileId: content.type === 'directory' ? undefined : content.file?.id,
    size: content.file?.size ?? 0,
    totalFiles: 0,
    shared: [],
    isFavorited: false,
    url: content.file?.url ?? '',
    thumbnailUrl: content.file?.thumbnail?.url ?? '',
    dateCreated: content.createdAt,
    dateModified: content.updatedAt,
    fileType: content.type === 'directory' ? 'folder' : 'file',
    fileFormat: fileFormatByContentType(contentType),
    type: content.type === 'directory' ? 'folder' : content.type,
    tags: [],
    merchantId: content.merchant.id,
    merchantSubdomain: content.merchant.subDomain,
    contentType,
    published: content.published,
  };
};

export const useSubscriberContentsByIds = <
  TFILE extends IFile<number> = IFile<number>
>(
  subdomain: string,
  request: SubscriberFindContentsByIdsRequestDto,
  disabled?: boolean
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, session } = useSessionAuth();
  const isSubscriberLoggedIn = isSubscriberUser(session) && isAuthenticated;
  return useQuery(
    [
      QUERY_KEYS.single,
      isSubscriberLoggedIn,
      QUERY_KEYS.contentByIds,
      subdomain,
      request,
    ],
    async () => {
      const response = isSubscriberLoggedIn
        ? await subscriberApi.findSubscriberContentsByIds(subdomain, request)
        : await unprotectedSubscriberApi.findUnprotectedContentsByIds(
            subdomain,
            request
          );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const files: TFILE[] = response.data.results.map((entry) =>
        mapContentToFile(entry)
      );
      return {
        results: files,
      };
    },
    {
      enabled: !disabled,
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching content.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSubscriberContents = <TFILE extends IFile = IFile>(
  request: SubscriberFindContentsRequestParams,
  disabled?: boolean
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, session } = useSessionAuth();
  const isSubscriberLoggedIn = isSubscriberUser(session) && isAuthenticated;
  return useQuery(
    [QUERY_KEYS.single, isSubscriberLoggedIn, QUERY_KEYS.contents, request],
    async () => {
      const response = isSubscriberLoggedIn
        ? await subscriberApi.findSubscriberContents(
            request.subdomain,
            request.parentId,
            request.page,
            request.limit,
            request.search,
            request.contentTypes,
            request.nodeType,
            request.depth
          )
        : await unprotectedSubscriberApi.findUnprotectedContents(
            request.subdomain,
            request.parentId,
            request.page,
            request.limit,
            request.search,
            request.contentTypes,
            request.nodeType,
            request.depth
          );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const files: TFILE[] = response.data.results.map((entry) =>
        mapContentToFile(entry)
      );
      return {
        ...response.data,
        results: files,
      };
    },
    {
      enabled: !disabled,
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching pages.', {
          variant: 'error',
        });
      },
    }
  );
};

interface PublishedContentVariables {
  subdomain: string;
  contentId: number;
}

export function useSubscriberFindOnePublishedContent(
  variables: PublishedContentVariables,
  { enabled }: { enabled: boolean }
) {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, session } = useSessionAuth();
  const isSubscriberLoggedIn = isSubscriberUser(session) && isAuthenticated;

  return useQuery(
    [isSubscriberLoggedIn, QUERY_KEYS.single, 'published-content', variables],
    async () => {
      if (isSubscriberLoggedIn) {
        const response = await subscriberApi.findOneSubscriberContent(
          variables.subdomain,
          variables.contentId
        );
        return {
          ...response.data,
        };
      } else {
        const response =
          await unprotectedSubscriberApi.findOneUnprotectedContent(
            variables.subdomain,
            variables.contentId
          );
        return {
          ...response.data,
        };
      }
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching your contents.', {
          variant: 'error',
        });
      },
      enabled,
    }
  );
}

export const useSubscriberUpdateProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { subdomain } = useSubdomain();
  return useMutation(
    (body: SetUpSubscriberProfileRequestDto) =>
      subscriberApi.setUpSubscriberProfile(body),
    {
      onSuccess: async (data, variables) => {
        enqueueSnackbar(`Profile successfully updated!`, {
          variant: 'success',
        });
        return data;
      },
      onError: (error: Error) => {
        enqueueSnackbar(
          `There was a problem updating your profile.
          ${error.message}`,
          {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
          }
        );
      },
    }
  );
};

export const useSubscriberUpdateOnboardingStep = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (body: SubscriberUpdateOnboardingStateRequestDto) =>
      subscriberApi.updateOnboardingState(body),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries([
          QUERY_KEYS.single,
          QUERY_KEYS.profile,
        ]);
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating onboarding.', {
          variant: 'error',
        });
      },
    }
  );
};

interface SubscriberSubscriptionVariables {
  subdomain: string;
  slug: string;
}

export const useSubscriberProduct = (
  variables: SubscriberSubscriptionVariables
) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, QUERY_KEYS.product],
    async () => {
      const response = await unprotectedSubscriberApi.findOneUnprotectedProduct(
        variables.subdomain,
        variables.slug
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your subscription.', {
          variant: 'error',
        });
      },
    }
  );
};

// workaround for now until websocket connection is established
const refreshOrgs = (queryClient: QueryClient) => {
  setTimeout(() => {
    queryClient.invalidateQueries([QUERY_KEYS.single, QUERY_KEYS.orgs]);
    setTimeout(() => {
      queryClient.invalidateQueries([QUERY_KEYS.single, QUERY_KEYS.orgs]);
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.single, QUERY_KEYS.orgs]);
      }, 10000);
    }, 5000);
  }, 2000);
};

interface CreateSubscriptionRequest {
  productId: string;
  body:
    | SubscriberCreateListingSubscriptionRequestDto
    | UnprotectedSubscriberCreateListingSubscriptionRequestDto;
}

export const useCreateSubscriptionToProduct = () => {
  const queryClient = useQueryClient();
  const { isAuthenticated, session } = useSessionAuth();
  const isSubscriberLoggedIn = isSubscriberUser(session) && isAuthenticated;

  return useMutation(
    async (request: CreateSubscriptionRequest) => {
      if (isSubscriberLoggedIn) {
        const { couponId, couponName, country, zipcode } = request.body;
        const response = await subscriberApi.createSubscriberSubscription(
          request.productId,
          {
            country,
            couponId,
            couponName,
            zipcode,
          }
        );
        return response.data;
      } else {
        const response = await unprotectedSubscriberApi.createSubscription(
          request.productId,
          request.body as UnprotectedSubscriberCreateListingSubscriptionRequestDto
        );
        return response.data;
      }
    },
    {
      onSuccess: async (data, variables) => {
        if (isSubscriberLoggedIn) {
          refreshOrgs(queryClient);
        }
      },
      onError: () => {},
    }
  );
};

export const useDeleteSubscriberPaymentMethod = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (body: DeleteSubscriberPaymentMethodRequestDto) =>
      subscriberApi.deletePaymentMethod(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.cardInfo);
        enqueueSnackbar(
          `Subscriber's payment method was successfully deleted!`,
          {
            variant: 'success',
          }
        );
      },
      onError: () => {
        enqueueSnackbar(
          "There was a problem deleting subscriber's payment method.",
          {
            variant: 'error',
          }
        );
      },
    }
  );
};

export const useAddSubscriberPaymentMethod = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (body: AddSubscriberPaymentMethodRequestDto) =>
      subscriberApi.addPaymentMethod(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.cardInfo);
        enqueueSnackbar(`Subscriber's payment method was successfully added!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(
          "There was a problem adding subscriber's payment method.",
          {
            variant: 'error',
          }
        );
      },
    }
  );
};

export const useSubscriberCardInfo = (customerId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.cardInfo, customerId],
    async () => {
      const response = await subscriberApi.getCardInfo(customerId);
      return response.data;
    },
    {
      refetchOnMount: false,
      onError: () => {
        enqueueSnackbar('There was a problem fetching card info.', {
          variant: 'error',
        });
      },
      enabled: !!customerId,
    }
  );
};

export const useSubscriberBillingInfo = (customerId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.billingInfo, customerId],
    async () => {
      const response = await subscriberApi.getBillingInfo(customerId);
      return response.data;
    },
    {
      refetchOnMount: false,
      onError: () => {
        enqueueSnackbar('There was a problem fetching billing info.', {
          variant: 'error',
        });
      },
      enabled: !!customerId,
    }
  );
};

export const useUpdateSubscriberBillingInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      customerId,
      body,
    }: {
      customerId: string;
      body: UpdateSubscriberBillingInfoRequestDto;
    }) => subscriberApi.updateBillingInfo(customerId, body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.single);
        enqueueSnackbar(`Subscriber billing info was successfully updated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(
          'There was a problem updating subscriber billing info.',
          {
            variant: 'error',
          }
        );
      },
    }
  );
};

interface PaginatedInvoiceHistoryParams {
  customerId: string;
  limit?: number;
  startingAfter?: string;
}

export function useSubscriberInvoiceHistory({
  customerId,
  limit,
  startingAfter,
}: PaginatedInvoiceHistoryParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.invoiceHistory, limit, startingAfter],
    async () => {
      const response = await subscriberApi.getInvoiceHistory(
        customerId,
        limit,
        startingAfter
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching invoice history.', {
          variant: 'error',
        });
      },
      enabled: !!customerId,
    }
  );
}

export const useSubscriberChatToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.chatToken],
    async () => {
      const response = await subscriberApi.getSubscriberChatToken();
      return response.data;
    },
    {
      onError: (e: Error) => {
        enqueueSnackbar('There was a problem fetching your chat token.', {
          variant: 'error',
        });
      },
      staleTime: Infinity,
    }
  );
};

export const useCancelSubscriberSubscription = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ subscriptionId }: { subscriptionId: string }) =>
      subscriberApi.cancelSubscriberSubscription(subscriptionId),
    {
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries([
          QUERY_KEYS.subscription,
          variables.subscriptionId,
        ]);
        await queryClient.invalidateQueries([QUERY_KEYS.subscriptions]);
        enqueueSnackbar('The subscription was successfully canceled!', {
          variant: 'success',
        });
        refreshOrgs(queryClient);
      },
      onError: () => {
        enqueueSnackbar('There was a problem canceling the subscription.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSubscriberFeedToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, QUERY_KEYS.feedToken],
    async () => {
      const response = await subscriberApi.getSubscriberFeedToken();
      return response.data;
    },
    {
      onError: (e: Error) => {
        enqueueSnackbar('There was a problem fetching your stream token.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSubscriberVideoAudioToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, QUERY_KEYS.videoAudioToken],
    async () => {
      const response = await subscriberApi.getSubscriberVideoAudioToken();
      return response.data;
    },
    {
      onError: (e: Error) => {
        enqueueSnackbar('There was a problem fetching your stream token.', {
          variant: 'error',
        });
      },
    }
  );
};

// export const useUnprotectedSubscriberVideoTokenForMeeting = () => {
//   const { enqueueSnackbar } = useSnackbar();
//   return useQuery(
//     [QUERY_KEYS.single, QUERY_KEYS.meetingVideoAudioToken],
//     async () => {
//       const response =
//         await unprotectedSubscriberApi.getSubscriberVideoAudioTokenForMeeting();
//       return response.data;
//     },
//     {
//       onError: (e: Error) => {
//         enqueueSnackbar('There was a problem fetching your stream token.', {
//           variant: 'error',
//         });
//       },
//     }
//   );
// };

export const useUnsubscribeFromNewsletter = () => {
  return useMutation(
    ({
      merchantId,
      contactId,
      mailId,
    }: {
      merchantId: string;
      contactId: string;
      mailId: string;
    }) => {
      return unprotectedSubscriberApi.unsubscribeFromNewsletter(
        merchantId,
        contactId,
        mailId
      );
    }
  );
};
