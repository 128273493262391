import React, { PropsWithChildren } from 'react';
import { ErrorComponent } from './ErrorComponent';
import { datadogLogs } from '@datadog/browser-logs';

type ErrorBoundaryProps = PropsWithChildren<{ component?: string }>;

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    datadogLogs.logger.error('Error occurred', {}, error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent errorMessage={'Something went wrong'} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
