/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddSubscriberPaymentMethodRequestDto } from '../models';
// @ts-ignore
import { ChatTokenResponseDto } from '../models';
// @ts-ignore
import { CreateListingSubscriptionResponseDto } from '../models';
// @ts-ignore
import { DeleteSubscriberPaymentMethodRequestDto } from '../models';
// @ts-ignore
import { FeedTokenResponseDto } from '../models';
// @ts-ignore
import { SetUpSubscriberProfileRequestDto } from '../models';
// @ts-ignore
import { SubscriberBillingInfoResponseDto } from '../models';
// @ts-ignore
import { SubscriberCardInfoResponseDto } from '../models';
// @ts-ignore
import { SubscriberContentResponseDto } from '../models';
// @ts-ignore
import { SubscriberCreateListingSubscriptionRequestDto } from '../models';
// @ts-ignore
import { SubscriberFindAllPagesResponseDto } from '../models';
// @ts-ignore
import { SubscriberFindContentsByIdsRequestDto } from '../models';
// @ts-ignore
import { SubscriberFindContentsByIdsResponseDto } from '../models';
// @ts-ignore
import { SubscriberFindContentsResponseDto } from '../models';
// @ts-ignore
import { SubscriberOrgResponseDto } from '../models';
// @ts-ignore
import { SubscriberPageResponseDto } from '../models';
// @ts-ignore
import { SubscriberPaginatedInvoiceHistoryResponseDto } from '../models';
// @ts-ignore
import { SubscriberResponseDto } from '../models';
// @ts-ignore
import { SubscriberSignedS3UrlsRequestDto } from '../models';
// @ts-ignore
import { SubscriberSignedS3UrlsResponseDto } from '../models';
// @ts-ignore
import { SubscriberSubscriptionResponseDto } from '../models';
// @ts-ignore
import { SubscriberSubscriptionsResponseDto } from '../models';
// @ts-ignore
import { SubscriberUpdateOnboardingStateRequestDto } from '../models';
// @ts-ignore
import { UpdateSubscriberBillingInfoRequestDto } from '../models';
// @ts-ignore
import { UpdateSubscriberRequestDto } from '../models';
// @ts-ignore
import { VideoAudioTokenResponseDto } from '../models';
/**
 * SubscriberApi - axios parameter creator
 * @export
 */
export const SubscriberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new payment method.
         * @param {AddSubscriberPaymentMethodRequestDto} addSubscriberPaymentMethodRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPaymentMethod: async (addSubscriberPaymentMethodRequestDto: AddSubscriberPaymentMethodRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addSubscriberPaymentMethodRequestDto' is not null or undefined
            assertParamExists('addPaymentMethod', 'addSubscriberPaymentMethodRequestDto', addSubscriberPaymentMethodRequestDto)
            const localVarPath = `/v1/subscriber/payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSubscriberPaymentMethodRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a subscription
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriberSubscription: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelSubscriberSubscription', 'id', id)
            const localVarPath = `/v1/subscriber/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a subscription to a product
         * @param {string} productId 
         * @param {SubscriberCreateListingSubscriptionRequestDto} subscriberCreateListingSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriberSubscription: async (productId: string, subscriberCreateListingSubscriptionRequestDto: SubscriberCreateListingSubscriptionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createSubscriberSubscription', 'productId', productId)
            // verify required parameter 'subscriberCreateListingSubscriptionRequestDto' is not null or undefined
            assertParamExists('createSubscriberSubscription', 'subscriberCreateListingSubscriptionRequestDto', subscriberCreateListingSubscriptionRequestDto)
            const localVarPath = `/v1/subscriber/listings/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriberCreateListingSubscriptionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete payment method.
         * @param {DeleteSubscriberPaymentMethodRequestDto} deleteSubscriberPaymentMethodRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethod: async (deleteSubscriberPaymentMethodRequestDto: DeleteSubscriberPaymentMethodRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteSubscriberPaymentMethodRequestDto' is not null or undefined
            assertParamExists('deletePaymentMethod', 'deleteSubscriberPaymentMethodRequestDto', deleteSubscriberPaymentMethodRequestDto)
            const localVarPath = `/v1/subscriber/payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSubscriberPaymentMethodRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscriber: Find all pages
         * @param {string} subdomain 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSubscriberPages: async (subdomain: string, page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findAllSubscriberPages', 'subdomain', subdomain)
            const localVarPath = `/v1/subscriber/{subdomain}/pages`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a all subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSubscriberSubscriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/subscriber/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single published content
         * @param {string} subdomain 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSubscriberContent: async (subdomain: string, contentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findOneSubscriberContent', 'subdomain', subdomain)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('findOneSubscriberContent', 'contentId', contentId)
            const localVarPath = `/v1/subscriber/{subdomain}/content/{contentId}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single published page by slug
         * @param {string} subdomain 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSubscriberPageBySlug: async (subdomain: string, slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findOneSubscriberPageBySlug', 'subdomain', subdomain)
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('findOneSubscriberPageBySlug', 'slug', slug)
            const localVarPath = `/v1/subscriber/{subdomain}/p/{slug}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)))
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve published descendants
         * @param {string} subdomain 
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {number} [depth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriberContents: async (subdomain: string, parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', depth?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findSubscriberContents', 'subdomain', subdomain)
            const localVarPath = `/v1/subscriber/{subdomain}/contents`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (contentTypes) {
                localVarQueryParameter['contentTypes'] = contentTypes;
            }

            if (nodeType !== undefined) {
                localVarQueryParameter['nodeType'] = nodeType;
            }

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get published content by ids
         * @param {string} subdomain 
         * @param {SubscriberFindContentsByIdsRequestDto} subscriberFindContentsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriberContentsByIds: async (subdomain: string, subscriberFindContentsByIdsRequestDto: SubscriberFindContentsByIdsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findSubscriberContentsByIds', 'subdomain', subdomain)
            // verify required parameter 'subscriberFindContentsByIdsRequestDto' is not null or undefined
            assertParamExists('findSubscriberContentsByIds', 'subscriberFindContentsByIdsRequestDto', subscriberFindContentsByIdsRequestDto)
            const localVarPath = `/v1/subscriber/{subdomain}/content/findByIds`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriberFindContentsByIdsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single subscription
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriberSubscription: async (subscriptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('findSubscriberSubscription', 'subscriptionId', subscriptionId)
            const localVarPath = `/v1/subscriber/subscriptions/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create signed s3 upload urls
         * @param {SubscriberSignedS3UrlsRequestDto} subscriberSignedS3UrlsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSignedS3Urls: async (subscriberSignedS3UrlsRequestDto: SubscriberSignedS3UrlsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberSignedS3UrlsRequestDto' is not null or undefined
            assertParamExists('generateSignedS3Urls', 'subscriberSignedS3UrlsRequestDto', subscriberSignedS3UrlsRequestDto)
            const localVarPath = `/v1/subscriber/aws/signed-s3-urls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriberSignedS3UrlsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve billing info
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingInfo: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getBillingInfo', 'customerId', customerId)
            const localVarPath = `/v1/subscriber/billing-info/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve card info
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardInfo: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCardInfo', 'customerId', customerId)
            const localVarPath = `/v1/subscriber/card-info/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve list of invoices
         * @param {string} customerId 
         * @param {number} [limit] 
         * @param {string} [startingAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceHistory: async (customerId: string, limit?: number, startingAfter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getInvoiceHistory', 'customerId', customerId)
            const localVarPath = `/v1/subscriber/invoice/history/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startingAfter !== undefined) {
                localVarQueryParameter['startingAfter'] = startingAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve chat token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberChatToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/subscriber/chat-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve feed token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberFeedToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/subscriber/feed-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all orgs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberOrgs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/subscriber/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a subscriber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/subscriber/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve video audio token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberVideoAudioToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/subscriber/video-audio-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set up subscriber profile
         * @param {SetUpSubscriberProfileRequestDto} setUpSubscriberProfileRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUpSubscriberProfile: async (setUpSubscriberProfileRequestDto: SetUpSubscriberProfileRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setUpSubscriberProfileRequestDto' is not null or undefined
            assertParamExists('setUpSubscriberProfile', 'setUpSubscriberProfileRequestDto', setUpSubscriberProfileRequestDto)
            const localVarPath = `/v1/subscriber/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setUpSubscriberProfileRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve billing info
         * @param {string} customerId 
         * @param {UpdateSubscriberBillingInfoRequestDto} updateSubscriberBillingInfoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingInfo: async (customerId: string, updateSubscriberBillingInfoRequestDto: UpdateSubscriberBillingInfoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateBillingInfo', 'customerId', customerId)
            // verify required parameter 'updateSubscriberBillingInfoRequestDto' is not null or undefined
            assertParamExists('updateBillingInfo', 'updateSubscriberBillingInfoRequestDto', updateSubscriberBillingInfoRequestDto)
            const localVarPath = `/v1/subscriber/billing-info/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriberBillingInfoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update onboarding state
         * @param {SubscriberUpdateOnboardingStateRequestDto} subscriberUpdateOnboardingStateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnboardingState: async (subscriberUpdateOnboardingStateRequestDto: SubscriberUpdateOnboardingStateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriberUpdateOnboardingStateRequestDto' is not null or undefined
            assertParamExists('updateOnboardingState', 'subscriberUpdateOnboardingStateRequestDto', subscriberUpdateOnboardingStateRequestDto)
            const localVarPath = `/v1/subscriber/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriberUpdateOnboardingStateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a subscriber\'s profile.
         * @param {UpdateSubscriberRequestDto} updateSubscriberRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriberProfile: async (updateSubscriberRequestDto: UpdateSubscriberRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSubscriberRequestDto' is not null or undefined
            assertParamExists('updateSubscriberProfile', 'updateSubscriberRequestDto', updateSubscriberRequestDto)
            const localVarPath = `/v1/subscriber/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriberRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriberApi - functional programming interface
 * @export
 */
export const SubscriberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new payment method.
         * @param {AddSubscriberPaymentMethodRequestDto} addSubscriberPaymentMethodRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPaymentMethod(addSubscriberPaymentMethodRequestDto: AddSubscriberPaymentMethodRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPaymentMethod(addSubscriberPaymentMethodRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a subscription
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscriberSubscription(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscriberSubscription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a subscription to a product
         * @param {string} productId 
         * @param {SubscriberCreateListingSubscriptionRequestDto} subscriberCreateListingSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriberSubscription(productId: string, subscriberCreateListingSubscriptionRequestDto: SubscriberCreateListingSubscriptionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateListingSubscriptionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscriberSubscription(productId, subscriberCreateListingSubscriptionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete payment method.
         * @param {DeleteSubscriberPaymentMethodRequestDto} deleteSubscriberPaymentMethodRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePaymentMethod(deleteSubscriberPaymentMethodRequestDto: DeleteSubscriberPaymentMethodRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePaymentMethod(deleteSubscriberPaymentMethodRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscriber: Find all pages
         * @param {string} subdomain 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSubscriberPages(subdomain: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberFindAllPagesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSubscriberPages(subdomain, page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a all subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSubscriberSubscriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberSubscriptionsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSubscriberSubscriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single published content
         * @param {string} subdomain 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneSubscriberContent(subdomain: string, contentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberContentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneSubscriberContent(subdomain, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single published page by slug
         * @param {string} subdomain 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneSubscriberPageBySlug(subdomain: string, slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberPageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneSubscriberPageBySlug(subdomain, slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve published descendants
         * @param {string} subdomain 
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {number} [depth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSubscriberContents(subdomain: string, parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', depth?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberFindContentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSubscriberContents(subdomain, parentId, page, limit, search, contentTypes, nodeType, depth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get published content by ids
         * @param {string} subdomain 
         * @param {SubscriberFindContentsByIdsRequestDto} subscriberFindContentsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSubscriberContentsByIds(subdomain: string, subscriberFindContentsByIdsRequestDto: SubscriberFindContentsByIdsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberFindContentsByIdsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSubscriberContentsByIds(subdomain, subscriberFindContentsByIdsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single subscription
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSubscriberSubscription(subscriptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberSubscriptionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSubscriberSubscription(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create signed s3 upload urls
         * @param {SubscriberSignedS3UrlsRequestDto} subscriberSignedS3UrlsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateSignedS3Urls(subscriberSignedS3UrlsRequestDto: SubscriberSignedS3UrlsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriberSignedS3UrlsResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateSignedS3Urls(subscriberSignedS3UrlsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve billing info
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBillingInfo(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberBillingInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBillingInfo(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve card info
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardInfo(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriberCardInfoResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardInfo(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve list of invoices
         * @param {string} customerId 
         * @param {number} [limit] 
         * @param {string} [startingAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceHistory(customerId: string, limit?: number, startingAfter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberPaginatedInvoiceHistoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceHistory(customerId, limit, startingAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve chat token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberChatToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatTokenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriberChatToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve feed token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberFeedToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedTokenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriberFeedToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all orgs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberOrgs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriberOrgResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriberOrgs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a subscriber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriberProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve video audio token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberVideoAudioToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoAudioTokenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriberVideoAudioToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set up subscriber profile
         * @param {SetUpSubscriberProfileRequestDto} setUpSubscriberProfileRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUpSubscriberProfile(setUpSubscriberProfileRequestDto: SetUpSubscriberProfileRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUpSubscriberProfile(setUpSubscriberProfileRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve billing info
         * @param {string} customerId 
         * @param {UpdateSubscriberBillingInfoRequestDto} updateSubscriberBillingInfoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBillingInfo(customerId: string, updateSubscriberBillingInfoRequestDto: UpdateSubscriberBillingInfoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberBillingInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBillingInfo(customerId, updateSubscriberBillingInfoRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update onboarding state
         * @param {SubscriberUpdateOnboardingStateRequestDto} subscriberUpdateOnboardingStateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOnboardingState(subscriberUpdateOnboardingStateRequestDto: SubscriberUpdateOnboardingStateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOnboardingState(subscriberUpdateOnboardingStateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a subscriber\'s profile.
         * @param {UpdateSubscriberRequestDto} updateSubscriberRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriberProfile(updateSubscriberRequestDto: UpdateSubscriberRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriberProfile(updateSubscriberRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriberApi - factory interface
 * @export
 */
export const SubscriberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriberApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new payment method.
         * @param {AddSubscriberPaymentMethodRequestDto} addSubscriberPaymentMethodRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPaymentMethod(addSubscriberPaymentMethodRequestDto: AddSubscriberPaymentMethodRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.addPaymentMethod(addSubscriberPaymentMethodRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a subscription
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriberSubscription(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.cancelSubscriberSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a subscription to a product
         * @param {string} productId 
         * @param {SubscriberCreateListingSubscriptionRequestDto} subscriberCreateListingSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriberSubscription(productId: string, subscriberCreateListingSubscriptionRequestDto: SubscriberCreateListingSubscriptionRequestDto, options?: any): AxiosPromise<CreateListingSubscriptionResponseDto> {
            return localVarFp.createSubscriberSubscription(productId, subscriberCreateListingSubscriptionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete payment method.
         * @param {DeleteSubscriberPaymentMethodRequestDto} deleteSubscriberPaymentMethodRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethod(deleteSubscriberPaymentMethodRequestDto: DeleteSubscriberPaymentMethodRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.deletePaymentMethod(deleteSubscriberPaymentMethodRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscriber: Find all pages
         * @param {string} subdomain 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSubscriberPages(subdomain: string, page?: number, limit?: number, search?: string, options?: any): AxiosPromise<SubscriberFindAllPagesResponseDto> {
            return localVarFp.findAllSubscriberPages(subdomain, page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a all subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSubscriberSubscriptions(options?: any): AxiosPromise<SubscriberSubscriptionsResponseDto> {
            return localVarFp.findAllSubscriberSubscriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single published content
         * @param {string} subdomain 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSubscriberContent(subdomain: string, contentId: number, options?: any): AxiosPromise<SubscriberContentResponseDto> {
            return localVarFp.findOneSubscriberContent(subdomain, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single published page by slug
         * @param {string} subdomain 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSubscriberPageBySlug(subdomain: string, slug: string, options?: any): AxiosPromise<SubscriberPageResponseDto> {
            return localVarFp.findOneSubscriberPageBySlug(subdomain, slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve published descendants
         * @param {string} subdomain 
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {number} [depth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriberContents(subdomain: string, parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', depth?: number, options?: any): AxiosPromise<SubscriberFindContentsResponseDto> {
            return localVarFp.findSubscriberContents(subdomain, parentId, page, limit, search, contentTypes, nodeType, depth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get published content by ids
         * @param {string} subdomain 
         * @param {SubscriberFindContentsByIdsRequestDto} subscriberFindContentsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriberContentsByIds(subdomain: string, subscriberFindContentsByIdsRequestDto: SubscriberFindContentsByIdsRequestDto, options?: any): AxiosPromise<SubscriberFindContentsByIdsResponseDto> {
            return localVarFp.findSubscriberContentsByIds(subdomain, subscriberFindContentsByIdsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single subscription
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriberSubscription(subscriptionId: string, options?: any): AxiosPromise<SubscriberSubscriptionResponseDto> {
            return localVarFp.findSubscriberSubscription(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create signed s3 upload urls
         * @param {SubscriberSignedS3UrlsRequestDto} subscriberSignedS3UrlsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSignedS3Urls(subscriberSignedS3UrlsRequestDto: SubscriberSignedS3UrlsRequestDto, options?: any): AxiosPromise<Array<SubscriberSignedS3UrlsResponseDto>> {
            return localVarFp.generateSignedS3Urls(subscriberSignedS3UrlsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve billing info
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingInfo(customerId: string, options?: any): AxiosPromise<SubscriberBillingInfoResponseDto> {
            return localVarFp.getBillingInfo(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve card info
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardInfo(customerId: string, options?: any): AxiosPromise<Array<SubscriberCardInfoResponseDto>> {
            return localVarFp.getCardInfo(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve list of invoices
         * @param {string} customerId 
         * @param {number} [limit] 
         * @param {string} [startingAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceHistory(customerId: string, limit?: number, startingAfter?: string, options?: any): AxiosPromise<SubscriberPaginatedInvoiceHistoryResponseDto> {
            return localVarFp.getInvoiceHistory(customerId, limit, startingAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve chat token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberChatToken(options?: any): AxiosPromise<ChatTokenResponseDto> {
            return localVarFp.getSubscriberChatToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve feed token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberFeedToken(options?: any): AxiosPromise<FeedTokenResponseDto> {
            return localVarFp.getSubscriberFeedToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all orgs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberOrgs(options?: any): AxiosPromise<Array<SubscriberOrgResponseDto>> {
            return localVarFp.getSubscriberOrgs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a subscriber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberProfile(options?: any): AxiosPromise<SubscriberResponseDto> {
            return localVarFp.getSubscriberProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve video audio token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberVideoAudioToken(options?: any): AxiosPromise<VideoAudioTokenResponseDto> {
            return localVarFp.getSubscriberVideoAudioToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set up subscriber profile
         * @param {SetUpSubscriberProfileRequestDto} setUpSubscriberProfileRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUpSubscriberProfile(setUpSubscriberProfileRequestDto: SetUpSubscriberProfileRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.setUpSubscriberProfile(setUpSubscriberProfileRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve billing info
         * @param {string} customerId 
         * @param {UpdateSubscriberBillingInfoRequestDto} updateSubscriberBillingInfoRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingInfo(customerId: string, updateSubscriberBillingInfoRequestDto: UpdateSubscriberBillingInfoRequestDto, options?: any): AxiosPromise<SubscriberBillingInfoResponseDto> {
            return localVarFp.updateBillingInfo(customerId, updateSubscriberBillingInfoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update onboarding state
         * @param {SubscriberUpdateOnboardingStateRequestDto} subscriberUpdateOnboardingStateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnboardingState(subscriberUpdateOnboardingStateRequestDto: SubscriberUpdateOnboardingStateRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateOnboardingState(subscriberUpdateOnboardingStateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a subscriber\'s profile.
         * @param {UpdateSubscriberRequestDto} updateSubscriberRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriberProfile(updateSubscriberRequestDto: UpdateSubscriberRequestDto, options?: any): AxiosPromise<SubscriberResponseDto> {
            return localVarFp.updateSubscriberProfile(updateSubscriberRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriberApi - object-oriented interface
 * @export
 * @class SubscriberApi
 * @extends {BaseAPI}
 */
export class SubscriberApi extends BaseAPI {
    /**
     * 
     * @summary Add a new payment method.
     * @param {AddSubscriberPaymentMethodRequestDto} addSubscriberPaymentMethodRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public addPaymentMethod(addSubscriberPaymentMethodRequestDto: AddSubscriberPaymentMethodRequestDto, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).addPaymentMethod(addSubscriberPaymentMethodRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a subscription
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public cancelSubscriberSubscription(id: string, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).cancelSubscriberSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a subscription to a product
     * @param {string} productId 
     * @param {SubscriberCreateListingSubscriptionRequestDto} subscriberCreateListingSubscriptionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public createSubscriberSubscription(productId: string, subscriberCreateListingSubscriptionRequestDto: SubscriberCreateListingSubscriptionRequestDto, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).createSubscriberSubscription(productId, subscriberCreateListingSubscriptionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete payment method.
     * @param {DeleteSubscriberPaymentMethodRequestDto} deleteSubscriberPaymentMethodRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public deletePaymentMethod(deleteSubscriberPaymentMethodRequestDto: DeleteSubscriberPaymentMethodRequestDto, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).deletePaymentMethod(deleteSubscriberPaymentMethodRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscriber: Find all pages
     * @param {string} subdomain 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public findAllSubscriberPages(subdomain: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).findAllSubscriberPages(subdomain, page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a all subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public findAllSubscriberSubscriptions(options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).findAllSubscriberSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single published content
     * @param {string} subdomain 
     * @param {number} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public findOneSubscriberContent(subdomain: string, contentId: number, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).findOneSubscriberContent(subdomain, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single published page by slug
     * @param {string} subdomain 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public findOneSubscriberPageBySlug(subdomain: string, slug: string, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).findOneSubscriberPageBySlug(subdomain, slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve published descendants
     * @param {string} subdomain 
     * @param {number} [parentId] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {Array<string>} [contentTypes] 
     * @param {'directory' | 'file'} [nodeType] 
     * @param {number} [depth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public findSubscriberContents(subdomain: string, parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', depth?: number, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).findSubscriberContents(subdomain, parentId, page, limit, search, contentTypes, nodeType, depth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get published content by ids
     * @param {string} subdomain 
     * @param {SubscriberFindContentsByIdsRequestDto} subscriberFindContentsByIdsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public findSubscriberContentsByIds(subdomain: string, subscriberFindContentsByIdsRequestDto: SubscriberFindContentsByIdsRequestDto, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).findSubscriberContentsByIds(subdomain, subscriberFindContentsByIdsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single subscription
     * @param {string} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public findSubscriberSubscription(subscriptionId: string, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).findSubscriberSubscription(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create signed s3 upload urls
     * @param {SubscriberSignedS3UrlsRequestDto} subscriberSignedS3UrlsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public generateSignedS3Urls(subscriberSignedS3UrlsRequestDto: SubscriberSignedS3UrlsRequestDto, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).generateSignedS3Urls(subscriberSignedS3UrlsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve billing info
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public getBillingInfo(customerId: string, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).getBillingInfo(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve card info
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public getCardInfo(customerId: string, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).getCardInfo(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve list of invoices
     * @param {string} customerId 
     * @param {number} [limit] 
     * @param {string} [startingAfter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public getInvoiceHistory(customerId: string, limit?: number, startingAfter?: string, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).getInvoiceHistory(customerId, limit, startingAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve chat token for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public getSubscriberChatToken(options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).getSubscriberChatToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve feed token for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public getSubscriberFeedToken(options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).getSubscriberFeedToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all orgs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public getSubscriberOrgs(options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).getSubscriberOrgs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a subscriber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public getSubscriberProfile(options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).getSubscriberProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve video audio token for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public getSubscriberVideoAudioToken(options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).getSubscriberVideoAudioToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set up subscriber profile
     * @param {SetUpSubscriberProfileRequestDto} setUpSubscriberProfileRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public setUpSubscriberProfile(setUpSubscriberProfileRequestDto: SetUpSubscriberProfileRequestDto, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).setUpSubscriberProfile(setUpSubscriberProfileRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve billing info
     * @param {string} customerId 
     * @param {UpdateSubscriberBillingInfoRequestDto} updateSubscriberBillingInfoRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public updateBillingInfo(customerId: string, updateSubscriberBillingInfoRequestDto: UpdateSubscriberBillingInfoRequestDto, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).updateBillingInfo(customerId, updateSubscriberBillingInfoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update onboarding state
     * @param {SubscriberUpdateOnboardingStateRequestDto} subscriberUpdateOnboardingStateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public updateOnboardingState(subscriberUpdateOnboardingStateRequestDto: SubscriberUpdateOnboardingStateRequestDto, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).updateOnboardingState(subscriberUpdateOnboardingStateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a subscriber\'s profile.
     * @param {UpdateSubscriberRequestDto} updateSubscriberRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriberApi
     */
    public updateSubscriberProfile(updateSubscriberRequestDto: UpdateSubscriberRequestDto, options?: AxiosRequestConfig) {
        return SubscriberApiFp(this.configuration).updateSubscriberProfile(updateSubscriberRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
