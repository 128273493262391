/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateProductWithPriceRequestDto } from '../models';
// @ts-ignore
import { CustomerResponseDto } from '../models';
// @ts-ignore
import { PaginatedProductsResponseDto } from '../models';
// @ts-ignore
import { Product } from '../models';
// @ts-ignore
import { ProductResponseDto } from '../models';
// @ts-ignore
import { UpdateProductRequestDto } from '../models';
/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if product with same slug exists
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkProductWithSlug: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('checkProductWithSlug', 'slug', slug)
            const localVarPath = `/v1/products/slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clean up product (e2e tests)
         * @summary Clean up product (e2e tests)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanUpProduct: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cleanUpProduct', 'id', id)
            const localVarPath = `/v1/products/clean-up/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single product
         * @param {CreateProductWithPriceRequestDto} createProductWithPriceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (createProductWithPriceRequestDto: CreateProductWithPriceRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductWithPriceRequestDto' is not null or undefined
            assertParamExists('createProduct', 'createProductWithPriceRequestDto', createProductWithPriceRequestDto)
            const localVarPath = `/v1/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductWithPriceRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft remove a single Product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProduct', 'id', id)
            const localVarPath = `/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Products
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllProducts: async (page?: number, limit?: number, search?: string, archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneProduct: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneProduct', 'id', id)
            const localVarPath = `/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get customers by product id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersByProductId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomersByProductId', 'id', id)
            const localVarPath = `/v1/products/{id}/customers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get products grouped by subscribers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsWithSubscribers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/products/subscribers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} merchantId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductTagsToContacts: async (merchantId: string, productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            assertParamExists('syncProductTagsToContacts', 'merchantId', merchantId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('syncProductTagsToContacts', 'productId', productId)
            const localVarPath = `/v1/products/sync-product-tags-to-contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/products/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Product
         * @param {string} id 
         * @param {UpdateProductRequestDto} updateProductRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (id: string, updateProductRequestDto: UpdateProductRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProduct', 'id', id)
            // verify required parameter 'updateProductRequestDto' is not null or undefined
            assertParamExists('updateProduct', 'updateProductRequestDto', updateProductRequestDto)
            const localVarPath = `/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if product with same slug exists
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkProductWithSlug(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkProductWithSlug(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Clean up product (e2e tests)
         * @summary Clean up product (e2e tests)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanUpProduct(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanUpProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single product
         * @param {CreateProductWithPriceRequestDto} createProductWithPriceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(createProductWithPriceRequestDto: CreateProductWithPriceRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(createProductWithPriceRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft remove a single Product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Products
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllProducts(page?: number, limit?: number, search?: string, archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProductsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllProducts(page, limit, search, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneProduct(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get customers by product id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersByProductId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersByProductId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get products grouped by subscribers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsWithSubscribers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsWithSubscribers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} merchantId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProductTagsToContacts(merchantId: string, productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProductTagsToContacts(merchantId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Product
         * @param {string} id 
         * @param {UpdateProductRequestDto} updateProductRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(id: string, updateProductRequestDto: UpdateProductRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(id, updateProductRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if product with same slug exists
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkProductWithSlug(slug: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkProductWithSlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Clean up product (e2e tests)
         * @summary Clean up product (e2e tests)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanUpProduct(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.cleanUpProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single product
         * @param {CreateProductWithPriceRequestDto} createProductWithPriceRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(createProductWithPriceRequestDto: CreateProductWithPriceRequestDto, options?: any): AxiosPromise<ProductResponseDto> {
            return localVarFp.createProduct(createProductWithPriceRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft remove a single Product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(id: string, options?: any): AxiosPromise<ProductResponseDto> {
            return localVarFp.deleteProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Products
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllProducts(page?: number, limit?: number, search?: string, archived?: boolean, options?: any): AxiosPromise<PaginatedProductsResponseDto> {
            return localVarFp.findAllProducts(page, limit, search, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneProduct(id: string, options?: any): AxiosPromise<ProductResponseDto> {
            return localVarFp.findOneProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get customers by product id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersByProductId(id: string, options?: any): AxiosPromise<Array<CustomerResponseDto>> {
            return localVarFp.getCustomersByProductId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get products grouped by subscribers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsWithSubscribers(options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.getProductsWithSubscribers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} merchantId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductTagsToContacts(merchantId: string, productId: string, options?: any): AxiosPromise<void> {
            return localVarFp.syncProductTagsToContacts(merchantId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProducts(options?: any): AxiosPromise<void> {
            return localVarFp.syncProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Product
         * @param {string} id 
         * @param {UpdateProductRequestDto} updateProductRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(id: string, updateProductRequestDto: UpdateProductRequestDto, options?: any): AxiosPromise<ProductResponseDto> {
            return localVarFp.updateProduct(id, updateProductRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @summary Check if product with same slug exists
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public checkProductWithSlug(slug: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).checkProductWithSlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Clean up product (e2e tests)
     * @summary Clean up product (e2e tests)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public cleanUpProduct(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).cleanUpProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single product
     * @param {CreateProductWithPriceRequestDto} createProductWithPriceRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createProduct(createProductWithPriceRequestDto: CreateProductWithPriceRequestDto, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createProduct(createProductWithPriceRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft remove a single Product
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteProduct(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Products
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {boolean} [archived] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public findAllProducts(page?: number, limit?: number, search?: string, archived?: boolean, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).findAllProducts(page, limit, search, archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Product
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public findOneProduct(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).findOneProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get customers by product id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getCustomersByProductId(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getCustomersByProductId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get products grouped by subscribers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductsWithSubscribers(options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductsWithSubscribers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} merchantId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public syncProductTagsToContacts(merchantId: string, productId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).syncProductTagsToContacts(merchantId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public syncProducts(options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).syncProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Product
     * @param {string} id 
     * @param {UpdateProductRequestDto} updateProductRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProduct(id: string, updateProductRequestDto: UpdateProductRequestDto, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProduct(id, updateProductRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
