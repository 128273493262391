/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FileResponseDto } from './file-response-dto';
import { MerchantPublicDataResponseDto } from './merchant-public-data-response-dto';

/**
 * 
 * @export
 * @interface ContentResponseDto
 */
export interface ContentResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ContentResponseDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ContentResponseDto
     */
    'name': string;
    /**
     * 
     * @type {FileResponseDto}
     * @memberof ContentResponseDto
     */
    'file': FileResponseDto;
    /**
     * 
     * @type {ContentResponseDto}
     * @memberof ContentResponseDto
     */
    'parent': ContentResponseDto;
    /**
     * 
     * @type {string}
     * @memberof ContentResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ContentResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ContentResponseDto
     */
    'type': ContentResponseDtoTypeEnum;
    /**
     * 
     * @type {MerchantPublicDataResponseDto}
     * @memberof ContentResponseDto
     */
    'merchant': MerchantPublicDataResponseDto;
    /**
     * 
     * @type {string}
     * @memberof ContentResponseDto
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContentResponseDto
     */
    'published': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContentResponseDto
     */
    'accessType': ContentResponseDtoAccessTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ContentResponseDto
     */
    'position': number;
}

export const ContentResponseDtoTypeEnum = {
    Directory: 'directory',
    File: 'file'
} as const;

export type ContentResponseDtoTypeEnum = typeof ContentResponseDtoTypeEnum[keyof typeof ContentResponseDtoTypeEnum];
export const ContentResponseDtoAccessTypeEnum = {
    Public: 'public',
    Restricted: 'restricted'
} as const;

export type ContentResponseDtoAccessTypeEnum = typeof ContentResponseDtoAccessTypeEnum[keyof typeof ContentResponseDtoAccessTypeEnum];


