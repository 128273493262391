import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import { Configuration, DashboardApiFactory } from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const dashboardApi = DashboardApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'dashboards',
  single = 'dashboard',
  pageTotal = 'pageTotal',
  sequenceTotal = 'sequenceTotal',
  segmentTotal = 'segmentTotal',
  subscriptionTotal = 'subscriptionTotal',
  currentPlan = 'currentPlan',
}

export const useCurrentPlan = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.currentPlan],
    async () => {
      const response = await dashboardApi.getCurrentPlan();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your current plan.', {
          variant: 'error',
        });
      },
    }
  );
};
export const useSubscriptionTotal = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.subscriptionTotal],
    async () => {
      const response = await dashboardApi.getTotalSubscriptions();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching subscriptions count.', {
          variant: 'error',
        });
      },
    }
  );
};

export const usePagesTotal = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.pageTotal],
    async () => {
      const response = await dashboardApi.getTotalPages();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching page count.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSequencesTotal = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.sequenceTotal],
    async () => {
      const response = await dashboardApi.getTotalSequences();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching sequences count.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSegmentTotal = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.segmentTotal],
    async () => {
      const response = await dashboardApi.getTotalSegments();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching segments count.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useFundsAvailable = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list],
    async () => {
      const response = await dashboardApi.getFundsAvailable();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching available funds.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useTotalContacts = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single],
    async () => {
      const response = await dashboardApi.getTotalContacts();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching total subscribers.', {
          variant: 'error',
        });
      },
    }
  );
};
