/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChannelResponseDto } from '../models';
// @ts-ignore
import { ChatTokenResponseDto } from '../models';
// @ts-ignore
import { CreateChannelRequestDto } from '../models';
// @ts-ignore
import { FeedTokenResponseDto } from '../models';
// @ts-ignore
import { UpdateChannelRequestDto } from '../models';
// @ts-ignore
import { VideoAudioTokenResponseDto } from '../models';
/**
 * GetstreamGatewayApi - axios parameter creator
 * @export
 */
export const GetstreamGatewayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a channel
         * @param {CreateChannelRequestDto} createChannelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChannel: async (createChannelRequestDto: CreateChannelRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChannelRequestDto' is not null or undefined
            assertParamExists('createChannel', 'createChannelRequestDto', createChannelRequestDto)
            const localVarPath = `/v1/getstream-gateway/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChannelRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a channel
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannel: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteChannel', 'id', id)
            const localVarPath = `/v1/getstream-gateway/channel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a channel
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannel: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChannel', 'id', id)
            const localVarPath = `/v1/getstream-gateway/channel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve chat token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/getstream-gateway/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve feed token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/getstream-gateway/feed-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve video-audio token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoAudioToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/getstream-gateway/video-audio-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a channel
         * @param {string} id 
         * @param {UpdateChannelRequestDto} updateChannelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannel: async (id: string, updateChannelRequestDto: UpdateChannelRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateChannel', 'id', id)
            // verify required parameter 'updateChannelRequestDto' is not null or undefined
            assertParamExists('updateChannel', 'updateChannelRequestDto', updateChannelRequestDto)
            const localVarPath = `/v1/getstream-gateway/channel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChannelRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetstreamGatewayApi - functional programming interface
 * @export
 */
export const GetstreamGatewayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetstreamGatewayApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a channel
         * @param {CreateChannelRequestDto} createChannelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChannel(createChannelRequestDto: CreateChannelRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChannel(createChannelRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a channel
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChannel(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChannel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a channel
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannel(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChannelResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve chat token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatTokenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve feed token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeedToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedTokenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve video-audio token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVideoAudioToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoAudioTokenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVideoAudioToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a channel
         * @param {string} id 
         * @param {UpdateChannelRequestDto} updateChannelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChannel(id: string, updateChannelRequestDto: UpdateChannelRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChannel(id, updateChannelRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetstreamGatewayApi - factory interface
 * @export
 */
export const GetstreamGatewayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetstreamGatewayApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a channel
         * @param {CreateChannelRequestDto} createChannelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChannel(createChannelRequestDto: CreateChannelRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.createChannel(createChannelRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a channel
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannel(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteChannel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a channel
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannel(id: string, options?: any): AxiosPromise<ChannelResponseDto> {
            return localVarFp.getChannel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve chat token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatToken(options?: any): AxiosPromise<ChatTokenResponseDto> {
            return localVarFp.getChatToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve feed token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedToken(options?: any): AxiosPromise<FeedTokenResponseDto> {
            return localVarFp.getFeedToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve video-audio token for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoAudioToken(options?: any): AxiosPromise<VideoAudioTokenResponseDto> {
            return localVarFp.getVideoAudioToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a channel
         * @param {string} id 
         * @param {UpdateChannelRequestDto} updateChannelRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannel(id: string, updateChannelRequestDto: UpdateChannelRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateChannel(id, updateChannelRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetstreamGatewayApi - object-oriented interface
 * @export
 * @class GetstreamGatewayApi
 * @extends {BaseAPI}
 */
export class GetstreamGatewayApi extends BaseAPI {
    /**
     * 
     * @summary Create a channel
     * @param {CreateChannelRequestDto} createChannelRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetstreamGatewayApi
     */
    public createChannel(createChannelRequestDto: CreateChannelRequestDto, options?: AxiosRequestConfig) {
        return GetstreamGatewayApiFp(this.configuration).createChannel(createChannelRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a channel
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetstreamGatewayApi
     */
    public deleteChannel(id: string, options?: AxiosRequestConfig) {
        return GetstreamGatewayApiFp(this.configuration).deleteChannel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a channel
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetstreamGatewayApi
     */
    public getChannel(id: string, options?: AxiosRequestConfig) {
        return GetstreamGatewayApiFp(this.configuration).getChannel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve chat token for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetstreamGatewayApi
     */
    public getChatToken(options?: AxiosRequestConfig) {
        return GetstreamGatewayApiFp(this.configuration).getChatToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve feed token for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetstreamGatewayApi
     */
    public getFeedToken(options?: AxiosRequestConfig) {
        return GetstreamGatewayApiFp(this.configuration).getFeedToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve video-audio token for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetstreamGatewayApi
     */
    public getVideoAudioToken(options?: AxiosRequestConfig) {
        return GetstreamGatewayApiFp(this.configuration).getVideoAudioToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a channel
     * @param {string} id 
     * @param {UpdateChannelRequestDto} updateChannelRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetstreamGatewayApi
     */
    public updateChannel(id: string, updateChannelRequestDto: UpdateChannelRequestDto, options?: AxiosRequestConfig) {
        return GetstreamGatewayApiFp(this.configuration).updateChannel(id, updateChannelRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
