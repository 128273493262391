/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Schedule } from '../models';
// @ts-ignore
import { ScheduleNewsletterRequestDto } from '../models';
// @ts-ignore
import { SendNewsletterRequestDto } from '../models';
/**
 * NewsletterApi - axios parameter creator
 * @export
 */
export const NewsletterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Schedule newsletter
         * @param {ScheduleNewsletterRequestDto} scheduleNewsletterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleNewsletter: async (scheduleNewsletterRequestDto: ScheduleNewsletterRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleNewsletterRequestDto' is not null or undefined
            assertParamExists('scheduleNewsletter', 'scheduleNewsletterRequestDto', scheduleNewsletterRequestDto)
            const localVarPath = `/v1/newsletter/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleNewsletterRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a newsletter
         * @param {SendNewsletterRequestDto} sendNewsletterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNewsletter: async (sendNewsletterRequestDto: SendNewsletterRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendNewsletterRequestDto' is not null or undefined
            assertParamExists('sendNewsletter', 'sendNewsletterRequestDto', sendNewsletterRequestDto)
            const localVarPath = `/v1/newsletter/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendNewsletterRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsletterApi - functional programming interface
 * @export
 */
export const NewsletterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsletterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Schedule newsletter
         * @param {ScheduleNewsletterRequestDto} scheduleNewsletterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleNewsletter(scheduleNewsletterRequestDto: ScheduleNewsletterRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleNewsletter(scheduleNewsletterRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a newsletter
         * @param {SendNewsletterRequestDto} sendNewsletterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNewsletter(sendNewsletterRequestDto: SendNewsletterRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNewsletter(sendNewsletterRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsletterApi - factory interface
 * @export
 */
export const NewsletterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsletterApiFp(configuration)
    return {
        /**
         * 
         * @summary Schedule newsletter
         * @param {ScheduleNewsletterRequestDto} scheduleNewsletterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleNewsletter(scheduleNewsletterRequestDto: ScheduleNewsletterRequestDto, options?: any): AxiosPromise<Schedule> {
            return localVarFp.scheduleNewsletter(scheduleNewsletterRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a newsletter
         * @param {SendNewsletterRequestDto} sendNewsletterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNewsletter(sendNewsletterRequestDto: SendNewsletterRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendNewsletter(sendNewsletterRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsletterApi - object-oriented interface
 * @export
 * @class NewsletterApi
 * @extends {BaseAPI}
 */
export class NewsletterApi extends BaseAPI {
    /**
     * 
     * @summary Schedule newsletter
     * @param {ScheduleNewsletterRequestDto} scheduleNewsletterRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApi
     */
    public scheduleNewsletter(scheduleNewsletterRequestDto: ScheduleNewsletterRequestDto, options?: AxiosRequestConfig) {
        return NewsletterApiFp(this.configuration).scheduleNewsletter(scheduleNewsletterRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a newsletter
     * @param {SendNewsletterRequestDto} sendNewsletterRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApi
     */
    public sendNewsletter(sendNewsletterRequestDto: SendNewsletterRequestDto, options?: AxiosRequestConfig) {
        return NewsletterApiFp(this.configuration).sendNewsletter(sendNewsletterRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
