/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CouponResponseDto } from '../models';
// @ts-ignore
import { CustomerResponseDto } from '../models';
// @ts-ignore
import { DeleteCustomersRequestDto } from '../models';
// @ts-ignore
import { SubscriptionResponseDto } from '../models';
/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCustomer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveCustomer', 'id', id)
            const localVarPath = `/v1/customers/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a subscription
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelSubscription', 'id', id)
            const localVarPath = `/v1/customers/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all customers
         * @param {DeleteCustomersRequestDto} deleteCustomersRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllCustomers: async (deleteCustomersRequestDto: DeleteCustomersRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteCustomersRequestDto' is not null or undefined
            assertParamExists('deleteAllCustomers', 'deleteCustomersRequestDto', deleteCustomersRequestDto)
            const localVarPath = `/v1/customers/multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCustomersRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Permanentely delete a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomer', 'id', id)
            const localVarPath = `/v1/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a subscritpion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/customers/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recover a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverCustomer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('recoverCustomer', 'id', id)
            const localVarPath = `/v1/customers/{id}/recover`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCode: async (code: string, merchantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('validateCode', 'code', code)
            // verify required parameter 'merchantId' is not null or undefined
            assertParamExists('validateCode', 'merchantId', merchantId)
            const localVarPath = `/v1/customers/{merchantId}/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)))
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveCustomer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveCustomer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a subscription
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscription(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all customers
         * @param {DeleteCustomersRequestDto} deleteCustomersRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllCustomers(deleteCustomersRequestDto: DeleteCustomersRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllCustomers(deleteCustomersRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Permanentely delete a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find a subscritpion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recover a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverCustomer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverCustomer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateCode(code: string, merchantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateCode(code, merchantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCustomer(id: string, options?: any): AxiosPromise<CustomerResponseDto> {
            return localVarFp.archiveCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a subscription
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(id: string, options?: any): AxiosPromise<SubscriptionResponseDto> {
            return localVarFp.cancelSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all customers
         * @param {DeleteCustomersRequestDto} deleteCustomersRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllCustomers(deleteCustomersRequestDto: DeleteCustomersRequestDto, options?: any): AxiosPromise<Array<CustomerResponseDto>> {
            return localVarFp.deleteAllCustomers(deleteCustomersRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Permanentely delete a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer(id: string, options?: any): AxiosPromise<CustomerResponseDto> {
            return localVarFp.deleteCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a subscritpion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSubscription(options?: any): AxiosPromise<SubscriptionResponseDto> {
            return localVarFp.findOneSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recover a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverCustomer(id: string, options?: any): AxiosPromise<CustomerResponseDto> {
            return localVarFp.recoverCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCode(code: string, merchantId: string, options?: any): AxiosPromise<CouponResponseDto> {
            return localVarFp.validateCode(code, merchantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @summary Archive a single Customer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public archiveCustomer(id: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).archiveCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a subscription
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public cancelSubscription(id: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).cancelSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all customers
     * @param {DeleteCustomersRequestDto} deleteCustomersRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public deleteAllCustomers(deleteCustomersRequestDto: DeleteCustomersRequestDto, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).deleteAllCustomers(deleteCustomersRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Permanentely delete a single Customer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public deleteCustomer(id: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).deleteCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a subscritpion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public findOneSubscription(options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).findOneSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recover a single Customer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public recoverCustomer(id: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).recoverCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} merchantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public validateCode(code: string, merchantId: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).validateCode(code, merchantId, options).then((request) => request(this.axios, this.basePath));
    }
}
