import { IFile } from '@subflow-frontend/@types/file';
import { IPageTemplateUI, IPageUI } from '@subflow-frontend/@types/page';
import { fileFormatByContentType } from '@subflow-frontend/components/file-thumbnail';
import {
  Configuration,
  CreatePageRequestDto,
  CreatePageTemplateRequestDto,
  FileResponseDto,
  PageResponseDto,
  PagesApiFactory,
  PageTemplateResponseDto,
  SendPageRequestDto,
  UpdatePageRequestDto,
  UpdatePageTemplateRequestDto,
} from '@subflow/api-client';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL } from './axios';

const config = new Configuration({
  basePath: baseURL,
});

export const pagesApi = PagesApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  children = 'children',
  list = 'list',
  content = 'content',
  single = 'single',
  childrenPageTemplates = 'children–page-templates',
  pageTemplate = 'page-template',
  publicList = 'public-list',
}
export interface FindChildrenPagesRequestParams {
  parentId?: string;
  page?: number;
  limit?: number;
  search?: string;
}

export function useChildrenPages({
  parentId,
  page,
  limit,
  search,
}: FindChildrenPagesRequestParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['children', { parentId, page, limit, search }],
    async () => {
      const response = await pagesApi.findChildrenPages(
        parentId,
        page,
        limit,
        search
      );
      const pages: IPageUI[] = response.data.results.map(
        mapPageResponseToPageUI
      );
      return {
        ...response.data,
        results: pages,
      };
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching your pages.', {
          variant: 'error',
        });
      },
    }
  );
}

export const mapFileDtoToFileUI = (
  merchant: {
    id: string;
    subDomain: string;
  },
  file: FileResponseDto
): IFile<string> => {
  return {
    id: file.id,
    name: file.name,
    description: file.name,
    fileId: file.id,
    size: file.size ?? 0,
    shared: [],
    isFavorited: false,
    url: file.url ?? '',
    thumbnailUrl: file.thumbnail?.url ?? '',
    dateCreated: file.createdAt,
    dateModified: file.updatedAt,
    fileType: 'file',
    fileFormat: fileFormatByContentType(file.contentType),
    type: file.contentType,
    tags: [],
    merchantId: merchant.id,
    merchantSubdomain: merchant.subDomain,
    contentType: file.contentType,
    published: false,
  };
};

export const mapPageResponseToPageUI = (page: PageResponseDto): IPageUI => {
  return {
    id: page.id,
    name: page.name,
    slug: page.slug,
    published: page.published,
    createdAt: page.createdAt,
    updatedAt: page.updatedAt,
    viewConfiguration: page.viewConfiguration,
    // @ts-expect-error open graph image type is incorrect
    openGraphImage: page.openGraphImage
      ? mapFileDtoToFileUI(page.merchant, page.openGraphImage)
      : undefined,
    merchant: mapPageMercantResponseToPageUI(page.merchant),
    accessType: page.accessType,
  };
};

export const mapPageMercantResponseToPageUI = (
  merchant: Pick<PageResponseDto['merchant'], 'businessName' | 'logo'>
) => {
  return {
    businessName: merchant.businessName,
    logo: merchant.logo,
  };
};

export const mapPageTemplateResponseToPageTemplateUI = (
  page: PageTemplateResponseDto
): IPageTemplateUI => {
  return {
    id: page.id,
    name: page.name,
    createdAt: page.createdAt,
    updatedAt: page.updatedAt,
    viewConfiguration: page.viewConfiguration,
  };
};

interface CreatePageRequest {
  page: CreatePageRequestDto;
}

export const useCreatePage = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: CreatePageRequest) => pagesApi.createPage(request.page),
    {
      onSuccess: async (response, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar(`${variables.page.name} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your page.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeletePage = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation((pageId: string) => pagesApi.deletePage(pageId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.children);
      queryClient.invalidateQueries(QUERY_KEYS.list);
      enqueueSnackbar('Your page was successfully deleted!', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('There was a problem deleting your page.', {
        variant: 'error',
      });
    },
  });
};

export const useDeletePages = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (pageIds: string[]) => pagesApi.deletePages({ ids: pageIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Your pages was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your pages.', {
          variant: 'error',
        });
      },
    }
  );
};

interface UpdatePageRequest {
  pageId: string;
  body: UpdatePageRequestDto;
}

export const useUpdatePage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (request: UpdatePageRequest) =>
      pagesApi.updatePage(request.pageId, request.body),
    {
      onSuccess: async (data, variables, context) => {
        await Promise.all([
          queryClient.invalidateQueries(QUERY_KEYS.children),
          queryClient.invalidateQueries(QUERY_KEYS.list),
          queryClient.invalidateQueries({
            queryKey: ['page', { pageId: variables.pageId }],
          }),
        ]);

        enqueueSnackbar('Your page was successfully updated!', {
          variant: 'success',
        });
      },
      onError: (error, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.children);
        queryClient.invalidateQueries(QUERY_KEYS.list);
        queryClient.invalidateQueries({
          queryKey: ['page', { pageId: variables.pageId }],
        });
        enqueueSnackbar('There was a problem updating your page.', {
          variant: 'error',
        });
      },
    }
  );
};

interface FindOnePageRequest {
  pageId: string;
}

export const useFindOnePage = ({ pageId }: FindOnePageRequest) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['page', { pageId }],
    async () => {
      const response = await pagesApi.findOnePage(pageId);
      return mapPageResponseToPageUI(response.data);
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching your page.', {
          variant: 'error',
        });
      },
    }
  );
};

export const checkPageExistsWithSlug = async (
  slug: string
): Promise<boolean> => {
  const response = await pagesApi.checkPageExistsBySlug(slug);
  return response.data?.found;
};

export interface FindChildrenPageTemplatesRequestParams {
  parentId?: string;
  page?: number;
  limit?: number;
  search?: string;
}

export function useChildrenPageTemplates(
  params: FindChildrenPageTemplatesRequestParams
) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.childrenPageTemplates, params],
    async () => {
      const { parentId, page, limit, search } = params;
      const response = await pagesApi.findChildrenPageTemplates(
        parentId,
        page,
        limit,
        search
      );
      const pages: IPageTemplateUI[] = response.data.results.map(
        mapPageTemplateResponseToPageTemplateUI
      );
      return {
        ...response.data,
        results: pages,
      };
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching your page templates.', {
          variant: 'error',
        });
      },
    }
  );
}

export const useCreatePageTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: CreatePageTemplateRequestDto) =>
      pagesApi.createPageTemplate(request),
    {
      onSuccess: async (response, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.childrenPageTemplates);
        enqueueSnackbar(
          `${variables.name} template was successfully created!`,
          {
            variant: 'success',
          }
        );
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your page template.', {
          variant: 'error',
        });
      },
    }
  );
};

interface UpdatePageTemplateRequest {
  pageTemplateId: string;
  body: UpdatePageTemplateRequestDto;
}

export const useUpdatePageTemplate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (request: UpdatePageTemplateRequest) =>
      pagesApi.updatePageTemplate(request.pageTemplateId, request.body),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.childrenPageTemplates);
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.pageTemplate,
            { pageTemplateId: variables.pageTemplateId },
          ],
        });
        enqueueSnackbar('Your page template was successfully updated!', {
          variant: 'success',
        });
      },
      onError: (error, variables, context) => {
        enqueueSnackbar('There was a problem updating your page template.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeletePageTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (pageTemplateId: string) => pagesApi.deletePageTemplate(pageTemplateId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.childrenPageTemplates);
        enqueueSnackbar('Your page template was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your page template.', {
          variant: 'error',
        });
      },
    }
  );
};

interface FindOnePageTemplateRequest {
  pageTemplateId: string;
}

export const useFindOnePageTemplate = ({
  pageTemplateId,
}: FindOnePageTemplateRequest) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.pageTemplate, { pageTemplateId }],
    async () => {
      const response = await pagesApi.findOnePageTemplate(pageTemplateId);
      return mapPageTemplateResponseToPageTemplateUI(response.data);
    },
    {
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('There was a problem fetching your page template.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSendPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (sendPageRequest: SendPageRequestDto) => pagesApi.sendPage(sendPageRequest),
    {
      onSuccess: () => {
        enqueueSnackbar('Your page was successfully sent!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem sending your page.', {
          variant: 'error',
        });
      },
    }
  );
};
