/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactDetailsRequestDto } from '../models';
// @ts-ignore
import { ContactDetailsResponseDto } from '../models';
// @ts-ignore
import { ContactResponseDto } from '../models';
// @ts-ignore
import { ContactsResponseDto } from '../models';
// @ts-ignore
import { FilteredContactsRequestDto } from '../models';
// @ts-ignore
import { FilteredContactsResponseDto } from '../models';
// @ts-ignore
import { FindSelectedRecipientsRequestDto } from '../models';
// @ts-ignore
import { FindSelectedRecipientsResponseDto } from '../models';
// @ts-ignore
import { TagResponseDto } from '../models';
/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingSubscriber: async (customerId: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createMissingSubscriber', 'customerId', customerId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('createMissingSubscriber', 'email', email)
            const localVarPath = `/v1/contacts/create-missing-subscriber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple contact tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUniqueContactTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contacts/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple contacts
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {string} [createdAtFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContacts: async (limit?: number, page?: number, search?: string, createdAtFrom?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = (createdAtFrom as any instanceof Date) ?
                    (createdAtFrom as any).toISOString() :
                    createdAtFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple contacts by product id
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactsByProductId: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('findContactsByProductId', 'productId', productId)
            const localVarPath = `/v1/contacts/by-product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve filtered Contacts
         * @param {FilteredContactsRequestDto} filteredContactsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFilteredContacts: async (filteredContactsRequestDto: FilteredContactsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filteredContactsRequestDto' is not null or undefined
            assertParamExists('findFilteredContacts', 'filteredContactsRequestDto', filteredContactsRequestDto)
            const localVarPath = `/v1/contacts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filteredContactsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single contact
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneContact: async (phone: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('findOneContact', 'phone', phone)
            const localVarPath = `/v1/contacts/{phone}`
                .replace(`{${"phone"}}`, encodeURIComponent(String(phone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find selected recipients
         * @param {FindSelectedRecipientsRequestDto} findSelectedRecipientsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSelectedRecipients: async (findSelectedRecipientsRequestDto: FindSelectedRecipientsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findSelectedRecipientsRequestDto' is not null or undefined
            assertParamExists('findSelectedRecipients', 'findSelectedRecipientsRequestDto', findSelectedRecipientsRequestDto)
            const localVarPath = `/v1/contacts/recipients/selected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findSelectedRecipientsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert a single Contact
         * @param {string} phone 
         * @param {ContactDetailsRequestDto} contactDetailsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertContact: async (phone: string, contactDetailsRequestDto: ContactDetailsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('upsertContact', 'phone', phone)
            // verify required parameter 'contactDetailsRequestDto' is not null or undefined
            assertParamExists('upsertContact', 'contactDetailsRequestDto', contactDetailsRequestDto)
            const localVarPath = `/v1/contacts/{phone}`
                .replace(`{${"phone"}}`, encodeURIComponent(String(phone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactDetailsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMissingSubscriber(customerId: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMissingSubscriber(customerId, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple contact tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllUniqueContactTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllUniqueContactTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple contacts
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {string} [createdAtFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContacts(limit?: number, page?: number, search?: string, createdAtFrom?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findContacts(limit, page, search, createdAtFrom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple contacts by product id
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContactsByProductId(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findContactsByProductId(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve filtered Contacts
         * @param {FilteredContactsRequestDto} filteredContactsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFilteredContacts(filteredContactsRequestDto: FilteredContactsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilteredContactsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFilteredContacts(filteredContactsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single contact
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneContact(phone: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneContact(phone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find selected recipients
         * @param {FindSelectedRecipientsRequestDto} findSelectedRecipientsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSelectedRecipients(findSelectedRecipientsRequestDto: FindSelectedRecipientsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindSelectedRecipientsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSelectedRecipients(findSelectedRecipientsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert a single Contact
         * @param {string} phone 
         * @param {ContactDetailsRequestDto} contactDetailsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertContact(phone: string, contactDetailsRequestDto: ContactDetailsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertContact(phone, contactDetailsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingSubscriber(customerId: string, email: string, options?: any): AxiosPromise<void> {
            return localVarFp.createMissingSubscriber(customerId, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple contact tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUniqueContactTags(options?: any): AxiosPromise<Array<TagResponseDto>> {
            return localVarFp.findAllUniqueContactTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple contacts
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {string} [createdAtFrom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContacts(limit?: number, page?: number, search?: string, createdAtFrom?: string, options?: any): AxiosPromise<ContactsResponseDto> {
            return localVarFp.findContacts(limit, page, search, createdAtFrom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple contacts by product id
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactsByProductId(productId: string, options?: any): AxiosPromise<Array<ContactResponseDto>> {
            return localVarFp.findContactsByProductId(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve filtered Contacts
         * @param {FilteredContactsRequestDto} filteredContactsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFilteredContacts(filteredContactsRequestDto: FilteredContactsRequestDto, options?: any): AxiosPromise<FilteredContactsResponseDto> {
            return localVarFp.findFilteredContacts(filteredContactsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single contact
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneContact(phone: string, options?: any): AxiosPromise<ContactDetailsResponseDto> {
            return localVarFp.findOneContact(phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find selected recipients
         * @param {FindSelectedRecipientsRequestDto} findSelectedRecipientsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSelectedRecipients(findSelectedRecipientsRequestDto: FindSelectedRecipientsRequestDto, options?: any): AxiosPromise<FindSelectedRecipientsResponseDto> {
            return localVarFp.findSelectedRecipients(findSelectedRecipientsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert a single Contact
         * @param {string} phone 
         * @param {ContactDetailsRequestDto} contactDetailsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertContact(phone: string, contactDetailsRequestDto: ContactDetailsRequestDto, options?: any): AxiosPromise<ContactDetailsResponseDto> {
            return localVarFp.upsertContact(phone, contactDetailsRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
    /**
     * 
     * @param {string} customerId 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public createMissingSubscriber(customerId: string, email: string, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).createMissingSubscriber(customerId, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple contact tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public findAllUniqueContactTags(options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).findAllUniqueContactTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple contacts
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [search] 
     * @param {string} [createdAtFrom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public findContacts(limit?: number, page?: number, search?: string, createdAtFrom?: string, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).findContacts(limit, page, search, createdAtFrom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple contacts by product id
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public findContactsByProductId(productId: string, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).findContactsByProductId(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve filtered Contacts
     * @param {FilteredContactsRequestDto} filteredContactsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public findFilteredContacts(filteredContactsRequestDto: FilteredContactsRequestDto, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).findFilteredContacts(filteredContactsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single contact
     * @param {string} phone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public findOneContact(phone: string, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).findOneContact(phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find selected recipients
     * @param {FindSelectedRecipientsRequestDto} findSelectedRecipientsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public findSelectedRecipients(findSelectedRecipientsRequestDto: FindSelectedRecipientsRequestDto, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).findSelectedRecipients(findSelectedRecipientsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert a single Contact
     * @param {string} phone 
     * @param {ContactDetailsRequestDto} contactDetailsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public upsertContact(phone: string, contactDetailsRequestDto: ContactDetailsRequestDto, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).upsertContact(phone, contactDetailsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
