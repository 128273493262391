import {
  Configuration,
  CreateSurveyRequestDto,
  SurveysApiFactory,
  UpdateSurveyRequestDto,
} from '@subflow/api-client';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL } from './axios';
import { QUERY_KEYS as BROADCAST_QUERY_KEYS } from './broadcasts';

const config = new Configuration({
  basePath: baseURL,
});

const surveysApi = SurveysApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'surveys',
  single = 'survey',
  results = 'survey_results',
}

export const useSurvey = (surveyId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, surveyId],
    async () => {
      const response = await surveysApi.findOneSurvey(surveyId);
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your survey.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSurveyResults = (surveyId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.results, surveyId],
    async () => {
      const response = await surveysApi.aggregateResults(surveyId);
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your survey results.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface PaginatedSurveyParams {
  page?: number;
  limit?: number;
  search?: string;
}

export function useSurveys(params: PaginatedSurveyParams) {
  const { page, limit } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, { page, limit, search }],
    async () => {
      const response = await surveysApi.findAllSurveys(page, limit, search);
      return {
        ...response.data,
        results: response.data?.results || [],
      };
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your surveys.', {
          variant: 'error',
        });
      },
    }
  );
}

export const useCreateSurvey = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: CreateSurveyRequestDto) => surveysApi.createSurvey(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar(`${variables.title} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your survey.', {
          variant: 'error',
        });
      },
    }
  );
};

interface DuplicateSurveyRequest {
  originalSurveyId: string;
}

export const useDuplicateSurvey = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: DuplicateSurveyRequest) =>
      surveysApi.duplicateSurvey(request.originalSurveyId),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar(`Survey was successfully duplicated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem duplicating your survey.', {
          variant: 'error',
        });
      },
    }
  );
};

interface updateSurveyRequestId {
  body: UpdateSurveyRequestDto;
  surveyId: string;
}

export const useUpdateSurvey = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ body, surveyId }: updateSurveyRequestId) =>
      surveysApi.updateSurvey(surveyId, body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        queryClient.invalidateQueries([QUERY_KEYS.single, variables.surveyId]);
        enqueueSnackbar(
          `Survey ${variables.body.title ?? ''} was successfully updated!`,
          {
            variant: 'success',
          }
        );
      },
      onError: async () => {
        enqueueSnackbar('There was a problem updating your survey.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteSurvey = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation((surveyId: string) => surveysApi.deleteSurvey(surveyId), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
      enqueueSnackbar('Your survey was successfully deleted!', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('There was a problem deleting your survey.', {
        variant: 'error',
      });
    },
  });
};

interface SendSurveyParams {
  surveyId: string;
  contactIds?: string[];
  segmentIds?: string[];
}

export const useSendSurvey = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ surveyId, contactIds = [], segmentIds = [] }: SendSurveyParams) => {
      return surveysApi.sendSurvey(surveyId, {
        contactIds,
        segmentIds,
      });
    },
    {
      onSuccess: (_, variables) => {
        enqueueSnackbar('Sent template messages!', {
          variant: 'success',
          preventDuplicate: false,
        });

        queryClient.invalidateQueries(QUERY_KEYS.list);
        queryClient.invalidateQueries([QUERY_KEYS.single, variables.surveyId]);

        //template sent to segment should show up in broadcast view
        if (variables?.segmentIds) {
          variables.segmentIds.forEach((segmentId) => {
            queryClient.invalidateQueries({
              queryKey: [BROADCAST_QUERY_KEYS.list, segmentId],
            });
          });
        }
      },
      onError: (e, variables) => {
        console.log(e);
        queryClient.invalidateQueries(QUERY_KEYS.list);
        queryClient.invalidateQueries([QUERY_KEYS.single, variables.surveyId]);
        enqueueSnackbar('Sending survey failed!', {
          persist: true,
          variant: 'error',
          preventDuplicate: false,
        });
      },
    }
  );
};

interface CancelSurveyRequest {
  surveyId: string;
}

export const useCancelSurvey = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: CancelSurveyRequest) => surveysApi.cancelSurvey(request.surveyId),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar(`Survey was successfully cancelled!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem cancelling your survey.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface ScheduleSurveyParams {
  surveyId: string;
  contactIds?: string[];
  segmentIds?: string[];
  sendTime: {
    year: number;
    month: number;
    day: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
}

export const scheduleSurvey = async ({
  surveyId,
  contactIds = [],
  segmentIds = [],
  sendTime,
}: ScheduleSurveyParams): Promise<any> => {
  const response = await surveysApi.scheduleSurvey(surveyId, {
    surveyId,
    contactIds,
    segmentIds,
    sendTime,
  });
  return response;
};
