/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateListingSubscriptionResponseDto } from '../models';
// @ts-ignore
import { SubscriberContentResponseDto } from '../models';
// @ts-ignore
import { SubscriberFindContentsByIdsRequestDto } from '../models';
// @ts-ignore
import { SubscriberFindContentsByIdsResponseDto } from '../models';
// @ts-ignore
import { SubscriberFindContentsResponseDto } from '../models';
// @ts-ignore
import { SubscriberPageResponseDto } from '../models';
// @ts-ignore
import { SubscriberProductResponseDto } from '../models';
// @ts-ignore
import { UnprotectedSubscriberCreateListingSubscriptionRequestDto } from '../models';
// @ts-ignore
import { VideoAudioTokenResponseDto } from '../models';
/**
 * UnprotectedSubscriberApi - axios parameter creator
 * @export
 */
export const UnprotectedSubscriberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a subscription to a product
         * @param {string} productId 
         * @param {UnprotectedSubscriberCreateListingSubscriptionRequestDto} unprotectedSubscriberCreateListingSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (productId: string, unprotectedSubscriberCreateListingSubscriptionRequestDto: UnprotectedSubscriberCreateListingSubscriptionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createSubscription', 'productId', productId)
            // verify required parameter 'unprotectedSubscriberCreateListingSubscriptionRequestDto' is not null or undefined
            assertParamExists('createSubscription', 'unprotectedSubscriberCreateListingSubscriptionRequestDto', unprotectedSubscriberCreateListingSubscriptionRequestDto)
            const localVarPath = `/v1/unprotected/subscriber/listings/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unprotectedSubscriberCreateListingSubscriptionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single unprotected content
         * @param {string} subdomain 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneUnprotectedContent: async (subdomain: string, contentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findOneUnprotectedContent', 'subdomain', subdomain)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('findOneUnprotectedContent', 'contentId', contentId)
            const localVarPath = `/v1/unprotected/subscriber/{subdomain}/content/{contentId}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single unprotected page by slug
         * @param {string} subdomain 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneUnprotectedPageBySlug: async (subdomain: string, slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findOneUnprotectedPageBySlug', 'subdomain', subdomain)
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('findOneUnprotectedPageBySlug', 'slug', slug)
            const localVarPath = `/v1/unprotected/subscriber/{subdomain}/p/{slug}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)))
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single product
         * @param {string} subdomain 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneUnprotectedProduct: async (subdomain: string, slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findOneUnprotectedProduct', 'subdomain', subdomain)
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('findOneUnprotectedProduct', 'slug', slug)
            const localVarPath = `/v1/unprotected/subscriber/{subdomain}/{slug}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)))
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve published descendants
         * @param {string} subdomain 
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {number} [depth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnprotectedContents: async (subdomain: string, parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', depth?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findUnprotectedContents', 'subdomain', subdomain)
            const localVarPath = `/v1/unprotected/subscriber/{subdomain}/contents`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (contentTypes) {
                localVarQueryParameter['contentTypes'] = contentTypes;
            }

            if (nodeType !== undefined) {
                localVarQueryParameter['nodeType'] = nodeType;
            }

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get published content by ids
         * @param {string} subdomain 
         * @param {SubscriberFindContentsByIdsRequestDto} subscriberFindContentsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnprotectedContentsByIds: async (subdomain: string, subscriberFindContentsByIdsRequestDto: SubscriberFindContentsByIdsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('findUnprotectedContentsByIds', 'subdomain', subdomain)
            // verify required parameter 'subscriberFindContentsByIdsRequestDto' is not null or undefined
            assertParamExists('findUnprotectedContentsByIds', 'subscriberFindContentsByIdsRequestDto', subscriberFindContentsByIdsRequestDto)
            const localVarPath = `/v1/unprotected/subscriber/{subdomain}/content/findByIds`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriberFindContentsByIdsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve video audio token for the meeting
         * @param {string} subdomain 
         * @param {string} meetingId 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberVideoAudioTokenForMeeting: async (subdomain: string, meetingId: string, phone: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('getSubscriberVideoAudioTokenForMeeting', 'subdomain', subdomain)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('getSubscriberVideoAudioTokenForMeeting', 'meetingId', meetingId)
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('getSubscriberVideoAudioTokenForMeeting', 'phone', phone)
            const localVarPath = `/v1/unprotected/subscriber/{subdomain}/meeting/{meetingId}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unsubscribe from a newsletter
         * @param {string} merchantId 
         * @param {string} contactId 
         * @param {string} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromNewsletter: async (merchantId: string, contactId: string, mailId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            assertParamExists('unsubscribeFromNewsletter', 'merchantId', merchantId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('unsubscribeFromNewsletter', 'contactId', contactId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('unsubscribeFromNewsletter', 'mailId', mailId)
            const localVarPath = `/v1/unprotected/subscriber/newsletter/{merchantId}/unsubscribe/{contactId}/{mailId}`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)))
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"mailId"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnprotectedSubscriberApi - functional programming interface
 * @export
 */
export const UnprotectedSubscriberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnprotectedSubscriberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a subscription to a product
         * @param {string} productId 
         * @param {UnprotectedSubscriberCreateListingSubscriptionRequestDto} unprotectedSubscriberCreateListingSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(productId: string, unprotectedSubscriberCreateListingSubscriptionRequestDto: UnprotectedSubscriberCreateListingSubscriptionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateListingSubscriptionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscription(productId, unprotectedSubscriberCreateListingSubscriptionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single unprotected content
         * @param {string} subdomain 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneUnprotectedContent(subdomain: string, contentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberContentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneUnprotectedContent(subdomain, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single unprotected page by slug
         * @param {string} subdomain 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneUnprotectedPageBySlug(subdomain: string, slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberPageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneUnprotectedPageBySlug(subdomain, slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single product
         * @param {string} subdomain 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneUnprotectedProduct(subdomain: string, slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberProductResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneUnprotectedProduct(subdomain, slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve published descendants
         * @param {string} subdomain 
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {number} [depth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUnprotectedContents(subdomain: string, parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', depth?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberFindContentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUnprotectedContents(subdomain, parentId, page, limit, search, contentTypes, nodeType, depth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get published content by ids
         * @param {string} subdomain 
         * @param {SubscriberFindContentsByIdsRequestDto} subscriberFindContentsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUnprotectedContentsByIds(subdomain: string, subscriberFindContentsByIdsRequestDto: SubscriberFindContentsByIdsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriberFindContentsByIdsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUnprotectedContentsByIds(subdomain, subscriberFindContentsByIdsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve video audio token for the meeting
         * @param {string} subdomain 
         * @param {string} meetingId 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriberVideoAudioTokenForMeeting(subdomain: string, meetingId: string, phone: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoAudioTokenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriberVideoAudioTokenForMeeting(subdomain, meetingId, phone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unsubscribe from a newsletter
         * @param {string} merchantId 
         * @param {string} contactId 
         * @param {string} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribeFromNewsletter(merchantId: string, contactId: string, mailId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribeFromNewsletter(merchantId, contactId, mailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnprotectedSubscriberApi - factory interface
 * @export
 */
export const UnprotectedSubscriberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnprotectedSubscriberApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a subscription to a product
         * @param {string} productId 
         * @param {UnprotectedSubscriberCreateListingSubscriptionRequestDto} unprotectedSubscriberCreateListingSubscriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(productId: string, unprotectedSubscriberCreateListingSubscriptionRequestDto: UnprotectedSubscriberCreateListingSubscriptionRequestDto, options?: any): AxiosPromise<CreateListingSubscriptionResponseDto> {
            return localVarFp.createSubscription(productId, unprotectedSubscriberCreateListingSubscriptionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single unprotected content
         * @param {string} subdomain 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneUnprotectedContent(subdomain: string, contentId: number, options?: any): AxiosPromise<SubscriberContentResponseDto> {
            return localVarFp.findOneUnprotectedContent(subdomain, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single unprotected page by slug
         * @param {string} subdomain 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneUnprotectedPageBySlug(subdomain: string, slug: string, options?: any): AxiosPromise<SubscriberPageResponseDto> {
            return localVarFp.findOneUnprotectedPageBySlug(subdomain, slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single product
         * @param {string} subdomain 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneUnprotectedProduct(subdomain: string, slug: string, options?: any): AxiosPromise<SubscriberProductResponseDto> {
            return localVarFp.findOneUnprotectedProduct(subdomain, slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve published descendants
         * @param {string} subdomain 
         * @param {number} [parentId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [contentTypes] 
         * @param {'directory' | 'file'} [nodeType] 
         * @param {number} [depth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnprotectedContents(subdomain: string, parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', depth?: number, options?: any): AxiosPromise<SubscriberFindContentsResponseDto> {
            return localVarFp.findUnprotectedContents(subdomain, parentId, page, limit, search, contentTypes, nodeType, depth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get published content by ids
         * @param {string} subdomain 
         * @param {SubscriberFindContentsByIdsRequestDto} subscriberFindContentsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnprotectedContentsByIds(subdomain: string, subscriberFindContentsByIdsRequestDto: SubscriberFindContentsByIdsRequestDto, options?: any): AxiosPromise<SubscriberFindContentsByIdsResponseDto> {
            return localVarFp.findUnprotectedContentsByIds(subdomain, subscriberFindContentsByIdsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve video audio token for the meeting
         * @param {string} subdomain 
         * @param {string} meetingId 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriberVideoAudioTokenForMeeting(subdomain: string, meetingId: string, phone: string, options?: any): AxiosPromise<VideoAudioTokenResponseDto> {
            return localVarFp.getSubscriberVideoAudioTokenForMeeting(subdomain, meetingId, phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unsubscribe from a newsletter
         * @param {string} merchantId 
         * @param {string} contactId 
         * @param {string} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromNewsletter(merchantId: string, contactId: string, mailId: string, options?: any): AxiosPromise<object> {
            return localVarFp.unsubscribeFromNewsletter(merchantId, contactId, mailId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnprotectedSubscriberApi - object-oriented interface
 * @export
 * @class UnprotectedSubscriberApi
 * @extends {BaseAPI}
 */
export class UnprotectedSubscriberApi extends BaseAPI {
    /**
     * 
     * @summary Create a subscription to a product
     * @param {string} productId 
     * @param {UnprotectedSubscriberCreateListingSubscriptionRequestDto} unprotectedSubscriberCreateListingSubscriptionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnprotectedSubscriberApi
     */
    public createSubscription(productId: string, unprotectedSubscriberCreateListingSubscriptionRequestDto: UnprotectedSubscriberCreateListingSubscriptionRequestDto, options?: AxiosRequestConfig) {
        return UnprotectedSubscriberApiFp(this.configuration).createSubscription(productId, unprotectedSubscriberCreateListingSubscriptionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single unprotected content
     * @param {string} subdomain 
     * @param {number} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnprotectedSubscriberApi
     */
    public findOneUnprotectedContent(subdomain: string, contentId: number, options?: AxiosRequestConfig) {
        return UnprotectedSubscriberApiFp(this.configuration).findOneUnprotectedContent(subdomain, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single unprotected page by slug
     * @param {string} subdomain 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnprotectedSubscriberApi
     */
    public findOneUnprotectedPageBySlug(subdomain: string, slug: string, options?: AxiosRequestConfig) {
        return UnprotectedSubscriberApiFp(this.configuration).findOneUnprotectedPageBySlug(subdomain, slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single product
     * @param {string} subdomain 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnprotectedSubscriberApi
     */
    public findOneUnprotectedProduct(subdomain: string, slug: string, options?: AxiosRequestConfig) {
        return UnprotectedSubscriberApiFp(this.configuration).findOneUnprotectedProduct(subdomain, slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve published descendants
     * @param {string} subdomain 
     * @param {number} [parentId] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {Array<string>} [contentTypes] 
     * @param {'directory' | 'file'} [nodeType] 
     * @param {number} [depth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnprotectedSubscriberApi
     */
    public findUnprotectedContents(subdomain: string, parentId?: number, page?: number, limit?: number, search?: string, contentTypes?: Array<string>, nodeType?: 'directory' | 'file', depth?: number, options?: AxiosRequestConfig) {
        return UnprotectedSubscriberApiFp(this.configuration).findUnprotectedContents(subdomain, parentId, page, limit, search, contentTypes, nodeType, depth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get published content by ids
     * @param {string} subdomain 
     * @param {SubscriberFindContentsByIdsRequestDto} subscriberFindContentsByIdsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnprotectedSubscriberApi
     */
    public findUnprotectedContentsByIds(subdomain: string, subscriberFindContentsByIdsRequestDto: SubscriberFindContentsByIdsRequestDto, options?: AxiosRequestConfig) {
        return UnprotectedSubscriberApiFp(this.configuration).findUnprotectedContentsByIds(subdomain, subscriberFindContentsByIdsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve video audio token for the meeting
     * @param {string} subdomain 
     * @param {string} meetingId 
     * @param {string} phone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnprotectedSubscriberApi
     */
    public getSubscriberVideoAudioTokenForMeeting(subdomain: string, meetingId: string, phone: string, options?: AxiosRequestConfig) {
        return UnprotectedSubscriberApiFp(this.configuration).getSubscriberVideoAudioTokenForMeeting(subdomain, meetingId, phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unsubscribe from a newsletter
     * @param {string} merchantId 
     * @param {string} contactId 
     * @param {string} mailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnprotectedSubscriberApi
     */
    public unsubscribeFromNewsletter(merchantId: string, contactId: string, mailId: string, options?: AxiosRequestConfig) {
        return UnprotectedSubscriberApiFp(this.configuration).unsubscribeFromNewsletter(merchantId, contactId, mailId, options).then((request) => request(this.axios, this.basePath));
    }
}
