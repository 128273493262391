import {
  Configuration,
  OnboardingApiFactory,
  QuickstartOnboardingUpdateRequestDto,
  StripeAccountLinkRequestDtoCountryEnum,
} from '@subflow/api-client';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL } from './axios';

const config = new Configuration({
  basePath: baseURL,
});

const onboardingApi = OnboardingApiFactory(config, baseURL, axios);

export async function getStripeCheckoutLink(body: {
  priceId: string;
  productId: string;
}) {
  const {
    data: { url },
  } = await onboardingApi.getStripeCheckoutSession(body);
  return url;
}

export async function getStripeAuthorizationLink(countryCode: string) {
  const country = countryCode as StripeAccountLinkRequestDtoCountryEnum;
  const {
    data: { url },
  } = await onboardingApi.createStripeAccountLink({
    country,
  });

  return url;
}

//TODO : to remove
export async function getStripeAccountPortalSession() {
  const {
    data: { url },
  } = await onboardingApi.getStripeAccountPortalSession();

  return url;
}

export function useOnboardingProducts() {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    'onboarding-plans',
    async () => {
      const response = await onboardingApi.findAllOnboardingProducts();
      return response.data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: () => {
        enqueueSnackbar('There was a problem fetching the plans.', {
          variant: 'error',
        });
      },
    }
  );
}

export const useUpdateQuickstartHistory = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    (request: QuickstartOnboardingUpdateRequestDto) =>
      onboardingApi.updateQuickstartOnboardingHistory(request),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries('merchant');
        enqueueSnackbar(`Quick start step successfully marked as complete.`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem marking the step as complete.', {
          variant: 'error',
        });
      },
    }
  );
};
