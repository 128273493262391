import { useRouter } from 'next/router';
import { getOrganizationSubdomain } from '@subflow/data-access';

export const useSubdomain = () => {
  const { query } = useRouter();
  const currentOrg = getOrganizationSubdomain();
  const subdomain = (query.subdomain || currentOrg || 'personal') as string;
  return {
    subdomain,
    isPersonalSubdomain: subdomain === 'personal',
  };
};
