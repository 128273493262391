/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MailchimpCallbackRequestDto } from '../models';
// @ts-ignore
import { MailchimpDeauthorizeResponseDto } from '../models';
// @ts-ignore
import { MailchimpIntegrationCallbackResponseDto } from '../models';
// @ts-ignore
import { MailchimpIntegrationStatusResponseDto } from '../models';
/**
 * IntegrationsApi - axios parameter creator
 * @export
 */
export const IntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorize Mailchimp integration for current organization.
         * @param {MailchimpCallbackRequestDto} mailchimpCallbackRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeMailchimp: async (mailchimpCallbackRequestDto: MailchimpCallbackRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailchimpCallbackRequestDto' is not null or undefined
            assertParamExists('authorizeMailchimp', 'mailchimpCallbackRequestDto', mailchimpCallbackRequestDto)
            const localVarPath = `/v1/integrations/mailchimp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailchimpCallbackRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove mailchimp integration from current organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeMailchimp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/integrations/mailchimp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Mailchimp integration status for this organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailchimpIntegrationStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/integrations/mailchimp/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xKeycloakSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleKeycloakWebhook: async (xKeycloakSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xKeycloakSignature' is not null or undefined
            assertParamExists('handleKeycloakWebhook', 'xKeycloakSignature', xKeycloakSignature)
            const localVarPath = `/v1/integrations/keycloak`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xKeycloakSignature !== undefined && xKeycloakSignature !== null) {
                localVarHeaderParameter['X-Keycloak-Signature'] = String(xKeycloakSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleMailgunWebhook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/integrations/mailgun/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xTwilioSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleTwilioWebhook: async (xTwilioSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xTwilioSignature' is not null or undefined
            assertParamExists('handleTwilioWebhook', 'xTwilioSignature', xTwilioSignature)
            const localVarPath = `/v1/integrations/twilio/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTwilioSignature !== undefined && xTwilioSignature !== null) {
                localVarHeaderParameter['X-Twilio-Signature'] = String(xTwilioSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWebhook: async (stripeSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('handleWebhook', 'stripeSignature', stripeSignature)
            const localVarPath = `/v1/integrations/stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature !== undefined && stripeSignature !== null) {
                localVarHeaderParameter['stripe-signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToAuthorize: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/integrations/mailchimp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsApi - functional programming interface
 * @export
 */
export const IntegrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authorize Mailchimp integration for current organization.
         * @param {MailchimpCallbackRequestDto} mailchimpCallbackRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeMailchimp(mailchimpCallbackRequestDto: MailchimpCallbackRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailchimpIntegrationCallbackResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeMailchimp(mailchimpCallbackRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove mailchimp integration from current organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deauthorizeMailchimp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailchimpDeauthorizeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deauthorizeMailchimp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Mailchimp integration status for this organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailchimpIntegrationStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailchimpIntegrationStatusResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailchimpIntegrationStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xKeycloakSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleKeycloakWebhook(xKeycloakSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleKeycloakWebhook(xKeycloakSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleMailgunWebhook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleMailgunWebhook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xTwilioSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleTwilioWebhook(xTwilioSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleTwilioWebhook(xTwilioSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleWebhook(stripeSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleWebhook(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectToAuthorize(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectToAuthorize(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationsApi - factory interface
 * @export
 */
export const IntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Authorize Mailchimp integration for current organization.
         * @param {MailchimpCallbackRequestDto} mailchimpCallbackRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeMailchimp(mailchimpCallbackRequestDto: MailchimpCallbackRequestDto, options?: any): AxiosPromise<MailchimpIntegrationCallbackResponseDto> {
            return localVarFp.authorizeMailchimp(mailchimpCallbackRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove mailchimp integration from current organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeMailchimp(options?: any): AxiosPromise<MailchimpDeauthorizeResponseDto> {
            return localVarFp.deauthorizeMailchimp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Mailchimp integration status for this organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailchimpIntegrationStatus(options?: any): AxiosPromise<MailchimpIntegrationStatusResponseDto> {
            return localVarFp.getMailchimpIntegrationStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xKeycloakSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleKeycloakWebhook(xKeycloakSignature: string, options?: any): AxiosPromise<string> {
            return localVarFp.handleKeycloakWebhook(xKeycloakSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleMailgunWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.handleMailgunWebhook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xTwilioSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleTwilioWebhook(xTwilioSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.handleTwilioWebhook(xTwilioSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWebhook(stripeSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.handleWebhook(stripeSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToAuthorize(options?: any): AxiosPromise<object> {
            return localVarFp.redirectToAuthorize(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationsApi - object-oriented interface
 * @export
 * @class IntegrationsApi
 * @extends {BaseAPI}
 */
export class IntegrationsApi extends BaseAPI {
    /**
     * 
     * @summary Authorize Mailchimp integration for current organization.
     * @param {MailchimpCallbackRequestDto} mailchimpCallbackRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public authorizeMailchimp(mailchimpCallbackRequestDto: MailchimpCallbackRequestDto, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).authorizeMailchimp(mailchimpCallbackRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove mailchimp integration from current organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public deauthorizeMailchimp(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).deauthorizeMailchimp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Mailchimp integration status for this organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getMailchimpIntegrationStatus(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getMailchimpIntegrationStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xKeycloakSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public handleKeycloakWebhook(xKeycloakSignature: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).handleKeycloakWebhook(xKeycloakSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public handleMailgunWebhook(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).handleMailgunWebhook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xTwilioSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public handleTwilioWebhook(xTwilioSignature: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).handleTwilioWebhook(xTwilioSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stripeSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public handleWebhook(stripeSignature: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).handleWebhook(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public redirectToAuthorize(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).redirectToAuthorize(options).then((request) => request(this.axios, this.basePath));
    }
}
