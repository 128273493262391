import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import { Configuration, NewsletterApiFactory } from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const newsletterApi = NewsletterApiFactory(config, baseURL, axios);

interface SendNewsletterParams {
  templateId: string;
  contactIds?: string[];
  segmentIds?: string[];
}

export const useSendNewsletter = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({
      templateId,
      contactIds = [],
      segmentIds = [],
    }: SendNewsletterParams) => {
      return newsletterApi.sendNewsletter({
        templateId,
        contactIds,
        segmentIds,
      });
    },
    {
      onSuccess: (_, variables) => {
        enqueueSnackbar('Newsletter sent!', {
          variant: 'success',
          preventDuplicate: false,
        });
      },
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('Sending newsletter failed!', {
          persist: true,
          variant: 'error',
          preventDuplicate: false,
        });
      },
    }
  );
};

export interface ScheduleNewsletterParams {
  emailTemplateId: string;
  contactIds?: string[];
  segmentIds?: string[];
  sendTime: {
    year: number;
    month: number;
    day: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
}

export const scheduleNewsletter = async ({
  emailTemplateId,
  contactIds = [],
  segmentIds = [],
  sendTime,
}: ScheduleNewsletterParams): Promise<any> => {
  const response = await newsletterApi.scheduleNewsletter({
    emailTemplateId,
    contactIds,
    segmentIds,
    sendTime,
  });
  return response;
};
