/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BroadcastDto } from '../models';
// @ts-ignore
import { PaginatedBroadcastsResponseDto } from '../models';
/**
 * BroadcastsApi - axios parameter creator
 * @export
 */
export const BroadcastsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Broadcast to segment
         * @param {BroadcastDto} broadcastDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcast: async (broadcastDto: BroadcastDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'broadcastDto' is not null or undefined
            assertParamExists('broadcast', 'broadcastDto', broadcastDto)
            const localVarPath = `/v1/broadcasts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(broadcastDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch broadcasts for a segment
         * @param {string} segmentId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBroadcasts: async (segmentId: string, page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('findBroadcasts', 'segmentId', segmentId)
            const localVarPath = `/v1/broadcasts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (segmentId !== undefined) {
                localVarQueryParameter['segmentId'] = segmentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BroadcastsApi - functional programming interface
 * @export
 */
export const BroadcastsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BroadcastsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Broadcast to segment
         * @param {BroadcastDto} broadcastDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcast(broadcastDto: BroadcastDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcast(broadcastDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch broadcasts for a segment
         * @param {string} segmentId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBroadcasts(segmentId: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBroadcastsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBroadcasts(segmentId, page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BroadcastsApi - factory interface
 * @export
 */
export const BroadcastsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BroadcastsApiFp(configuration)
    return {
        /**
         * 
         * @summary Broadcast to segment
         * @param {BroadcastDto} broadcastDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcast(broadcastDto: BroadcastDto, options?: any): AxiosPromise<object> {
            return localVarFp.broadcast(broadcastDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch broadcasts for a segment
         * @param {string} segmentId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBroadcasts(segmentId: string, page?: number, limit?: number, search?: string, options?: any): AxiosPromise<PaginatedBroadcastsResponseDto> {
            return localVarFp.findBroadcasts(segmentId, page, limit, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BroadcastsApi - object-oriented interface
 * @export
 * @class BroadcastsApi
 * @extends {BaseAPI}
 */
export class BroadcastsApi extends BaseAPI {
    /**
     * 
     * @summary Broadcast to segment
     * @param {BroadcastDto} broadcastDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public broadcast(broadcastDto: BroadcastDto, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).broadcast(broadcastDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch broadcasts for a segment
     * @param {string} segmentId 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastsApi
     */
    public findBroadcasts(segmentId: string, page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return BroadcastsApiFp(this.configuration).findBroadcasts(segmentId, page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }
}
