import { axios, baseURL } from './axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import {
  Configuration,
  CreateCustomSegmentRequestDto,
  SegmentsApiFactory,
  UpdateCustomSegmentRequestDto,
  UpdateSegmentRequestDto,
} from '@subflow/api-client';
import { ApiError } from 'libs/data-access/src/utils/error';

const config = new Configuration({
  basePath: baseURL,
});

const segmentsApi = SegmentsApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'segments',
  byIds = 'segmentsByIds',
  single = 'segment',
}

export interface PaginatedSegmentsParams {
  page?: number;
  limit?: number;
}

export interface SegmentFilters {
  hasContacts?: boolean;
  published?: boolean;
}

export interface SegmentParams extends PaginatedSegmentsParams {
  filters?: SegmentFilters;
}

export function useSegments({ page, limit, filters }: SegmentParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, page, limit, filters],
    async () => {
      const response = await segmentsApi.findAllSegments(
        page,
        limit,
        filters?.hasContacts,
        filters?.published
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your segments.', {
          variant: 'error',
        });
      },
    }
  );
}

interface updateSegmentRequestId {
  body: UpdateSegmentRequestDto;
  segmentId: string;
}

export const useUpdateSegment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ body, segmentId }: updateSegmentRequestId) =>
      segmentsApi.updateSegment(segmentId, body),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Segment was successfully updated!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating this segment.', {
          variant: 'error',
        });
      },
    }
  );
};

interface CreateSegmentRequest {
  segment: CreateCustomSegmentRequestDto;
}

export const useCreateCustomSegment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ segment }: CreateSegmentRequest) =>
      segmentsApi.createCustomSegment(segment),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Segment was successfully created!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating this segment.', {
          variant: 'error',
        });
      },
    }
  );
};

interface UpdateSegmentRequest {
  segmentId: string;
  segment: UpdateCustomSegmentRequestDto;
}

export const useUpdateCustomSegment = ({
  onError,
}: {
  onError?: (error: ApiError) => void;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ segmentId, segment }: UpdateSegmentRequest) =>
      segmentsApi.updateCustomSegment(segmentId, segment),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Segment was successfully updated!', {
          variant: 'success',
        });
      },
      onError: onError
        ? onError
        : (error: ApiError) => {
            enqueueSnackbar('There was a problem updating this segment.', {
              variant: 'error',
            });
          },
    }
  );
};

interface DeleteSegmentRequest {
  segmentId: string;
}
export const useDeleteCustomSegment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ segmentId }: DeleteSegmentRequest) =>
      segmentsApi.deleteCustomSegment(segmentId),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Segment was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: (error: ApiError) => {
        enqueueSnackbar('There was a problem deleting this segment.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSegment = (segmentId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, segmentId],
    async () => {
      const response = await segmentsApi.findOneSegment(segmentId);
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your segment.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSegmentsByIds = (ids?: string[]) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.byIds, ids],
    async () => {
      const response = await segmentsApi.findAllSegmentsByIds({
        segmentIds: ids,
      });
      return response.data;
    },
    {
      enabled: !!ids,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your segment.', {
          variant: 'error',
        });
      },
    }
  );
};
