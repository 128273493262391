/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactNoteResponseDto } from '../models';
// @ts-ignore
import { CreateContactNoteRequestDto } from '../models';
// @ts-ignore
import { EditContactNoteRequestDto } from '../models';
// @ts-ignore
import { PaginatedContactNotesResponseDto } from '../models';
/**
 * ContactNotesApi - axios parameter creator
 * @export
 */
export const ContactNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single contact note
         * @param {string} contactId 
         * @param {CreateContactNoteRequestDto} createContactNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactNote: async (contactId: string, createContactNoteRequestDto: CreateContactNoteRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('createContactNote', 'contactId', contactId)
            // verify required parameter 'createContactNoteRequestDto' is not null or undefined
            assertParamExists('createContactNote', 'createContactNoteRequestDto', createContactNoteRequestDto)
            const localVarPath = `/v1/contacts-notes/{contactId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactNoteRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a single contact note
         * @param {string} contactId 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactNote: async (contactId: string, noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('deleteContactNote', 'contactId', contactId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('deleteContactNote', 'noteId', noteId)
            const localVarPath = `/v1/contacts-notes/{contactId}/{noteId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single contact note
         * @param {string} contactId 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactNote: async (contactId: string, noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('findContactNote', 'contactId', contactId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('findContactNote', 'noteId', noteId)
            const localVarPath = `/v1/contacts-notes/{contactId}/{noteId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple contact notes
         * @param {string} contactId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactNotes: async (contactId: string, page?: number, limit?: number, search?: string, archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('findContactNotes', 'contactId', contactId)
            const localVarPath = `/v1/contacts-notes/{contactId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a single contact note
         * @param {string} contactId 
         * @param {string} noteId 
         * @param {EditContactNoteRequestDto} editContactNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactNote: async (contactId: string, noteId: string, editContactNoteRequestDto: EditContactNoteRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('updateContactNote', 'contactId', contactId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('updateContactNote', 'noteId', noteId)
            // verify required parameter 'editContactNoteRequestDto' is not null or undefined
            assertParamExists('updateContactNote', 'editContactNoteRequestDto', editContactNoteRequestDto)
            const localVarPath = `/v1/contacts-notes/{contactId}/{noteId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editContactNoteRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactNotesApi - functional programming interface
 * @export
 */
export const ContactNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single contact note
         * @param {string} contactId 
         * @param {CreateContactNoteRequestDto} createContactNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactNote(contactId: string, createContactNoteRequestDto: CreateContactNoteRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactNoteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContactNote(contactId, createContactNoteRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a single contact note
         * @param {string} contactId 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContactNote(contactId: string, noteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContactNote(contactId, noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single contact note
         * @param {string} contactId 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContactNote(contactId: string, noteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactNoteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findContactNote(contactId, noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple contact notes
         * @param {string} contactId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContactNotes(contactId: string, page?: number, limit?: number, search?: string, archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedContactNotesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findContactNotes(contactId, page, limit, search, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a single contact note
         * @param {string} contactId 
         * @param {string} noteId 
         * @param {EditContactNoteRequestDto} editContactNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactNote(contactId: string, noteId: string, editContactNoteRequestDto: EditContactNoteRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactNoteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactNote(contactId, noteId, editContactNoteRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactNotesApi - factory interface
 * @export
 */
export const ContactNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactNotesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single contact note
         * @param {string} contactId 
         * @param {CreateContactNoteRequestDto} createContactNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactNote(contactId: string, createContactNoteRequestDto: CreateContactNoteRequestDto, options?: any): AxiosPromise<ContactNoteResponseDto> {
            return localVarFp.createContactNote(contactId, createContactNoteRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a single contact note
         * @param {string} contactId 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactNote(contactId: string, noteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContactNote(contactId, noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single contact note
         * @param {string} contactId 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactNote(contactId: string, noteId: string, options?: any): AxiosPromise<ContactNoteResponseDto> {
            return localVarFp.findContactNote(contactId, noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple contact notes
         * @param {string} contactId 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactNotes(contactId: string, page?: number, limit?: number, search?: string, archived?: boolean, options?: any): AxiosPromise<PaginatedContactNotesResponseDto> {
            return localVarFp.findContactNotes(contactId, page, limit, search, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a single contact note
         * @param {string} contactId 
         * @param {string} noteId 
         * @param {EditContactNoteRequestDto} editContactNoteRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactNote(contactId: string, noteId: string, editContactNoteRequestDto: EditContactNoteRequestDto, options?: any): AxiosPromise<ContactNoteResponseDto> {
            return localVarFp.updateContactNote(contactId, noteId, editContactNoteRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactNotesApi - object-oriented interface
 * @export
 * @class ContactNotesApi
 * @extends {BaseAPI}
 */
export class ContactNotesApi extends BaseAPI {
    /**
     * 
     * @summary Create a single contact note
     * @param {string} contactId 
     * @param {CreateContactNoteRequestDto} createContactNoteRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactNotesApi
     */
    public createContactNote(contactId: string, createContactNoteRequestDto: CreateContactNoteRequestDto, options?: AxiosRequestConfig) {
        return ContactNotesApiFp(this.configuration).createContactNote(contactId, createContactNoteRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a single contact note
     * @param {string} contactId 
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactNotesApi
     */
    public deleteContactNote(contactId: string, noteId: string, options?: AxiosRequestConfig) {
        return ContactNotesApiFp(this.configuration).deleteContactNote(contactId, noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single contact note
     * @param {string} contactId 
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactNotesApi
     */
    public findContactNote(contactId: string, noteId: string, options?: AxiosRequestConfig) {
        return ContactNotesApiFp(this.configuration).findContactNote(contactId, noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple contact notes
     * @param {string} contactId 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {boolean} [archived] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactNotesApi
     */
    public findContactNotes(contactId: string, page?: number, limit?: number, search?: string, archived?: boolean, options?: AxiosRequestConfig) {
        return ContactNotesApiFp(this.configuration).findContactNotes(contactId, page, limit, search, archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a single contact note
     * @param {string} contactId 
     * @param {string} noteId 
     * @param {EditContactNoteRequestDto} editContactNoteRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactNotesApi
     */
    public updateContactNote(contactId: string, noteId: string, editContactNoteRequestDto: EditContactNoteRequestDto, options?: AxiosRequestConfig) {
        return ContactNotesApiFp(this.configuration).updateContactNote(contactId, noteId, editContactNoteRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
