import { SettingsValueProps } from './components/settings/type';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const FIREBASE_API = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APPID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.AUTH0_CLIENT_ID,
  domain: process.env.AUTH0_DOMAIN,
};

export const GETSTREAM_API = {
  apiKey: process.env.NEXT_PUBLIC_GETSTREAM_API_KEY,
  appId: process.env.NEXT_PUBLIC_GETSTREAM_APP_ID,
};

export const SESSION_CONFIG = {
  CLIENT_REFETCH_SESSION_INTERVAL: 60 * 2, // 2mins
  NEXT_AUTH_SESSION_MAX_AGE: 60 * 60 * 24 * 13, // 13 days - Less than SSO Session Idle
  TOKEN_REFRESH_THRESHOLD: 60 * 3, // 3 mins - If the access token has expired or will expire within specified time, try to refresh it; slightly more than CLIENT_REFETCH_SESSION_INTERVAL
};

export const MAPBOX_API = process.env.MAPBOX;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_MARGIN_RIGHT: 16,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const PAGE_CONTENT = {
  MARGIN_RIGHT: 16,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// ----------------------------------------------------------------------

export const cookiesExpires = 3;

export const cookiesKey = {
  themeMode: 'themeMode',
  themeDirection: 'themeDirection',
  themeColorPresets: 'themeColorPresets',
  themeLayout: 'themeLayout',
  themeStretch: 'themeStretch',
};

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'blue',
  themeLayout: 'horizontal',
  themeStretch: false,
};
export const isProductionEnvironment =
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN === '.subflow.com';

export const isProductionBuild = process.env.NODE_ENV === 'production';
