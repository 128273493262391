/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MerchantResponseDto } from '../models';
// @ts-ignore
import { Product } from '../models';
// @ts-ignore
import { QuickstartOnboardingUpdateRequestDto } from '../models';
// @ts-ignore
import { StripeAccountLinkRequestDto } from '../models';
// @ts-ignore
import { StripeAuthorizeResponseDto } from '../models';
// @ts-ignore
import { StripeCheckoutSessionRequestDto } from '../models';
/**
 * OnboardingApi - axios parameter creator
 * @export
 */
export const OnboardingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new stripe account and link it
         * @param {StripeAccountLinkRequestDto} stripeAccountLinkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeAccountLink: async (stripeAccountLinkRequestDto: StripeAccountLinkRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeAccountLinkRequestDto' is not null or undefined
            assertParamExists('createStripeAccountLink', 'stripeAccountLinkRequestDto', stripeAccountLinkRequestDto)
            const localVarPath = `/v1/onboarding/stripe/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripeAccountLinkRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available products with prices a merchant can subscribe to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOnboardingProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/onboarding/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new account portal session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeAccountPortalSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/onboarding/stripe/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new billing portal session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeBillingPortalSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/onboarding/stripe/portal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new checkout session
         * @param {StripeCheckoutSessionRequestDto} stripeCheckoutSessionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeCheckoutSession: async (stripeCheckoutSessionRequestDto: StripeCheckoutSessionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeCheckoutSessionRequestDto' is not null or undefined
            assertParamExists('getStripeCheckoutSession', 'stripeCheckoutSessionRequestDto', stripeCheckoutSessionRequestDto)
            const localVarPath = `/v1/onboarding/stripe/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripeCheckoutSessionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current user merchant quickstart onboarding history
         * @param {QuickstartOnboardingUpdateRequestDto} quickstartOnboardingUpdateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuickstartOnboardingHistory: async (quickstartOnboardingUpdateRequestDto: QuickstartOnboardingUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quickstartOnboardingUpdateRequestDto' is not null or undefined
            assertParamExists('updateQuickstartOnboardingHistory', 'quickstartOnboardingUpdateRequestDto', quickstartOnboardingUpdateRequestDto)
            const localVarPath = `/v1/onboarding/quickstart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quickstartOnboardingUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardingApi - functional programming interface
 * @export
 */
export const OnboardingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OnboardingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new stripe account and link it
         * @param {StripeAccountLinkRequestDto} stripeAccountLinkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStripeAccountLink(stripeAccountLinkRequestDto: StripeAccountLinkRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeAuthorizeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStripeAccountLink(stripeAccountLinkRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available products with prices a merchant can subscribe to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllOnboardingProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllOnboardingProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new account portal session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripeAccountPortalSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeAuthorizeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStripeAccountPortalSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new billing portal session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripeBillingPortalSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeAuthorizeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStripeBillingPortalSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new checkout session
         * @param {StripeCheckoutSessionRequestDto} stripeCheckoutSessionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStripeCheckoutSession(stripeCheckoutSessionRequestDto: StripeCheckoutSessionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeAuthorizeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStripeCheckoutSession(stripeCheckoutSessionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current user merchant quickstart onboarding history
         * @param {QuickstartOnboardingUpdateRequestDto} quickstartOnboardingUpdateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuickstartOnboardingHistory(quickstartOnboardingUpdateRequestDto: QuickstartOnboardingUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuickstartOnboardingHistory(quickstartOnboardingUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OnboardingApi - factory interface
 * @export
 */
export const OnboardingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OnboardingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new stripe account and link it
         * @param {StripeAccountLinkRequestDto} stripeAccountLinkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStripeAccountLink(stripeAccountLinkRequestDto: StripeAccountLinkRequestDto, options?: any): AxiosPromise<StripeAuthorizeResponseDto> {
            return localVarFp.createStripeAccountLink(stripeAccountLinkRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available products with prices a merchant can subscribe to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOnboardingProducts(options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.findAllOnboardingProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new account portal session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeAccountPortalSession(options?: any): AxiosPromise<StripeAuthorizeResponseDto> {
            return localVarFp.getStripeAccountPortalSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new billing portal session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeBillingPortalSession(options?: any): AxiosPromise<StripeAuthorizeResponseDto> {
            return localVarFp.getStripeBillingPortalSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new checkout session
         * @param {StripeCheckoutSessionRequestDto} stripeCheckoutSessionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripeCheckoutSession(stripeCheckoutSessionRequestDto: StripeCheckoutSessionRequestDto, options?: any): AxiosPromise<StripeAuthorizeResponseDto> {
            return localVarFp.getStripeCheckoutSession(stripeCheckoutSessionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current user merchant quickstart onboarding history
         * @param {QuickstartOnboardingUpdateRequestDto} quickstartOnboardingUpdateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuickstartOnboardingHistory(quickstartOnboardingUpdateRequestDto: QuickstartOnboardingUpdateRequestDto, options?: any): AxiosPromise<MerchantResponseDto> {
            return localVarFp.updateQuickstartOnboardingHistory(quickstartOnboardingUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnboardingApi - object-oriented interface
 * @export
 * @class OnboardingApi
 * @extends {BaseAPI}
 */
export class OnboardingApi extends BaseAPI {
    /**
     * 
     * @summary Create a new stripe account and link it
     * @param {StripeAccountLinkRequestDto} stripeAccountLinkRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public createStripeAccountLink(stripeAccountLinkRequestDto: StripeAccountLinkRequestDto, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).createStripeAccountLink(stripeAccountLinkRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available products with prices a merchant can subscribe to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public findAllOnboardingProducts(options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).findAllOnboardingProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new account portal session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public getStripeAccountPortalSession(options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).getStripeAccountPortalSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new billing portal session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public getStripeBillingPortalSession(options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).getStripeBillingPortalSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new checkout session
     * @param {StripeCheckoutSessionRequestDto} stripeCheckoutSessionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public getStripeCheckoutSession(stripeCheckoutSessionRequestDto: StripeCheckoutSessionRequestDto, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).getStripeCheckoutSession(stripeCheckoutSessionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current user merchant quickstart onboarding history
     * @param {QuickstartOnboardingUpdateRequestDto} quickstartOnboardingUpdateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public updateQuickstartOnboardingHistory(quickstartOnboardingUpdateRequestDto: QuickstartOnboardingUpdateRequestDto, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).updateQuickstartOnboardingHistory(quickstartOnboardingUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
