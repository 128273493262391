import {
  useSubscriberProfile,
  useSubscriberUpdateOnboardingStep,
} from '@subflow/data-access';
import { useEffect, useState } from 'react';

export interface IOnboardingStepProps {
  onStepCompleted: () => void;
}

export interface ISkippableOnboardingStepProps extends IOnboardingStepProps {
  onSkip: () => void;
}

export type TOnboardingState =
  | {
      state: 'notLoaded';
    }
  | {
      state: 'init';
    }
  | { state: 'started' }
  | { state: 'completed' }
  | { state: 'skipped' };

export const useOnboardingState = () => {
  const { data, isFetched } = useSubscriberProfile();
  const { mutateAsync } = useSubscriberUpdateOnboardingStep();
  const [onboardingState, setOnboardingState] = useState<TOnboardingState>({
    state: 'notLoaded',
  });

  const updateOnboardingState = (newState: TOnboardingState) => {
    setOnboardingState(newState);
    if (newState.state !== 'notLoaded') {
      mutateAsync({
        state: newState.state,
      });
    }
  };

  useEffect(() => {
    if (isFetched && data?.onboardingState) {
      setOnboardingState({
        state: data.onboardingState,
      });
    }
  }, [data?.onboardingState, isFetched]);

  return {
    onboardingState,
    updateOnboardingState,
  };
};
