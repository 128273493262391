// @ts-nocheck
import { axios, baseURL } from './axios';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import {
  Configuration,
  BroadcastsApiFactory,
  BroadcastDto,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

export const broadcastsApi = BroadcastsApiFactory(config, baseURL, axios);

export enum QUERY_KEYS {
  list = 'broadcasts',
  single = 'broadcast',
}

export const useCreateBroadcast = () => {
  const queryClient = useQueryClient();
  return useMutation((body: BroadcastDto) => broadcastsApi.broadcast(body), {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS.list, data.segmentId);
    },
  });
};

export const useBroadcasts = ({ segmentId, page, limit }) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, segmentId],
    async () => {
      const response = await broadcastsApi.findBroadcasts(
        segmentId,
        page,
        limit
      );
      return response.data ? response.data.results.reverse() : [];
    },
    {
      enabled: Boolean(segmentId),
      onError: (e) => {
        console.log(e);
        enqueueSnackbar('There was a problem fetching your broadcast.', {
          variant: 'error',
        });
      },
    }
  );
};
