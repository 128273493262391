/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateCustomSegmentRequestDto } from '../models';
// @ts-ignore
import { FindSegmentsByIdsRequestDto } from '../models';
// @ts-ignore
import { FindSegmentsByIdsResponseDto } from '../models';
// @ts-ignore
import { PaginatedSegmentsResponseDto } from '../models';
// @ts-ignore
import { SegmentResponseDto } from '../models';
// @ts-ignore
import { UpdateCustomSegmentRequestDto } from '../models';
// @ts-ignore
import { UpdateSegmentRequestDto } from '../models';
/**
 * SegmentsApi - axios parameter creator
 * @export
 */
export const SegmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a custom Segment
         * @param {CreateCustomSegmentRequestDto} createCustomSegmentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomSegment: async (createCustomSegmentRequestDto: CreateCustomSegmentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomSegmentRequestDto' is not null or undefined
            assertParamExists('createCustomSegment', 'createCustomSegmentRequestDto', createCustomSegmentRequestDto)
            const localVarPath = `/v1/segments/custom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomSegmentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a custom Segment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomSegment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomSegment', 'id', id)
            const localVarPath = `/v1/segments/custom/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Segments
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {boolean} [hasContacts] 
         * @param {boolean} [published] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSegments: async (page?: number, limit?: number, hasContacts?: boolean, published?: boolean, archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/segments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (hasContacts !== undefined) {
                localVarQueryParameter['hasContacts'] = hasContacts;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all segments by ids
         * @param {FindSegmentsByIdsRequestDto} findSegmentsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSegmentsByIds: async (findSegmentsByIdsRequestDto: FindSegmentsByIdsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findSegmentsByIdsRequestDto' is not null or undefined
            assertParamExists('findAllSegmentsByIds', 'findSegmentsByIdsRequestDto', findSegmentsByIdsRequestDto)
            const localVarPath = `/v1/segments/byIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findSegmentsByIdsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Segment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSegment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneSegment', 'id', id)
            const localVarPath = `/v1/segments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a custom Segment
         * @param {string} id 
         * @param {UpdateCustomSegmentRequestDto} updateCustomSegmentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomSegment: async (id: string, updateCustomSegmentRequestDto: UpdateCustomSegmentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomSegment', 'id', id)
            // verify required parameter 'updateCustomSegmentRequestDto' is not null or undefined
            assertParamExists('updateCustomSegment', 'updateCustomSegmentRequestDto', updateCustomSegmentRequestDto)
            const localVarPath = `/v1/segments/custom/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomSegmentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Segment
         * @param {string} id 
         * @param {UpdateSegmentRequestDto} updateSegmentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSegment: async (id: string, updateSegmentRequestDto: UpdateSegmentRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSegment', 'id', id)
            // verify required parameter 'updateSegmentRequestDto' is not null or undefined
            assertParamExists('updateSegment', 'updateSegmentRequestDto', updateSegmentRequestDto)
            const localVarPath = `/v1/segments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSegmentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SegmentsApi - functional programming interface
 * @export
 */
export const SegmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SegmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a custom Segment
         * @param {CreateCustomSegmentRequestDto} createCustomSegmentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomSegment(createCustomSegmentRequestDto: CreateCustomSegmentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomSegment(createCustomSegmentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a custom Segment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomSegment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomSegment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Segments
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {boolean} [hasContacts] 
         * @param {boolean} [published] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSegments(page?: number, limit?: number, hasContacts?: boolean, published?: boolean, archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSegmentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSegments(page, limit, hasContacts, published, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all segments by ids
         * @param {FindSegmentsByIdsRequestDto} findSegmentsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSegmentsByIds(findSegmentsByIdsRequestDto: FindSegmentsByIdsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindSegmentsByIdsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSegmentsByIds(findSegmentsByIdsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Segment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneSegment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneSegment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a custom Segment
         * @param {string} id 
         * @param {UpdateCustomSegmentRequestDto} updateCustomSegmentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomSegment(id: string, updateCustomSegmentRequestDto: UpdateCustomSegmentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomSegment(id, updateCustomSegmentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Segment
         * @param {string} id 
         * @param {UpdateSegmentRequestDto} updateSegmentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSegment(id: string, updateSegmentRequestDto: UpdateSegmentRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSegment(id, updateSegmentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SegmentsApi - factory interface
 * @export
 */
export const SegmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SegmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a custom Segment
         * @param {CreateCustomSegmentRequestDto} createCustomSegmentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomSegment(createCustomSegmentRequestDto: CreateCustomSegmentRequestDto, options?: any): AxiosPromise<SegmentResponseDto> {
            return localVarFp.createCustomSegment(createCustomSegmentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a custom Segment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomSegment(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomSegment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Segments
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {boolean} [hasContacts] 
         * @param {boolean} [published] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSegments(page?: number, limit?: number, hasContacts?: boolean, published?: boolean, archived?: boolean, options?: any): AxiosPromise<PaginatedSegmentsResponseDto> {
            return localVarFp.findAllSegments(page, limit, hasContacts, published, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all segments by ids
         * @param {FindSegmentsByIdsRequestDto} findSegmentsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSegmentsByIds(findSegmentsByIdsRequestDto: FindSegmentsByIdsRequestDto, options?: any): AxiosPromise<FindSegmentsByIdsResponseDto> {
            return localVarFp.findAllSegmentsByIds(findSegmentsByIdsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Segment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSegment(id: string, options?: any): AxiosPromise<SegmentResponseDto> {
            return localVarFp.findOneSegment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a custom Segment
         * @param {string} id 
         * @param {UpdateCustomSegmentRequestDto} updateCustomSegmentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomSegment(id: string, updateCustomSegmentRequestDto: UpdateCustomSegmentRequestDto, options?: any): AxiosPromise<SegmentResponseDto> {
            return localVarFp.updateCustomSegment(id, updateCustomSegmentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Segment
         * @param {string} id 
         * @param {UpdateSegmentRequestDto} updateSegmentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSegment(id: string, updateSegmentRequestDto: UpdateSegmentRequestDto, options?: any): AxiosPromise<SegmentResponseDto> {
            return localVarFp.updateSegment(id, updateSegmentRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SegmentsApi - object-oriented interface
 * @export
 * @class SegmentsApi
 * @extends {BaseAPI}
 */
export class SegmentsApi extends BaseAPI {
    /**
     * 
     * @summary Create a custom Segment
     * @param {CreateCustomSegmentRequestDto} createCustomSegmentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentsApi
     */
    public createCustomSegment(createCustomSegmentRequestDto: CreateCustomSegmentRequestDto, options?: AxiosRequestConfig) {
        return SegmentsApiFp(this.configuration).createCustomSegment(createCustomSegmentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a custom Segment
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentsApi
     */
    public deleteCustomSegment(id: string, options?: AxiosRequestConfig) {
        return SegmentsApiFp(this.configuration).deleteCustomSegment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Segments
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {boolean} [hasContacts] 
     * @param {boolean} [published] 
     * @param {boolean} [archived] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentsApi
     */
    public findAllSegments(page?: number, limit?: number, hasContacts?: boolean, published?: boolean, archived?: boolean, options?: AxiosRequestConfig) {
        return SegmentsApiFp(this.configuration).findAllSegments(page, limit, hasContacts, published, archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all segments by ids
     * @param {FindSegmentsByIdsRequestDto} findSegmentsByIdsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentsApi
     */
    public findAllSegmentsByIds(findSegmentsByIdsRequestDto: FindSegmentsByIdsRequestDto, options?: AxiosRequestConfig) {
        return SegmentsApiFp(this.configuration).findAllSegmentsByIds(findSegmentsByIdsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Segment
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentsApi
     */
    public findOneSegment(id: string, options?: AxiosRequestConfig) {
        return SegmentsApiFp(this.configuration).findOneSegment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a custom Segment
     * @param {string} id 
     * @param {UpdateCustomSegmentRequestDto} updateCustomSegmentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentsApi
     */
    public updateCustomSegment(id: string, updateCustomSegmentRequestDto: UpdateCustomSegmentRequestDto, options?: AxiosRequestConfig) {
        return SegmentsApiFp(this.configuration).updateCustomSegment(id, updateCustomSegmentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Segment
     * @param {string} id 
     * @param {UpdateSegmentRequestDto} updateSegmentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentsApi
     */
    public updateSegment(id: string, updateSegmentRequestDto: UpdateSegmentRequestDto, options?: AxiosRequestConfig) {
        return SegmentsApiFp(this.configuration).updateSegment(id, updateSegmentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
