/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FileResponseDto } from './file-response-dto';
import { MerchantPublicDataResponseDto } from './merchant-public-data-response-dto';

/**
 * 
 * @export
 * @interface PageResponseDto
 */
export interface PageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PageResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PageResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PageResponseDto
     */
    'slug': string;
    /**
     * 
     * @type {PageResponseDto}
     * @memberof PageResponseDto
     */
    'parent': PageResponseDto;
    /**
     * 
     * @type {string}
     * @memberof PageResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PageResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {MerchantPublicDataResponseDto}
     * @memberof PageResponseDto
     */
    'merchant': MerchantPublicDataResponseDto;
    /**
     * 
     * @type {boolean}
     * @memberof PageResponseDto
     */
    'published': boolean;
    /**
     * 
     * @type {object}
     * @memberof PageResponseDto
     */
    'viewConfiguration': object;
    /**
     * 
     * @type {FileResponseDto}
     * @memberof PageResponseDto
     */
    'openGraphImage': FileResponseDto;
    /**
     * 
     * @type {string}
     * @memberof PageResponseDto
     */
    'accessType': PageResponseDtoAccessTypeEnum;
}

export const PageResponseDtoAccessTypeEnum = {
    Public: 'public',
    Restricted: 'restricted'
} as const;

export type PageResponseDtoAccessTypeEnum = typeof PageResponseDtoAccessTypeEnum[keyof typeof PageResponseDtoAccessTypeEnum];


