import { signIn, useSession } from 'next-auth/react';
import { setToken } from '@subflow/data-access';

export const useSessionAuth = () => {
  const session = useSession();
  const { data, status, update } = session;

  if (status !== 'loading') {
    if (status === 'authenticated') {
      setToken(data.accessToken);
    } else if (status === 'unauthenticated') {
      setToken();
    }
  }

  if (data?.error && data.error === 'RefreshAccessTokenError') {
    // trigger async signIn method and return isAuthenticated=false value if there are some components listening to useSessionAuth hook
    signIn('keycloak');
    return {
      session: data,
      user: data?.user,
      expires: data?.expires,
      loading: status === 'loading',
      isAuthenticated: false,
      update,
    };
  }

  return {
    session: data,
    user: data?.user,
    expires: data?.expires,
    loading: status === 'loading',
    isAuthenticated: status === 'authenticated',
    update,
  };
};
