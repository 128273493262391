import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from '@subflow-frontend/redux/store';

// ----------------------------------------------------------------------

/*
  Basic feature flags functionality
  What could be improved:
  - SSR support
  - Store feature flags in a database instead of local storage
 */
const FEATURE_FLAG_STORAGE_KEY = 'enabledFeatures';

type Index = {
  label?: string;
  enabled: boolean;
  variant?: string;
};

export type FeatureFlagState = {
  features: {
    byId: Record<string, Index>;
    loaded: boolean;
  };
};

const initialState: FeatureFlagState = {
  features: {
    byId: {
      useSchedulesInSequenceSubscribersTab: {
        enabled: false,
        label: 'Schedules in Sequence Recipients',
      },
      useDebugContentOrder: {
        enabled: false,
        label: 'Enable order debugging for content library',
      },
      useShowCommunityMemberFlagOnSubscriptionPageFeatureFlag: {
        enabled: false,
        label: 'Show community member flag on subscription page',
      },
      useShowDisableWelcomeMsgFieldFeatureFlag: {
        enabled: false,
        label: 'Show disable welcome msg field on subscription page',
      },
      useCoursesFeatureFlag: {
        enabled: false,
        label: 'Enable Courses Page',
      },
    },
    loaded: false,
  },
};

const slice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    initializeFeatureFlagsSuccess(
      state,
      action: PayloadAction<Record<string, Pick<Index, 'enabled'>>>
    ) {
      Object.entries(action.payload).forEach(([featureKey, featureValue]) => {
        if (state.features.byId[featureKey]) {
          state.features.byId[featureKey].enabled = featureValue.enabled;
        }
      });
      state.features.loaded = true;
    },
    toggleFeatureFlagSuccess(
      state,
      action: PayloadAction<{ feature: string; enabled: boolean }>
    ) {
      if (state.features.byId[action.payload.feature]) {
        state.features.byId[action.payload.feature].enabled =
          action.payload.enabled;
      }
    },
  },
});

export function initializeFeatureFlags() {
  return async () => {
    const enabledFeaturesAsString = localStorage.getItem(
      FEATURE_FLAG_STORAGE_KEY
    );
    if (enabledFeaturesAsString) {
      const enabledFeatures = JSON.parse(enabledFeaturesAsString) as Record<
        string,
        Pick<Index, 'enabled'>
      >;
      dispatch(slice.actions.initializeFeatureFlagsSuccess(enabledFeatures));
    } else {
      dispatch(slice.actions.initializeFeatureFlagsSuccess({}));
    }
  };
}

export function toggleFeatureFlag(feature: string, enabled: boolean) {
  return async () => {
    const enabledFeaturesAsString = localStorage.getItem(
      FEATURE_FLAG_STORAGE_KEY
    );
    const enabledFeatures = (
      enabledFeaturesAsString ? JSON.parse(enabledFeaturesAsString) : {}
    ) as Record<string, Pick<Index, 'enabled'>>;
    enabledFeatures[feature] = { enabled };
    dispatch(
      slice.actions.toggleFeatureFlagSuccess({
        feature,
        enabled,
      })
    );
    localStorage.setItem(
      FEATURE_FLAG_STORAGE_KEY,
      JSON.stringify(enabledFeatures)
    );
  };
}

// Reducer
export default slice.reducer;
