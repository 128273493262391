import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import {
  MotionContainer,
  varBounce,
} from '@subflow-frontend/components/animate';
import { m } from 'framer-motion';
import { SeverErrorIllustration } from '@subflow-frontend/assets';
import { styled } from '@mui/material/styles';
import Router from 'next/router';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

interface ErrorComponentProps {
  errorMessage: string;
}

export const ErrorComponent: React.FC<ErrorComponentProps> = ({
  errorMessage,
}) => {
  return (
    <RootStyle>
      <Container component={MotionContainer}>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              {errorMessage}
            </Typography>
          </m.div>
          <Typography sx={{ color: 'text.secondary' }}>
            There was an error, please try again later.
          </Typography>
          <m.div variants={varBounce().in}>
            <SeverErrorIllustration
              sx={{ height: 260, my: { xs: 5, sm: 10 } }}
            />
          </m.div>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              Router.reload();
            }}
          >
            Reload Page
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
};
