/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateSurveyRequestDto } from '../models';
// @ts-ignore
import { PaginatedSurveysResponseDto } from '../models';
// @ts-ignore
import { ScheduleSurveyRequestDto } from '../models';
// @ts-ignore
import { Survey } from '../models';
// @ts-ignore
import { SurveyAggregrateResponseDto } from '../models';
// @ts-ignore
import { SurveyResponseDto } from '../models';
// @ts-ignore
import { SurveySendRequestDto } from '../models';
// @ts-ignore
import { UpdateSurveyRequestDto } from '../models';
/**
 * SurveysApi - axios parameter creator
 * @export
 */
export const SurveysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retreive survey results
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateResults: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateResults', 'id', id)
            const localVarPath = `/v1/surveys/{id}/results`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels schedules for a single survey
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSurvey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelSurvey', 'id', id)
            const localVarPath = `/v1/surveys/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new survey
         * @param {CreateSurveyRequestDto} createSurveyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSurvey: async (createSurveyRequestDto: CreateSurveyRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSurveyRequestDto' is not null or undefined
            assertParamExists('createSurvey', 'createSurveyRequestDto', createSurveyRequestDto)
            const localVarPath = `/v1/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSurveyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a single surveys
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSurvey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSurvey', 'id', id)
            const localVarPath = `/v1/surveys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicates a single survey
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateSurvey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateSurvey', 'id', id)
            const localVarPath = `/v1/surveys/{id}/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retreive multiple surveys
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSurveys: async (page?: number, limit?: number, search?: string, archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retreive a single surveys
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSurvey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneSurvey', 'id', id)
            const localVarPath = `/v1/surveys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule survey
         * @param {string} id 
         * @param {ScheduleSurveyRequestDto} scheduleSurveyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleSurvey: async (id: string, scheduleSurveyRequestDto: ScheduleSurveyRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduleSurvey', 'id', id)
            // verify required parameter 'scheduleSurveyRequestDto' is not null or undefined
            assertParamExists('scheduleSurvey', 'scheduleSurveyRequestDto', scheduleSurveyRequestDto)
            const localVarPath = `/v1/surveys/{id}/schedule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleSurveyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a single survey
         * @param {string} id 
         * @param {SurveySendRequestDto} surveySendRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurvey: async (id: string, surveySendRequestDto: SurveySendRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendSurvey', 'id', id)
            // verify required parameter 'surveySendRequestDto' is not null or undefined
            assertParamExists('sendSurvey', 'surveySendRequestDto', surveySendRequestDto)
            const localVarPath = `/v1/surveys/{id}/send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveySendRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a single survey
         * @param {string} id 
         * @param {UpdateSurveyRequestDto} updateSurveyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSurvey: async (id: string, updateSurveyRequestDto: UpdateSurveyRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSurvey', 'id', id)
            // verify required parameter 'updateSurveyRequestDto' is not null or undefined
            assertParamExists('updateSurvey', 'updateSurveyRequestDto', updateSurveyRequestDto)
            const localVarPath = `/v1/surveys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSurveyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveysApi - functional programming interface
 * @export
 */
export const SurveysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retreive survey results
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateResults(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyAggregrateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateResults(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancels schedules for a single survey
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSurvey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSurvey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new survey
         * @param {CreateSurveyRequestDto} createSurveyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSurvey(createSurveyRequestDto: CreateSurveyRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSurvey(createSurveyRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a single surveys
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSurvey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSurvey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Duplicates a single survey
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateSurvey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateSurvey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retreive multiple surveys
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSurveys(page?: number, limit?: number, search?: string, archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSurveysResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSurveys(page, limit, search, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retreive a single surveys
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneSurvey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneSurvey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedule survey
         * @param {string} id 
         * @param {ScheduleSurveyRequestDto} scheduleSurveyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleSurvey(id: string, scheduleSurveyRequestDto: ScheduleSurveyRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleSurvey(id, scheduleSurveyRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a single survey
         * @param {string} id 
         * @param {SurveySendRequestDto} surveySendRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSurvey(id: string, surveySendRequestDto: SurveySendRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSurvey(id, surveySendRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a single survey
         * @param {string} id 
         * @param {UpdateSurveyRequestDto} updateSurveyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSurvey(id: string, updateSurveyRequestDto: UpdateSurveyRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSurvey(id, updateSurveyRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveysApi - factory interface
 * @export
 */
export const SurveysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveysApiFp(configuration)
    return {
        /**
         * 
         * @summary Retreive survey results
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateResults(id: string, options?: any): AxiosPromise<SurveyAggregrateResponseDto> {
            return localVarFp.aggregateResults(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels schedules for a single survey
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSurvey(id: string, options?: any): AxiosPromise<SurveyResponseDto> {
            return localVarFp.cancelSurvey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new survey
         * @param {CreateSurveyRequestDto} createSurveyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSurvey(createSurveyRequestDto: CreateSurveyRequestDto, options?: any): AxiosPromise<SurveyResponseDto> {
            return localVarFp.createSurvey(createSurveyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a single surveys
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSurvey(id: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.deleteSurvey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicates a single survey
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateSurvey(id: string, options?: any): AxiosPromise<SurveyResponseDto> {
            return localVarFp.duplicateSurvey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retreive multiple surveys
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSurveys(page?: number, limit?: number, search?: string, archived?: boolean, options?: any): AxiosPromise<PaginatedSurveysResponseDto> {
            return localVarFp.findAllSurveys(page, limit, search, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retreive a single surveys
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneSurvey(id: string, options?: any): AxiosPromise<SurveyResponseDto> {
            return localVarFp.findOneSurvey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule survey
         * @param {string} id 
         * @param {ScheduleSurveyRequestDto} scheduleSurveyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleSurvey(id: string, scheduleSurveyRequestDto: ScheduleSurveyRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.scheduleSurvey(id, scheduleSurveyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a single survey
         * @param {string} id 
         * @param {SurveySendRequestDto} surveySendRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurvey(id: string, surveySendRequestDto: SurveySendRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendSurvey(id, surveySendRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a single survey
         * @param {string} id 
         * @param {UpdateSurveyRequestDto} updateSurveyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSurvey(id: string, updateSurveyRequestDto: UpdateSurveyRequestDto, options?: any): AxiosPromise<SurveyResponseDto> {
            return localVarFp.updateSurvey(id, updateSurveyRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveysApi - object-oriented interface
 * @export
 * @class SurveysApi
 * @extends {BaseAPI}
 */
export class SurveysApi extends BaseAPI {
    /**
     * 
     * @summary Retreive survey results
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public aggregateResults(id: string, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).aggregateResults(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels schedules for a single survey
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public cancelSurvey(id: string, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).cancelSurvey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new survey
     * @param {CreateSurveyRequestDto} createSurveyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public createSurvey(createSurveyRequestDto: CreateSurveyRequestDto, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).createSurvey(createSurveyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a single surveys
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public deleteSurvey(id: string, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).deleteSurvey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicates a single survey
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public duplicateSurvey(id: string, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).duplicateSurvey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retreive multiple surveys
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {boolean} [archived] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public findAllSurveys(page?: number, limit?: number, search?: string, archived?: boolean, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).findAllSurveys(page, limit, search, archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retreive a single surveys
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public findOneSurvey(id: string, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).findOneSurvey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule survey
     * @param {string} id 
     * @param {ScheduleSurveyRequestDto} scheduleSurveyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public scheduleSurvey(id: string, scheduleSurveyRequestDto: ScheduleSurveyRequestDto, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).scheduleSurvey(id, scheduleSurveyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a single survey
     * @param {string} id 
     * @param {SurveySendRequestDto} surveySendRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public sendSurvey(id: string, surveySendRequestDto: SurveySendRequestDto, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).sendSurvey(id, surveySendRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a single survey
     * @param {string} id 
     * @param {UpdateSurveyRequestDto} updateSurveyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public updateSurvey(id: string, updateSurveyRequestDto: UpdateSurveyRequestDto, options?: AxiosRequestConfig) {
        return SurveysApiFp(this.configuration).updateSurvey(id, updateSurveyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
