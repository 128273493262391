import { useMemo, ReactNode } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { withDarkModeProvider, useDarkMode } from '../hooks/useDarkMode';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

export const subscriberTheme = 'subscriber';

export type AppTheme = 'light' | 'dark' | typeof subscriberTheme;

type Props = {
  defaultSettings: any;
  children: ReactNode;
  appTheme?: AppTheme;
};

function ThemeProvider({ children, appTheme }: Props) {
  const { mode } = useDarkMode();
  const isLight = mode === 'light' || appTheme === 'subscriber';
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: appTheme
        ? palette[appTheme]
        : isLight
        ? palette.light
        : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: appTheme
        ? shadows[appTheme]
        : isLight
        ? shadows.light
        : shadows.dark,
      customShadows: appTheme
        ? customShadows[appTheme]
        : isLight
        ? customShadows.light
        : customShadows.dark,
    }),
    [isLight, appTheme]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

export default withDarkModeProvider(ThemeProvider);
