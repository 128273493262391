import { ReactNode, useState, useEffect, FC } from 'react';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import Router, { useRouter } from 'next/router';
import { SUBSCRIBER_USER_TYPE } from 'libs/data-access/src/utils/constants';
import { signIn } from 'next-auth/react';
import { Session } from 'next-auth';
import { useOnboardingState } from '@subflow-frontend/sections/subscriber/onboarding';
import { useSubscriberPaths } from '@subflow-frontend/routes/paths';

type SubscriberGuardProp = {
  children: ReactNode | string;
};

export const isSubscriberUser = (session: Session | null) =>
  session && session.userType.includes(SUBSCRIBER_USER_TYPE);

export const SubscriberGuard = ({ children }: SubscriberGuardProp) => {
  const [accessGranted, setAccessGranted] = useState(false);
  const { session, isAuthenticated, loading } = useSessionAuth();
  const { push } = useRouter();

  useEffect(() => {
    if (loading) {
      return;
    } else {
      if (session && isAuthenticated) {
        const isSubscriber = session.userType.includes(SUBSCRIBER_USER_TYPE);
        if (isSubscriber) {
          setAccessGranted(true);
        } else {
          // push(PATH_DASHBOARD.root);
        }
      } else {
        setAccessGranted(false);
        signIn('keycloak');
      }
    }
  }, [isAuthenticated, loading, push, session]);

  if (!accessGranted) {
    return null;
  }

  return <OnboardingGuard>{children}</OnboardingGuard>;
};

const OnboardingGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { onboardingState } = useOnboardingState();
  const [onboardingChecked, setOnboardingChecked] = useState(false);
  const { subscriberPaths } = useSubscriberPaths();

  useEffect(() => {
    if (onboardingState.state !== 'notLoaded') {
      if (
        (onboardingState.state === 'init' ||
          onboardingState.state === 'started') &&
        Router.pathname !== '/sub/[subdomain]/onboarding'
      ) {
        Router.push(subscriberPaths.onboarding(Router.asPath)).then(() =>
          setOnboardingChecked(true)
        );
      } else {
        setOnboardingChecked(true);
      }
    }
  }, [onboardingState.state, subscriberPaths]);

  if (!onboardingChecked) return null;

  return <>{children}</>;
};
