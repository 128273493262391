/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface QuickstartOnboardingUpdateRequestDto
 */
export interface QuickstartOnboardingUpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof QuickstartOnboardingUpdateRequestDto
     */
    'step': QuickstartOnboardingUpdateRequestDtoStepEnum;
    /**
     * 
     * @type {boolean}
     * @memberof QuickstartOnboardingUpdateRequestDto
     */
    'complete': boolean;
}

export const QuickstartOnboardingUpdateRequestDtoStepEnum = {
    QuickStartOrgSettings: 'quickStartOrgSettings',
    QuickStartCreateProduct: 'quickStartCreateProduct',
    QuickStartAddTemplate: 'quickStartAddTemplate',
    QuickStartAddEmailNewsletter: 'quickStartAddEmailNewsletter',
    QuickStartPromoteProduct: 'quickStartPromoteProduct',
    QuickStartCreateSequence: 'quickStartCreateSequence',
    QuickStartAddContent: 'quickStartAddContent',
    QuickStartFirstPage: 'quickStartFirstPage',
    QuickStartVerifyNumber: 'quickStartVerifyNumber',
    QuickStartFirstMessage: 'quickStartFirstMessage',
    QuickStartHasPlan: 'quickStartHasPlan'
} as const;

export type QuickstartOnboardingUpdateRequestDtoStepEnum = typeof QuickstartOnboardingUpdateRequestDtoStepEnum[keyof typeof QuickstartOnboardingUpdateRequestDtoStepEnum];


