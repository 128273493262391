import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import {
  Configuration,
  CreateProductWithPriceRequestDto,
  ProductsApiFactory,
  UpdateProductRequestDto,
} from '@subflow/api-client';
import { PaginatedSequenceSchedulesParams } from 'libs/data-access/src/sequences';

const config = new Configuration({
  basePath: baseURL,
});

const subscriptionsApi = ProductsApiFactory(config, baseURL, axios);

export interface PaginatedProductsParams {
  page?: number;
  limit?: number;
  search?: string;
}

enum QUERY_KEYS {
  list = 'products',
  infiniteList = 'infiniteSubscriptions',
  subscribers = 'products.subscribers',
  single = 'product',
}

export function useSubscriptionsWithSubscribers() {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    QUERY_KEYS.subscribers,
    async () => {
      const response = await subscriptionsApi.getProductsWithSubscribers();
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your subscriptions.', {
          variant: 'error',
        });
      },
    }
  );
}

export const checkSubscriptionExistsWithSlug = async (
  slug: string
): Promise<any> => {
  const response = await subscriptionsApi.checkProductWithSlug(slug);
  return response.data;
};

export function useSubscriptions({
  page,
  limit,
  search,
}: PaginatedProductsParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, page, limit, search],
    async () => {
      const response = await subscriptionsApi.findAllProducts(
        page,
        limit,
        search
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your subscriptions.', {
          variant: 'error',
        });
      },
    }
  );
}

export function useInfiniteSubscriptions(
  params: Omit<PaginatedProductsParams, 'page'>
) {
  const { limit } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useInfiniteQuery(
    [QUERY_KEYS.infiniteList, limit, search],
    async ({ pageParam = 1 }) => {
      const response = await subscriptionsApi.findAllProducts(
        pageParam,
        limit,
        search
      );
      return {
        ...response.data,
        results: response.data?.results || [],
      };
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your subscriptions.', {
          variant: 'error',
        });
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.results.length === 0) return undefined;

        return lastPage.page + 1 ?? undefined;
      },
      getPreviousPageParam: (lastPage) => {
        if (lastPage.results.length === 0) return undefined;

        return lastPage.page - 1 ?? undefined;
      },
    }
  );
}

export const useCreateSubscription = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: CreateProductWithPriceRequestDto) =>
      subscriptionsApi.createProduct(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar(`${variables.name} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your subscription.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface UpdateSubscriptionRequestId {
  body: UpdateProductRequestDto;
  subscriptionId: string;
}

export const useUpdateSubscription = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ body, subscriptionId }: UpdateSubscriptionRequestId) =>
      subscriptionsApi.updateProduct(subscriptionId, body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        queryClient.invalidateQueries([
          QUERY_KEYS.single,
          variables.subscriptionId,
        ]);
        enqueueSnackbar(`${variables.body.name} was successfully updated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating your subscription.', {
          variant: 'error',
        });
      },
    }
  );
};

export function useCustomersBySubscriptionId(productId: string) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, productId],
    async () => {
      const response = await subscriptionsApi.getCustomersByProductId(
        productId
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your customers.', {
          variant: 'error',
        });
      },
    }
  );
}

export const useSubscription = (subscriptionId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, subscriptionId],
    async () => {
      // TODO: Update this endpoint to return prices
      const response = await subscriptionsApi.findOneProduct(subscriptionId);
      return response.data;
    },
    {
      enabled: Boolean(subscriptionId),
      refetchOnMount: false,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your subscription.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteSubscription = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (productId: string) => subscriptionsApi.deleteProduct(productId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.list);
        enqueueSnackbar('Your subscription was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your subscription.', {
          variant: 'error',
        });
      },
    }
  );
};
