import { baseURL, axios } from './axios';
import {
  Configuration,
  CreateSupportTicketRequestDto,
  CreateInternationalMerchantContactRequestDto,
  HubspotGatewayApiFactory,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const hubspotGatewayApi = HubspotGatewayApiFactory(config, baseURL, axios);

export const createSupportTicket = async (
  body: CreateSupportTicketRequestDto
): Promise<any> => {
  const response = await hubspotGatewayApi.createSupportTicket(body);
  return response;
};

export const createInternationalMerchantContact = async (
  body: CreateInternationalMerchantContactRequestDto
): Promise<any> => {
  const response = await hubspotGatewayApi.createInternationalMerchantContact(
    body
  );
  return response;
};
