/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConversationDto } from '../models';
// @ts-ignore
import { PaginatedConversationsResponseDto } from '../models';
// @ts-ignore
import { PaginatedMessagesResponseDto } from '../models';
// @ts-ignore
import { UnreadMessagesCountResponseDto } from '../models';
/**
 * ConversationsApi - axios parameter creator
 * @export
 */
export const ConversationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all active conversations
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {Array<string>} [tags] 
         * @param {boolean} [unreadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConversations: async (limit?: number, page?: number, search?: string, tags?: Array<string>, unreadOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (unreadOnly !== undefined) {
                localVarQueryParameter['unreadOnly'] = unreadOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch a single conversation
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation: async (conversationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getConversation', 'conversationId', conversationId)
            const localVarPath = `/v1/conversations/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch messages for a conversation
         * @param {string} conversationId 
         * @param {string} [cursor] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (conversationId: string, cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getMessages', 'conversationId', conversationId)
            const localVarPath = `/v1/conversations/{conversationId}/messages`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get unread messages count for conversations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalUnreadMessages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/conversations/unread-messages/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get usage
         * @param {number} startDate 
         * @param {number} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsage: async (startDate: number, endDate: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getUsage', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getUsage', 'endDate', endDate)
            const localVarPath = `/v1/conversations/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationsApi - functional programming interface
 * @export
 */
export const ConversationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all active conversations
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {Array<string>} [tags] 
         * @param {boolean} [unreadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConversations(limit?: number, page?: number, search?: string, tags?: Array<string>, unreadOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedConversationsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConversations(limit, page, search, tags, unreadOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch a single conversation
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversation(conversationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversation(conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch messages for a conversation
         * @param {string} conversationId 
         * @param {string} [cursor] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(conversationId: string, cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMessagesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(conversationId, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get unread messages count for conversations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalUnreadMessages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnreadMessagesCountResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalUnreadMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get usage
         * @param {number} startDate 
         * @param {number} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsage(startDate: number, endDate: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsage(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConversationsApi - factory interface
 * @export
 */
export const ConversationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all active conversations
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [search] 
         * @param {Array<string>} [tags] 
         * @param {boolean} [unreadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConversations(limit?: number, page?: number, search?: string, tags?: Array<string>, unreadOnly?: boolean, options?: any): AxiosPromise<PaginatedConversationsResponseDto> {
            return localVarFp.getAllConversations(limit, page, search, tags, unreadOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch a single conversation
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation(conversationId: string, options?: any): AxiosPromise<ConversationDto> {
            return localVarFp.getConversation(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch messages for a conversation
         * @param {string} conversationId 
         * @param {string} [cursor] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(conversationId: string, cursor?: string, limit?: number, options?: any): AxiosPromise<PaginatedMessagesResponseDto> {
            return localVarFp.getMessages(conversationId, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get unread messages count for conversations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalUnreadMessages(options?: any): AxiosPromise<UnreadMessagesCountResponseDto> {
            return localVarFp.getTotalUnreadMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get usage
         * @param {number} startDate 
         * @param {number} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsage(startDate: number, endDate: number, options?: any): AxiosPromise<void> {
            return localVarFp.getUsage(startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationsApi - object-oriented interface
 * @export
 * @class ConversationsApi
 * @extends {BaseAPI}
 */
export class ConversationsApi extends BaseAPI {
    /**
     * 
     * @summary Get all active conversations
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [search] 
     * @param {Array<string>} [tags] 
     * @param {boolean} [unreadOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getAllConversations(limit?: number, page?: number, search?: string, tags?: Array<string>, unreadOnly?: boolean, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).getAllConversations(limit, page, search, tags, unreadOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch a single conversation
     * @param {string} conversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getConversation(conversationId: string, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).getConversation(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch messages for a conversation
     * @param {string} conversationId 
     * @param {string} [cursor] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getMessages(conversationId: string, cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).getMessages(conversationId, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get unread messages count for conversations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getTotalUnreadMessages(options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).getTotalUnreadMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get usage
     * @param {number} startDate 
     * @param {number} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getUsage(startDate: number, endDate: number, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).getUsage(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}
