import {
  Configuration,
  IntegrationsApiFactory,
  MailchimpCallbackRequestDto,
} from '@subflow/api-client';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axios, baseURL } from './axios';

const config = new Configuration({
  basePath: baseURL,
});

export const integrationsApi = IntegrationsApiFactory(config, baseURL, axios);

export const useAuthorizeMailchimp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    (request: MailchimpCallbackRequestDto) =>
      integrationsApi.authorizeMailchimp(request),
    {
      onSuccess: async (response, variables) => {
        queryClient.invalidateQueries('status');

        enqueueSnackbar(`Mailchimp was successfully connected!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(
          'There was a problem connecting your mailchimp account.',
          {
            variant: 'error',
          }
        );
      },
    }
  );
};
export const useDeauthorizeMailchimp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(() => integrationsApi.deauthorizeMailchimp(), {
    onSuccess: async (response, variables) => {
      queryClient.invalidateQueries('status');

      enqueueSnackbar(`Mailchimp was successfully disconnected!`, {
        variant: 'success',
      });
    },
    onError: () => {
      queryClient.invalidateQueries('status');

      enqueueSnackbar(
        'There was a problem disconnecting your mailchimp account.',
        {
          variant: 'error',
        }
      );
    },
  });
};

export const useMailchimpStatus = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['status'],
    async () => {
      const response = await integrationsApi.getMailchimpIntegrationStatus();
      return response.data;
    },
    {
      onError: (e: Error) => {
        if (e.message === "Mailchimp integration doesn't exist.") {
          return;
        }

        enqueueSnackbar(
          'There was a problem fetching your mailchimp integration status..',
          {
            variant: 'error',
          }
        );
      },
    }
  );
};
