/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InviteMemberRequestDto
 */
export interface InviteMemberRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InviteMemberRequestDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InviteMemberRequestDto
     */
    'role': InviteMemberRequestDtoRoleEnum;
}

export const InviteMemberRequestDtoRoleEnum = {
    Owner: 'org-owner',
    Admin: 'org-admin',
    Manager: 'org-manager',
    Member: 'org-member',
    Viewer: 'org-viewer'
} as const;

export type InviteMemberRequestDtoRoleEnum = typeof InviteMemberRequestDtoRoleEnum[keyof typeof InviteMemberRequestDtoRoleEnum];


