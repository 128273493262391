import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import {
  Configuration,
  EmailTemplateRequestDto,
  EmailTemplatesApiFactory,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const emailTemplatesApi = EmailTemplatesApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'email-templates',
  single = 'email-template',
}

export const useEmailTemplate = (templateId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, templateId],
    async () => {
      const response = await emailTemplatesApi.findEmailTemplate(templateId);
      return response.data;
    },
    {
      enabled: !!templateId,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your template.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useEmailTemplates = (params: PaginatedEmailTemplateParams) => {
  const { page, limit } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, { page, limit, search }],
    async () => {
      const response = await emailTemplatesApi.findAllEmailTemplates(
        page,
        limit,
        search
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your email templates.', {
          variant: 'error',
        });
      },
    }
  );
};

export interface PaginatedEmailTemplateParams {
  page?: number;
  limit?: number;
  search?: string;
}

const base64Encode = btoa;

export const useCreateEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: EmailTemplateRequestDto) =>
      emailTemplatesApi.createEmailTemplate({
        ...body,
        template: base64Encode(JSON.stringify(body.template)),
        generatedHtml: base64Encode(body.generatedHtml),
      }),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.single] });
        enqueueSnackbar(`${variables.name} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your template.', {
          variant: 'error',
        });
      },
    }
  );
};

type UpdateEmailTemplateRequest = {
  body: EmailTemplateRequestDto;
  templateId: string;
};

export const useUpdateEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ templateId, body }: UpdateEmailTemplateRequest) =>
      emailTemplatesApi.updateEmailTemplate(templateId, {
        ...body,
        template: base64Encode(JSON.stringify(body.template)),
        generatedHtml: base64Encode(body.generatedHtml),
      }),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.single, variables.templateId],
        });
        enqueueSnackbar(`${variables.body.name} was successfully updated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating your template.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (templateId: string) => emailTemplatesApi.deleteEmailTemplate(templateId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar('Your template was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your template.', {
          variant: 'error',
        });
      },
    }
  );
};
