import { default as axiosClient } from 'axios';
import Cookies from 'js-cookie';
import {
  COOKIE_KEY_CURRENT_ORGANIZATION_ID,
  COOKIE_KEY_CURRENT_ORGANIZATION_SUBDOMAIN,
  PERSONAL_ORGANIZATION_ID,
} from './utils/constants';

export const baseURL =
  process.env['NEXT_PUBLIC_SUBFLOW_API_ENDPOINT'] || 'http://localhost:3000';

// ----------------------------------------------------------------------

export const axios = axiosClient.create({
  baseURL: baseURL,
});

axios.interceptors.request.use(
  (config) => {
    const currentOrganizationId = getOrganizationId();
    if (currentOrganizationId) {
      config.headers['x-org-id'] = currentOrganizationId;
    }
    return config;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);

export const setToken = (token?: string) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const setOrganizationId = (organizationId: string) => {
  Cookies.set(COOKIE_KEY_CURRENT_ORGANIZATION_ID, organizationId, {
    domain: process.env['NEXT_PUBLIC_COOKIE_DOMAIN'],
    expires: 365, // expire in one year
    secure:
      process.env['NEXT_PUBLIC_COOKIE_DOMAIN'] !== 'localhost' &&
      process.env['NEXT_PUBLIC_COOKIE_DOMAIN'] !== 'subflow.local',
    sameSite: 'lax',
    path: '/',
  });
  return organizationId;
};

export const setOrganizationSubdomain = (organizationSubdomain: string) => {
  Cookies.set(
    COOKIE_KEY_CURRENT_ORGANIZATION_SUBDOMAIN,
    organizationSubdomain,
    {
      domain: process.env['NEXT_PUBLIC_COOKIE_DOMAIN'],
      expires: 365, // expire in one year
      secure:
        process.env['NEXT_PUBLIC_COOKIE_DOMAIN'] !== 'localhost' &&
        process.env['NEXT_PUBLIC_COOKIE_DOMAIN'] !== 'subflow.local',
      sameSite: 'lax',
      path: '/',
    }
  );
  return organizationSubdomain;
};

export const getOrganizationId = () => {
  const currentOrganizationId = Cookies.get(COOKIE_KEY_CURRENT_ORGANIZATION_ID);

  if (!currentOrganizationId) {
    return PERSONAL_ORGANIZATION_ID;
  }

  return currentOrganizationId;
};

export const getOrganizationSubdomain = () => {
  const currentOrganizationSubdomain = Cookies.get(
    COOKIE_KEY_CURRENT_ORGANIZATION_SUBDOMAIN
  );

  if (!currentOrganizationSubdomain) {
    return PERSONAL_ORGANIZATION_ID;
  }

  return currentOrganizationSubdomain;
};
