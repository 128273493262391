/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountResponseDto } from '../models';
// @ts-ignore
import { AssignRoleRequestDto } from '../models';
// @ts-ignore
import { ChangeEmailAddressRequestDto } from '../models';
// @ts-ignore
import { CreateAccountRequestDto } from '../models';
// @ts-ignore
import { CreateAccountResponseDto } from '../models';
// @ts-ignore
import { CredentialsResponseDto } from '../models';
// @ts-ignore
import { InvitationsResponseDto } from '../models';
// @ts-ignore
import { InviteMemberRequestDto } from '../models';
// @ts-ignore
import { KeycloakAccountDeviceDto } from '../models';
// @ts-ignore
import { MembersResponseDto } from '../models';
// @ts-ignore
import { UpdateAccountRequestDto } from '../models';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign role to member
         * @param {string} memberId 
         * @param {AssignRoleRequestDto} assignRoleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRole: async (memberId: string, assignRoleRequestDto: AssignRoleRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('assignRole', 'memberId', memberId)
            // verify required parameter 'assignRoleRequestDto' is not null or undefined
            assertParamExists('assignRole', 'assignRoleRequestDto', assignRoleRequestDto)
            const localVarPath = `/v1/auth/members/{memberId}/role`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignRoleRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrgInvitation: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('cancelOrgInvitation', 'invitationId', invitationId)
            const localVarPath = `/v1/auth/invitations/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update email address
         * @param {ChangeEmailAddressRequestDto} changeEmailAddressRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: async (changeEmailAddressRequestDto: ChangeEmailAddressRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeEmailAddressRequestDto' is not null or undefined
            assertParamExists('changeEmail', 'changeEmailAddressRequestDto', changeEmailAddressRequestDto)
            const localVarPath = `/v1/auth/settings/account/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailAddressRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/settings/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentials: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current organization invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgInvitations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current organization members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgMembers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current organization owner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgOwners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new organization invitation
         * @param {InviteMemberRequestDto} inviteMemberRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteOrgMember: async (inviteMemberRequestDto: InviteMemberRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteMemberRequestDto' is not null or undefined
            assertParamExists('inviteOrgMember', 'inviteMemberRequestDto', inviteMemberRequestDto)
            const localVarPath = `/v1/auth/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteMemberRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a credential for authenticated user
         * @param {string} credentialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCredential: async (credentialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentialId' is not null or undefined
            assertParamExists('removeCredential', 'credentialId', credentialId)
            const localVarPath = `/v1/auth/credentials/{credentialId}`
                .replace(`{${"credentialId"}}`, encodeURIComponent(String(credentialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a member from an organization
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrgMember: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeOrgMember', 'userId', userId)
            const localVarPath = `/v1/auth/members/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendOrgInvitation: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('resendOrgInvitation', 'invitationId', invitationId)
            const localVarPath = `/v1/auth/invitations/{invitationId}/resend`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revoke user session
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeSession: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('revokeSession', 'sessionId', sessionId)
            const localVarPath = `/v1/auth/sessions/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create account and organization
         * @summary Register a new account
         * @param {CreateAccountRequestDto} createAccountRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (createAccountRequestDto: CreateAccountRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountRequestDto' is not null or undefined
            assertParamExists('signup', 'createAccountRequestDto', createAccountRequestDto)
            const localVarPath = `/v1/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account settings
         * @param {UpdateAccountRequestDto} updateAccountRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountSettings: async (updateAccountRequestDto: UpdateAccountRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountRequestDto' is not null or undefined
            assertParamExists('updateAccountSettings', 'updateAccountRequestDto', updateAccountRequestDto)
            const localVarPath = `/v1/auth/settings/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign role to member
         * @param {string} memberId 
         * @param {AssignRoleRequestDto} assignRoleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRole(memberId: string, assignRoleRequestDto: AssignRoleRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRole(memberId, assignRoleRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrgInvitation(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrgInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update email address
         * @param {ChangeEmailAddressRequestDto} changeEmailAddressRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmail(changeEmailAddressRequestDto: ChangeEmailAddressRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmail(changeEmailAddressRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get account settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCredentials(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CredentialsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCredentials(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KeycloakAccountDeviceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current organization invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgInvitations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgInvitations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current organization members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgMembers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgMembers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current organization owner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgOwners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgOwners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new organization invitation
         * @param {InviteMemberRequestDto} inviteMemberRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteOrgMember(inviteMemberRequestDto: InviteMemberRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteOrgMember(inviteMemberRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a credential for authenticated user
         * @param {string} credentialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCredential(credentialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCredential(credentialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a member from an organization
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOrgMember(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOrgMember(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendOrgInvitation(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendOrgInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Revoke user session
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeSession(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeSession(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create account and organization
         * @summary Register a new account
         * @param {CreateAccountRequestDto} createAccountRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(createAccountRequestDto: CreateAccountRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAccountResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(createAccountRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update account settings
         * @param {UpdateAccountRequestDto} updateAccountRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountSettings(updateAccountRequestDto: UpdateAccountRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountSettings(updateAccountRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign role to member
         * @param {string} memberId 
         * @param {AssignRoleRequestDto} assignRoleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRole(memberId: string, assignRoleRequestDto: AssignRoleRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.assignRole(memberId, assignRoleRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrgInvitation(invitationId: string, options?: any): AxiosPromise<object> {
            return localVarFp.cancelOrgInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update email address
         * @param {ChangeEmailAddressRequestDto} changeEmailAddressRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(changeEmailAddressRequestDto: ChangeEmailAddressRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeEmail(changeEmailAddressRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get account settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSettings(options?: any): AxiosPromise<AccountResponseDto> {
            return localVarFp.getAccountSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentials(options?: any): AxiosPromise<CredentialsResponseDto> {
            return localVarFp.getCredentials(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(options?: any): AxiosPromise<Array<KeycloakAccountDeviceDto>> {
            return localVarFp.getDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current organization invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgInvitations(options?: any): AxiosPromise<InvitationsResponseDto> {
            return localVarFp.getOrgInvitations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current organization members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgMembers(options?: any): AxiosPromise<MembersResponseDto> {
            return localVarFp.getOrgMembers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current organization owner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgOwners(options?: any): AxiosPromise<InvitationsResponseDto> {
            return localVarFp.getOrgOwners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new organization invitation
         * @param {InviteMemberRequestDto} inviteMemberRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteOrgMember(inviteMemberRequestDto: InviteMemberRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.inviteOrgMember(inviteMemberRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a credential for authenticated user
         * @param {string} credentialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCredential(credentialId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeCredential(credentialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a member from an organization
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrgMember(userId: string, options?: any): AxiosPromise<object> {
            return localVarFp.removeOrgMember(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendOrgInvitation(invitationId: string, options?: any): AxiosPromise<object> {
            return localVarFp.resendOrgInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revoke user session
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeSession(sessionId: string, options?: any): AxiosPromise<object> {
            return localVarFp.revokeSession(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create account and organization
         * @summary Register a new account
         * @param {CreateAccountRequestDto} createAccountRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(createAccountRequestDto: CreateAccountRequestDto, options?: any): AxiosPromise<CreateAccountResponseDto> {
            return localVarFp.signup(createAccountRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update account settings
         * @param {UpdateAccountRequestDto} updateAccountRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountSettings(updateAccountRequestDto: UpdateAccountRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.updateAccountSettings(updateAccountRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Assign role to member
     * @param {string} memberId 
     * @param {AssignRoleRequestDto} assignRoleRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public assignRole(memberId: string, assignRoleRequestDto: AssignRoleRequestDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).assignRole(memberId, assignRoleRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel invitation
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public cancelOrgInvitation(invitationId: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).cancelOrgInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update email address
     * @param {ChangeEmailAddressRequestDto} changeEmailAddressRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public changeEmail(changeEmailAddressRequestDto: ChangeEmailAddressRequestDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).changeEmail(changeEmailAddressRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get account settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getAccountSettings(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getAccountSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getCredentials(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getCredentials(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getDevices(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current organization invitations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getOrgInvitations(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getOrgInvitations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current organization members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getOrgMembers(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getOrgMembers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current organization owner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getOrgOwners(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getOrgOwners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new organization invitation
     * @param {InviteMemberRequestDto} inviteMemberRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public inviteOrgMember(inviteMemberRequestDto: InviteMemberRequestDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).inviteOrgMember(inviteMemberRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a credential for authenticated user
     * @param {string} credentialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public removeCredential(credentialId: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).removeCredential(credentialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a member from an organization
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public removeOrgMember(userId: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).removeOrgMember(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend invitation
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public resendOrgInvitation(invitationId: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).resendOrgInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Revoke user session
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public revokeSession(sessionId: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).revokeSession(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create account and organization
     * @summary Register a new account
     * @param {CreateAccountRequestDto} createAccountRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public signup(createAccountRequestDto: CreateAccountRequestDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).signup(createAccountRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update account settings
     * @param {UpdateAccountRequestDto} updateAccountRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public updateAccountSettings(updateAccountRequestDto: UpdateAccountRequestDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).updateAccountSettings(updateAccountRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
