export interface ApiError {
  errorCode?: string;
  errorMessage?: string;
  statusCode: number;
  message: string;
}

export function isApiError(error: any | unknown): error is ApiError {
  return error.statusCode;
}
