import { combineReducers } from 'redux';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import featureFlagsReducer from './slices/featureFlag';

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  chat: chatReducer,
  calendar: calendarReducer,
  featureFlag: featureFlagsReducer,
  product: productReducer,
});

export { rootReducer };
