// ----------------------------------------------------------------------

import { useSubdomain } from '@subflow-frontend/sections/subscriber';
import { useMemo } from 'react';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/org';
const ROOTS_CONTENT = '/content';
export const ROOTS_SUBSCRIBER = '/sub';

export type CalendarQueryParams = {
  segmentId?: string;
  segmentName?: string;
  sequenceId?: string;
  sequenceName?: string;
  contactId?: string;
  contactEmail?: string;
};
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_CONTENT = {
  root: ROOTS_CONTENT,
  preview: {
    content: ({
      merchantId,
      contentId,
    }: {
      merchantId: string;
      contentId: string;
    }) =>
      `${path(
        ROOTS_CONTENT,
        '/preview'
      )}/?merchantId=${merchantId}&contentId=${contentId}`,
  },
  pages: {
    root: path(ROOTS_CONTENT, '/pages'),
    edit: ({ pageId }: { pageId: string }) =>
      `${PATH_CONTENT.pages.root}/${pageId}`,
    templates: {
      root: path(ROOTS_CONTENT, '/pages'),
      edit: ({ pageId }: { pageId: string }) =>
        `${PATH_CONTENT.pages.templates.root}/templates/${pageId}`,
    },
  },
  courses: {
    root: path(ROOTS_CONTENT, '/courses'),
    new: () => `${PATH_CONTENT.courses.root}/new}`,
    edit: (courseId: string) => `${PATH_CONTENT.courses.root}/${courseId}`,
  },
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_SUBSCRIBER = {
  settings: {
    root: path(ROOTS_SUBSCRIBER, '/settings'),
    edit: path(ROOTS_SUBSCRIBER, '/settings/edit'),
    subscriptions: {
      details: (subscriptionId) => {
        return {
          root: path(
            ROOTS_SUBSCRIBER,
            `/settings/subscriptions/${subscriptionId}`
          ),
          payments: path(
            ROOTS_SUBSCRIBER,
            `/settings/subscriptions/${subscriptionId}/payments`
          ),
        };
      },
    },
  },
};

export const PATH_SUBSCRIBER_SUBDOMAIN = (subdomain: string) => {
  const subscriberRoot = ROOTS_SUBSCRIBER + `/${subdomain}`;

  return {
    root: subscriberRoot,
    onboarding: (callbackUrl?: string) => ({
      pathname: subscriberRoot + '/onboarding',
      query: {
        callbackUrl: callbackUrl ?? '',
      },
    }),
    dashboard: path(subscriberRoot, '/dashboard'),
    chat: path(subscriberRoot, '/chat'),
    content: {
      root: path(subscriberRoot, '/content'),
      collections: () =>
        `${PATH_SUBSCRIBER_SUBDOMAIN(subdomain).content.root}/collections`,
      preview: ({
        contentId,
        parentCollections,
      }: {
        contentId: number;
        parentCollections?: string;
      }) => {
        return `${subscriberRoot}/content/preview/?contentId=${contentId}${
          parentCollections ? '&parentCollections=' + parentCollections : ''
        }`;
      },
      pages: () => `${PATH_SUBSCRIBER_SUBDOMAIN(subdomain).content.root}/pages`,
      page: ({ pageSlug }: { pageSlug: string }) => {
        return `${subscriberRoot}/p/${pageSlug}`;
      },
    },
    meetings: {
      root: `${subscriberRoot}/meetings`,
      meeting: (meetingId: string) =>
        `${PATH_SUBSCRIBER_SUBDOMAIN(subdomain).meetings.root}/${meetingId}`,
    },
    courses: {
      root: `${subscriberRoot}/courses`,
      course: (courseId: string) =>
        `${PATH_SUBSCRIBER_SUBDOMAIN(subdomain).courses.root}/${courseId}`,
    },
  };
};
export const useSubscriberPaths = () => {
  const { subdomain } = useSubdomain();

  return useMemo(() => {
    return { subscriberPaths: PATH_SUBSCRIBER_SUBDOMAIN(subdomain) };
  }, [subdomain]);
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, '/dashboard'),
  org: {
    settings: path(ROOTS_DASHBOARD, '/settings'),
  },
  store: {
    root: path(ROOTS_DASHBOARD, '/store'),
    subscriptions: path(ROOTS_DASHBOARD, '/store/subscriptions'),
    newSubscription: path(ROOTS_DASHBOARD, '/store/subscriptions/new'),
    customers: {
      root: path(ROOTS_DASHBOARD, '/store/customers'),
      edit: ({ phone }: { phone: string }) =>
        `${PATH_DASHBOARD.store.customers.root}/${encodeURIComponent(phone)}`,
      notes: ({ phone }: { phone: string }) => {
        return `${PATH_DASHBOARD.store.customers.edit({
          phone,
        })}/?activeTab=notes`;
      },
    },
  },
  communication: {
    root: path(ROOTS_DASHBOARD, '/communication'),
    feed: {
      root: path(ROOTS_DASHBOARD, '/communication/feed'),
    },
    templates: {
      root: path(ROOTS_DASHBOARD, '/communication/templates'),
      new: path(ROOTS_DASHBOARD, '/communication/templates/new'),
      scheduling: path(ROOTS_DASHBOARD, '/communication/templates/scheduling'),
    },
    surveys: {
      root: path(ROOTS_DASHBOARD, '/communication/surveys'),
      new: path(ROOTS_DASHBOARD, '/communication/surveys/new'),
      scheduling: path(ROOTS_DASHBOARD, '/communication/surveys/scheduling'),
    },
    sequences: {
      root: path(ROOTS_DASHBOARD, '/communication/sequences'),
      edit: ({
        sequenceId,
        activeTab,
      }: {
        sequenceId: string;
        activeTab: 'settings' | 'sequence' | 'subscribers';
      }) =>
        `${PATH_DASHBOARD.communication.sequences.root}/${sequenceId}?activeTab=${activeTab}`,
      new: path(ROOTS_DASHBOARD, '/communication/sequences/new'),
      scheduling: path(ROOTS_DASHBOARD, '/communication/sequences/scheduling'),
    },
    newsletter: {
      root: path(ROOTS_DASHBOARD, '/communication/newsletter'),
      rootTemplateTab: path(
        ROOTS_DASHBOARD,
        '/communication/newsletter?activeTab=template'
      ),
      new: path(ROOTS_DASHBOARD, '/communication/newsletter/new'),
      scheduling: path(ROOTS_DASHBOARD, '/communication/newsletter/scheduling'),
      emailCampaigns: {
        new: (
          query:
            | { step: 'configure'; duplicateEmailCampaignId: string }
            | { step: 'choose_template' }
            | { step: 'configure' }
            | { step: 'configure'; emailTemplateId: string }
            | { step: 'configure'; unlayerTemplateId: string }
        ) => {
          return {
            pathname: `${PATH_DASHBOARD.communication.newsletter.root}/email-campaign/new`,
            query,
          };
        },
        details: ({ emailCampaignId }: { emailCampaignId: string }) =>
          `${PATH_DASHBOARD.communication.newsletter.root}/email-campaign/${emailCampaignId}`,
        recipients: ({ emailCampaignId }: { emailCampaignId: string }) =>
          PATH_DASHBOARD.communication.newsletter.emailCampaigns.details({
            emailCampaignId,
          }) + '/recipients',
      },
    },
    meetings: {
      root: path(ROOTS_DASHBOARD, '/communication/meetings'),
      new: path(ROOTS_DASHBOARD, '/communication/meetings/new'),
      edit: (meetingId: string) =>
        `${PATH_DASHBOARD.communication.meetings.root}/edit/${meetingId}`,
      meeting: (meetingId: string) =>
        `${PATH_DASHBOARD.communication.meetings.root}/${meetingId}`,
    },
    email: {
      root: path(ROOTS_DASHBOARD, '/communication/email'),
      sent: path(ROOTS_DASHBOARD, '/communication/email/sent'),
    },
  },
  messaging: {
    root: path(ROOTS_DASHBOARD, '/messaging'),
    conversation: {
      root: path(ROOTS_DASHBOARD, '/messaging/conversations'),
      detail: path(ROOTS_DASHBOARD, '/messaging/conversations'),
    },
    chat: {
      root: path(ROOTS_DASHBOARD, '/messaging/chat'),
    },
    segments: {
      root: path(ROOTS_DASHBOARD, '/messaging/segments'),
      edit: (segmentId: string) =>
        `${PATH_DASHBOARD.messaging.segments.root}/${segmentId}`,
      viewContacts: (segmentId: string) =>
        `${PATH_DASHBOARD.messaging.segments.root}/${segmentId}/contacts`,
      new: path(ROOTS_DASHBOARD, '/messaging/segments/new'),
    },
    broadcasts: {
      root: path(ROOTS_DASHBOARD, '/messaging/broadcasts'),
      detail: path(ROOTS_DASHBOARD, '/messaging/broadcasts'),
    },
  },
  onboarding: path(ROOTS_DASHBOARD, '/onboarding'),
  resources: {
    welcome: path(ROOTS_DASHBOARD, '/resources/welcome'),
    createSubscription: path(ROOTS_DASHBOARD, '/resources/create-subscription'),
    sendMessages: path(ROOTS_DASHBOARD, '/resources/send-messages'),
  },
  team: {
    root: path(ROOTS_DASHBOARD, '/team'),
    users: {
      root: path(ROOTS_DASHBOARD, '/team/users'),
      new: path(ROOTS_DASHBOARD, '/team/users/new'),
    },
    permissions: path(ROOTS_DASHBOARD, '/team/permissions'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  calendar: {
    root: (query: CalendarQueryParams) => ({
      pathname: path(ROOTS_DASHBOARD, '/calendar'),
      query,
    }),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  settings: {
    tollFreeVerification: path(
      ROOTS_DASHBOARD,
      '/settings/toll-free-verification'
    ),
    payoutSettings: path(ROOTS_DASHBOARD, '/settings/payout-settings'),
  },
};

export const PATH_SUPPORT = {
  ticket: path(ROOTS_DASHBOARD, '/support/ticket'),
};

export const PATH_DOCS = '/docs';
export const PATH_LISTING = '/listing';
