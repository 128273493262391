/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateEmailCampaignRequestDto } from '../models';
// @ts-ignore
import { EmailCampaignDetailsResponseDto } from '../models';
// @ts-ignore
import { EmailCampaignResponseDto } from '../models';
// @ts-ignore
import { EmailCampaignsResponseDto } from '../models';
// @ts-ignore
import { PaginatedEmailCampaignRecipientsResponseDto } from '../models';
// @ts-ignore
import { UpdateEmailCampaignRequestDto } from '../models';
/**
 * EmailCampaignsApi - axios parameter creator
 * @export
 */
export const EmailCampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single email campaign
         * @param {CreateEmailCampaignRequestDto} createEmailCampaignRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmailCampaign: async (createEmailCampaignRequestDto: CreateEmailCampaignRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmailCampaignRequestDto' is not null or undefined
            assertParamExists('createEmailCampaign', 'createEmailCampaignRequestDto', createEmailCampaignRequestDto)
            const localVarPath = `/v1/email-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmailCampaignRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an email campaign
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailCampaign: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmailCampaign', 'id', id)
            const localVarPath = `/v1/email-campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple email campaigns
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEmailCampaigns: async (page?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/email-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find email campaign recipients
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {'delivered' | 'opened' | 'clicked' | 'unsubscribed' | 'permanent_fail' | 'complained' | 'opened_once' | 'clicked_once'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmailCampaignRecipients: async (id: string, page?: number, limit?: number, search?: string, status?: 'delivered' | 'opened' | 'clicked' | 'unsubscribed' | 'permanent_fail' | 'complained' | 'opened_once' | 'clicked_once', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findEmailCampaignRecipients', 'id', id)
            const localVarPath = `/v1/email-campaigns/{id}/recipients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a single email campaign
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneEmailCampaign: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneEmailCampaign', 'id', id)
            const localVarPath = `/v1/email-campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single email campaign
         * @param {string} id 
         * @param {UpdateEmailCampaignRequestDto} updateEmailCampaignRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailCampaign: async (id: string, updateEmailCampaignRequestDto: UpdateEmailCampaignRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmailCampaign', 'id', id)
            // verify required parameter 'updateEmailCampaignRequestDto' is not null or undefined
            assertParamExists('updateEmailCampaign', 'updateEmailCampaignRequestDto', updateEmailCampaignRequestDto)
            const localVarPath = `/v1/email-campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmailCampaignRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailCampaignsApi - functional programming interface
 * @export
 */
export const EmailCampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailCampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single email campaign
         * @param {CreateEmailCampaignRequestDto} createEmailCampaignRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmailCampaign(createEmailCampaignRequestDto: CreateEmailCampaignRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailCampaignDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmailCampaign(createEmailCampaignRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an email campaign
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmailCampaign(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailCampaignResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmailCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple email campaigns
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllEmailCampaigns(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailCampaignsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllEmailCampaigns(page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find email campaign recipients
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {'delivered' | 'opened' | 'clicked' | 'unsubscribed' | 'permanent_fail' | 'complained' | 'opened_once' | 'clicked_once'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmailCampaignRecipients(id: string, page?: number, limit?: number, search?: string, status?: 'delivered' | 'opened' | 'clicked' | 'unsubscribed' | 'permanent_fail' | 'complained' | 'opened_once' | 'clicked_once', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedEmailCampaignRecipientsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEmailCampaignRecipients(id, page, limit, search, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find a single email campaign
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneEmailCampaign(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailCampaignDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneEmailCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single email campaign
         * @param {string} id 
         * @param {UpdateEmailCampaignRequestDto} updateEmailCampaignRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmailCampaign(id: string, updateEmailCampaignRequestDto: UpdateEmailCampaignRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailCampaignDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmailCampaign(id, updateEmailCampaignRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailCampaignsApi - factory interface
 * @export
 */
export const EmailCampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailCampaignsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single email campaign
         * @param {CreateEmailCampaignRequestDto} createEmailCampaignRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmailCampaign(createEmailCampaignRequestDto: CreateEmailCampaignRequestDto, options?: any): AxiosPromise<EmailCampaignDetailsResponseDto> {
            return localVarFp.createEmailCampaign(createEmailCampaignRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an email campaign
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailCampaign(id: string, options?: any): AxiosPromise<EmailCampaignResponseDto> {
            return localVarFp.deleteEmailCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple email campaigns
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEmailCampaigns(page?: number, limit?: number, search?: string, options?: any): AxiosPromise<EmailCampaignsResponseDto> {
            return localVarFp.findAllEmailCampaigns(page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find email campaign recipients
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {'delivered' | 'opened' | 'clicked' | 'unsubscribed' | 'permanent_fail' | 'complained' | 'opened_once' | 'clicked_once'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmailCampaignRecipients(id: string, page?: number, limit?: number, search?: string, status?: 'delivered' | 'opened' | 'clicked' | 'unsubscribed' | 'permanent_fail' | 'complained' | 'opened_once' | 'clicked_once', options?: any): AxiosPromise<PaginatedEmailCampaignRecipientsResponseDto> {
            return localVarFp.findEmailCampaignRecipients(id, page, limit, search, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a single email campaign
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneEmailCampaign(id: string, options?: any): AxiosPromise<EmailCampaignDetailsResponseDto> {
            return localVarFp.findOneEmailCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single email campaign
         * @param {string} id 
         * @param {UpdateEmailCampaignRequestDto} updateEmailCampaignRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailCampaign(id: string, updateEmailCampaignRequestDto: UpdateEmailCampaignRequestDto, options?: any): AxiosPromise<EmailCampaignDetailsResponseDto> {
            return localVarFp.updateEmailCampaign(id, updateEmailCampaignRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailCampaignsApi - object-oriented interface
 * @export
 * @class EmailCampaignsApi
 * @extends {BaseAPI}
 */
export class EmailCampaignsApi extends BaseAPI {
    /**
     * 
     * @summary Create a single email campaign
     * @param {CreateEmailCampaignRequestDto} createEmailCampaignRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailCampaignsApi
     */
    public createEmailCampaign(createEmailCampaignRequestDto: CreateEmailCampaignRequestDto, options?: AxiosRequestConfig) {
        return EmailCampaignsApiFp(this.configuration).createEmailCampaign(createEmailCampaignRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an email campaign
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailCampaignsApi
     */
    public deleteEmailCampaign(id: string, options?: AxiosRequestConfig) {
        return EmailCampaignsApiFp(this.configuration).deleteEmailCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple email campaigns
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailCampaignsApi
     */
    public findAllEmailCampaigns(page?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return EmailCampaignsApiFp(this.configuration).findAllEmailCampaigns(page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find email campaign recipients
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [search] 
     * @param {'delivered' | 'opened' | 'clicked' | 'unsubscribed' | 'permanent_fail' | 'complained' | 'opened_once' | 'clicked_once'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailCampaignsApi
     */
    public findEmailCampaignRecipients(id: string, page?: number, limit?: number, search?: string, status?: 'delivered' | 'opened' | 'clicked' | 'unsubscribed' | 'permanent_fail' | 'complained' | 'opened_once' | 'clicked_once', options?: AxiosRequestConfig) {
        return EmailCampaignsApiFp(this.configuration).findEmailCampaignRecipients(id, page, limit, search, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a single email campaign
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailCampaignsApi
     */
    public findOneEmailCampaign(id: string, options?: AxiosRequestConfig) {
        return EmailCampaignsApiFp(this.configuration).findOneEmailCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single email campaign
     * @param {string} id 
     * @param {UpdateEmailCampaignRequestDto} updateEmailCampaignRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailCampaignsApi
     */
    public updateEmailCampaign(id: string, updateEmailCampaignRequestDto: UpdateEmailCampaignRequestDto, options?: AxiosRequestConfig) {
        return EmailCampaignsApiFp(this.configuration).updateEmailCampaign(id, updateEmailCampaignRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
