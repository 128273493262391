import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import {
  Configuration,
  CreateTemplateRequestDto,
  TemplateRequestDto,
  TemplatesApiFactory,
  UpdateScheduleRequestDto,
  UpdateTemplateRequestDto,
} from '@subflow/api-client';
import { QUERY_KEYS as BROADCAST_QUERY_KEYS } from './broadcasts';
import { ApiError } from 'libs/data-access/src/utils/error';
import { CalendarQueryParams } from '@subflow-frontend/routes/paths';

const config = new Configuration({
  basePath: baseURL,
});

const templatesApi = TemplatesApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'templates',
  templateTags = 'templateTags',
  single = 'template',
  schedules = 'schedules',
  schedule = 'schedule',
}

export function useTemplateMessages(params: TemplateRequestDto) {
  const { page, limit, tags } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, { page, limit, search, tags }],
    async () => {
      const response = await templatesApi.findAllTemplates({
        page,
        limit,
        search,
        tags,
      });
      const responseData = response.data;
      return {
        ...responseData,
        results: responseData?.results
          ? responseData.results.map((template) => ({
              id: template.id,
              title: template.name,
              content: template?.templateMessages?.[0]?.text,
              msgCount: template?.templateMessages?.length,
              rawData: template,
            }))
          : [],
      };
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your templates.', {
          variant: 'error',
        });
      },
    }
  );
}

export const useTemplate = (templateId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.single, templateId],
    async () => {
      const response = await templatesApi.findOneTemplate(templateId);
      return response.data;
    },
    {
      enabled: !!templateId,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your template.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useSchedule = (scheduleId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.schedule, scheduleId],
    async () => {
      const response = await templatesApi.findOneSchedule(scheduleId);
      return response.data;
    },
    {
      enabled: !!scheduleId,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your schedule.', {
          variant: 'error',
        });
      },
    }
  );
};

export function useSchedules(queryParams: CalendarQueryParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.schedules, queryParams],
    async () => {
      const response = await templatesApi.findAllSchedules(
        queryParams.sequenceId,
        queryParams.contactId,
        queryParams.segmentId
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your schedules.', {
          variant: 'error',
        });
      },
    }
  );
}

export const useCreateTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (body: CreateTemplateRequestDto) => templatesApi.createTemplate(body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar(`${variables.name} was successfully created!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating your template.', {
          variant: 'error',
        });
      },
    }
  );
};

interface DuplicateTemplateRequest {
  originalTemplateId: string;
}
export const useDuplicateTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (request: DuplicateTemplateRequest) =>
      templatesApi.duplicateTemplate(request.originalTemplateId),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar(`Template was successfully duplicated!`, {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem duplicating your template.', {
          variant: 'error',
        });
      },
    }
  );
};

interface updateTemplateRequestId {
  body: UpdateTemplateRequestDto;
  templateId: string;
}

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ body, templateId }: updateTemplateRequestId) =>
      templatesApi.updateTemplate(templateId, body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        queryClient.invalidateQueries([
          QUERY_KEYS.single,
          variables.templateId,
        ]);
        enqueueSnackbar(`${variables.body.name} was successfully updated!`, {
          variant: 'success',
        });
        return data.data;
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating your template.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (templateId: string) => templatesApi.deleteTemplate(templateId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.list] });
        enqueueSnackbar('Your template was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting your template.', {
          variant: 'error',
        });
      },
    }
  );
};

interface SendTemplateParams {
  templateId: string;
  contactIds?: string[];
  segmentIds?: string[];
}

export const useSendTemplate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ templateId, contactIds = [], segmentIds = [] }: SendTemplateParams) => {
      return templatesApi.sendTemplate({
        templateId,
        contactIds,
        segmentIds,
      });
    },
    {
      onSuccess: (_, variables) => {
        enqueueSnackbar('Sent template messages!', {
          variant: 'success',
          preventDuplicate: false,
        });

        //template sent to segment should show up in broadcast view
        if (variables?.segmentIds) {
          variables.segmentIds.forEach((segmentId) => {
            queryClient.invalidateQueries({
              queryKey: [BROADCAST_QUERY_KEYS.list, segmentId],
            });
          });
        }
      },
      onError: (e) => {
        console.log(e);

        enqueueSnackbar('Sending template failed!', {
          persist: true,
          variant: 'error',
          preventDuplicate: false,
        });
      },
    }
  );
};

export interface ScheduleTemplateParams {
  templateId: string;
  contactIds?: string[];
  segmentIds?: string[];
  sendTime: {
    year: number;
    month: number;
    day: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
}

export const scheduleTemplate = async ({
  templateId,
  contactIds = [],
  segmentIds = [],
  sendTime,
}: ScheduleTemplateParams): Promise<any> => {
  const response = await templatesApi.scheduleTemplate({
    templateId,
    contactIds,
    segmentIds,
    sendTime,
  });
  return response;
};

export const useCancelSchedule = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (scheduleId: string) => templatesApi.cancelSchedule(scheduleId),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(QUERY_KEYS.schedules);
        await queryClient.invalidateQueries([QUERY_KEYS.schedule, variables]);
        enqueueSnackbar('Your schedule was successfully cancelled!', {
          variant: 'success',
        });
      },
      onError: (error: ApiError) => {
        if (error?.errorCode === 'scheduleAlreadySent') {
          enqueueSnackbar('You cannot cancel a message that is already sent.', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('There was a problem cancelling your schedule.', {
            variant: 'error',
          });
        }
      },
    }
  );
};

export const useRetrySchedule = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (scheduleId: string) => templatesApi.retrySchedule(scheduleId),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(QUERY_KEYS.schedules);
        await queryClient.invalidateQueries([QUERY_KEYS.schedule, variables]);
        enqueueSnackbar('Your schedule was successfully retried!', {
          variant: 'success',
        });
      },
      onError: (error: ApiError) => {
        if (error?.errorCode === 'scheduleNotFailed') {
          enqueueSnackbar('Only failed schedules may be retried.', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('There was a problem retrying your schedule.', {
            variant: 'error',
          });
        }
      },
    }
  );
};

interface UpdateScheduleMethodParams {
  scheduleId: string;
  body: UpdateScheduleRequestDto;
}

export const useUpdateSchedule = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ scheduleId, body }: UpdateScheduleMethodParams) =>
      templatesApi.updateSchedule(scheduleId, body),
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.schedules] });
        queryClient.invalidateQueries([
          QUERY_KEYS.schedule,
          variables.scheduleId,
        ]);
        enqueueSnackbar(`Schedule was successfully updated!`, {
          variant: 'success',
        });
        return data.data;
      },
      onError: (error: ApiError) => {
        if (error?.errorCode === 'scheduleAlreadySent') {
          enqueueSnackbar('You cannot edit a schedule that is already sent.', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('There was a problem updating your schedule.', {
            variant: 'error',
          });
        }
      },
    }
  );
};

export function useTemplateTags() {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.templateTags],
    async () => {
      const response = await templatesApi.findAllUniqueTemplateTags();
      const responseData = response.data;
      return responseData;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your tag templates.', {
          variant: 'error',
        });
      },
    }
  );
}
