/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Merchant } from '../models';
// @ts-ignore
import { MerchantPublicDataResponseDto } from '../models';
// @ts-ignore
import { MerchantResponseDto } from '../models';
// @ts-ignore
import { MerchantsPublicDataRequestDto } from '../models';
// @ts-ignore
import { MerchantsPublicDataResponseDto } from '../models';
// @ts-ignore
import { UpdateMerchantLastVisitedResourceIdRequestDto } from '../models';
// @ts-ignore
import { UpdateMerchantRequestDto } from '../models';
/**
 * MerchantsApi - axios parameter creator
 * @export
 */
export const MerchantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive a current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveMerchant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/merchants/archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify if merchant exists with a certain subdomain
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMerchantBySubdomain: async (subdomain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('checkMerchantBySubdomain', 'subdomain', subdomain)
            const localVarPath = `/v1/merchants/{subdomain}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Permanently delete current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMerchant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/merchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneMerchant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/merchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve merchants public data
         * @param {MerchantsPublicDataRequestDto} merchantsPublicDataRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicDataForMerchants: async (merchantsPublicDataRequestDto: MerchantsPublicDataRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantsPublicDataRequestDto' is not null or undefined
            assertParamExists('getPublicDataForMerchants', 'merchantsPublicDataRequestDto', merchantsPublicDataRequestDto)
            const localVarPath = `/v1/merchants/merchants-public-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(merchantsPublicDataRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve merchant public data by subdomain
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicMerchantBySubdomain: async (subdomain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('getPublicMerchantBySubdomain', 'subdomain', subdomain)
            const localVarPath = `/v1/merchants/{subdomain}/details`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve merchant public data
         * @param {string} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicMerchantData: async (merchantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            assertParamExists('getPublicMerchantData', 'merchantId', merchantId)
            const localVarPath = `/v1/merchants/public/{merchantId}`
                .replace(`{${"merchantId"}}`, encodeURIComponent(String(merchantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if merchant canceled their account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCanceled: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/merchants/is-canceled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recover a current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverMerchant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/merchants/recover`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current user merchant
         * @param {UpdateMerchantRequestDto} updateMerchantRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerchant: async (updateMerchantRequestDto: UpdateMerchantRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMerchantRequestDto' is not null or undefined
            assertParamExists('updateMerchant', 'updateMerchantRequestDto', updateMerchantRequestDto)
            const localVarPath = `/v1/merchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMerchantRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current user merchant last visited resource id
         * @param {UpdateMerchantLastVisitedResourceIdRequestDto} updateMerchantLastVisitedResourceIdRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerchantLastVisitedResourceId: async (updateMerchantLastVisitedResourceIdRequestDto: UpdateMerchantLastVisitedResourceIdRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMerchantLastVisitedResourceIdRequestDto' is not null or undefined
            assertParamExists('updateMerchantLastVisitedResourceId', 'updateMerchantLastVisitedResourceIdRequestDto', updateMerchantLastVisitedResourceIdRequestDto)
            const localVarPath = `/v1/merchants/resource-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMerchantLastVisitedResourceIdRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current user merchant onboarding history skipped-account-payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerchantSkippedPaymentAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/merchants/skipped-payment-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantsApi - functional programming interface
 * @export
 */
export const MerchantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MerchantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive a current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveMerchant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveMerchant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify if merchant exists with a certain subdomain
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkMerchantBySubdomain(subdomain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkMerchantBySubdomain(subdomain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Permanently delete current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMerchant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMerchant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneMerchant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneMerchant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve merchants public data
         * @param {MerchantsPublicDataRequestDto} merchantsPublicDataRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicDataForMerchants(merchantsPublicDataRequestDto: MerchantsPublicDataRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantsPublicDataResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicDataForMerchants(merchantsPublicDataRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve merchant public data by subdomain
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicMerchantBySubdomain(subdomain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantPublicDataResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicMerchantBySubdomain(subdomain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve merchant public data
         * @param {string} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicMerchantData(merchantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantPublicDataResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicMerchantData(merchantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if merchant canceled their account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isCanceled(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isCanceled(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recover a current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverMerchant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverMerchant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current user merchant
         * @param {UpdateMerchantRequestDto} updateMerchantRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMerchant(updateMerchantRequestDto: UpdateMerchantRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMerchant(updateMerchantRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current user merchant last visited resource id
         * @param {UpdateMerchantLastVisitedResourceIdRequestDto} updateMerchantLastVisitedResourceIdRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMerchantLastVisitedResourceId(updateMerchantLastVisitedResourceIdRequestDto: UpdateMerchantLastVisitedResourceIdRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMerchantLastVisitedResourceId(updateMerchantLastVisitedResourceIdRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current user merchant onboarding history skipped-account-payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMerchantSkippedPaymentAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Merchant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMerchantSkippedPaymentAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MerchantsApi - factory interface
 * @export
 */
export const MerchantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MerchantsApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive a current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveMerchant(options?: any): AxiosPromise<MerchantResponseDto> {
            return localVarFp.archiveMerchant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify if merchant exists with a certain subdomain
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMerchantBySubdomain(subdomain: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkMerchantBySubdomain(subdomain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Permanently delete current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMerchant(options?: any): AxiosPromise<MerchantResponseDto> {
            return localVarFp.deleteMerchant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneMerchant(options?: any): AxiosPromise<MerchantResponseDto> {
            return localVarFp.findOneMerchant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve merchants public data
         * @param {MerchantsPublicDataRequestDto} merchantsPublicDataRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicDataForMerchants(merchantsPublicDataRequestDto: MerchantsPublicDataRequestDto, options?: any): AxiosPromise<MerchantsPublicDataResponseDto> {
            return localVarFp.getPublicDataForMerchants(merchantsPublicDataRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve merchant public data by subdomain
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicMerchantBySubdomain(subdomain: string, options?: any): AxiosPromise<MerchantPublicDataResponseDto> {
            return localVarFp.getPublicMerchantBySubdomain(subdomain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve merchant public data
         * @param {string} merchantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicMerchantData(merchantId: string, options?: any): AxiosPromise<MerchantPublicDataResponseDto> {
            return localVarFp.getPublicMerchantData(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if merchant canceled their account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCanceled(options?: any): AxiosPromise<boolean> {
            return localVarFp.isCanceled(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recover a current user merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverMerchant(options?: any): AxiosPromise<MerchantResponseDto> {
            return localVarFp.recoverMerchant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current user merchant
         * @param {UpdateMerchantRequestDto} updateMerchantRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerchant(updateMerchantRequestDto: UpdateMerchantRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateMerchant(updateMerchantRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current user merchant last visited resource id
         * @param {UpdateMerchantLastVisitedResourceIdRequestDto} updateMerchantLastVisitedResourceIdRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerchantLastVisitedResourceId(updateMerchantLastVisitedResourceIdRequestDto: UpdateMerchantLastVisitedResourceIdRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateMerchantLastVisitedResourceId(updateMerchantLastVisitedResourceIdRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current user merchant onboarding history skipped-account-payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerchantSkippedPaymentAccount(options?: any): AxiosPromise<Merchant> {
            return localVarFp.updateMerchantSkippedPaymentAccount(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantsApi - object-oriented interface
 * @export
 * @class MerchantsApi
 * @extends {BaseAPI}
 */
export class MerchantsApi extends BaseAPI {
    /**
     * 
     * @summary Archive a current user merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public archiveMerchant(options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).archiveMerchant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify if merchant exists with a certain subdomain
     * @param {string} subdomain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public checkMerchantBySubdomain(subdomain: string, options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).checkMerchantBySubdomain(subdomain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Permanently delete current user merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public deleteMerchant(options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).deleteMerchant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve current user merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public findOneMerchant(options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).findOneMerchant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve merchants public data
     * @param {MerchantsPublicDataRequestDto} merchantsPublicDataRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public getPublicDataForMerchants(merchantsPublicDataRequestDto: MerchantsPublicDataRequestDto, options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).getPublicDataForMerchants(merchantsPublicDataRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve merchant public data by subdomain
     * @param {string} subdomain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public getPublicMerchantBySubdomain(subdomain: string, options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).getPublicMerchantBySubdomain(subdomain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve merchant public data
     * @param {string} merchantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public getPublicMerchantData(merchantId: string, options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).getPublicMerchantData(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if merchant canceled their account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public isCanceled(options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).isCanceled(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recover a current user merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public recoverMerchant(options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).recoverMerchant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current user merchant
     * @param {UpdateMerchantRequestDto} updateMerchantRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public updateMerchant(updateMerchantRequestDto: UpdateMerchantRequestDto, options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).updateMerchant(updateMerchantRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current user merchant last visited resource id
     * @param {UpdateMerchantLastVisitedResourceIdRequestDto} updateMerchantLastVisitedResourceIdRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public updateMerchantLastVisitedResourceId(updateMerchantLastVisitedResourceIdRequestDto: UpdateMerchantLastVisitedResourceIdRequestDto, options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).updateMerchantLastVisitedResourceId(updateMerchantLastVisitedResourceIdRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current user merchant onboarding history skipped-account-payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public updateMerchantSkippedPaymentAccount(options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).updateMerchantSkippedPaymentAccount(options).then((request) => request(this.axios, this.basePath));
    }
}
