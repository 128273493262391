import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { axios, baseURL } from './axios';
import {
  Configuration,
  TagsApiFactory,
  TemplateRequestDto,
} from '@subflow/api-client';

const config = new Configuration({
  basePath: baseURL,
});

const tagsApi = TagsApiFactory(config, baseURL, axios);

export enum TAG_QUERY_KEYS {
  infiniteList = 'infiniteList',
  list = 'list',
}

export interface PaginatedTagsParams {
  page?: number;
  limit?: number;
  search?: string;
}

export function useInfiniteTags(params: Omit<PaginatedTagsParams, 'page'>) {
  const { limit } = params;
  const search = params.search ?? '';

  const { enqueueSnackbar } = useSnackbar();
  return useInfiniteQuery(
    [TAG_QUERY_KEYS.infiniteList, limit, search],
    async ({ pageParam = 1 }) => {
      const response = await tagsApi.findAllTags(pageParam, limit, search);
      return {
        ...response.data,
        results: response.data?.results || [],
      };
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your tags.', {
          variant: 'error',
        });
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.results.length === 0) return undefined;

        return lastPage.page + 1 ?? undefined;
      },
      getPreviousPageParam: (lastPage) => {
        if (lastPage.results.length === 0) return undefined;

        return lastPage.page - 1 ?? undefined;
      },
    }
  );
}

export function useTags(params: { limit?: number; search?: string }) {
  const limit = params.limit ?? 45;

  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [TAG_QUERY_KEYS.list, { ...params, limit }],
    async () => {
      const response = await tagsApi.findAllTags(1, limit, params.search);
      return response.data;
    },
    {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('There was a problem fetching your tags.', {
          variant: 'error',
        });
      },
    }
  );
}
