import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  Configuration,
  ContactNotesApiFactory,
  CreateContactNoteRequestDto,
  EditContactNoteRequestDto,
} from '@subflow/api-client';

import { axios, baseURL } from './axios';

const config = new Configuration({
  basePath: baseURL,
});

const contactNotesApi = ContactNotesApiFactory(config, baseURL, axios);

enum QUERY_KEYS {
  list = 'list',
  single = 'contact-note',
}

export interface FindContactNotesParams {
  limit?: number;
  page?: number;
  search?: string;
  contactId: string;
}

export const useCreateContactNote = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({
      contactId,
      body,
    }: {
      contactId: string;
      body: CreateContactNoteRequestDto;
    }) => contactNotesApi.createContactNote(contactId, body),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([QUERY_KEYS.single, variables.contactId]);
        queryClient.invalidateQueries([QUERY_KEYS.list]);
        enqueueSnackbar('The contact note was successfully created!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem creating the contact note.', {
          variant: 'error',
        });
      },
    }
  );
};

export const useUpdateContactNote = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({
      contactId,
      noteId,
      body,
    }: {
      contactId: string;
      noteId: string;
      body: EditContactNoteRequestDto;
    }) => contactNotesApi.updateContactNote(contactId, noteId, body),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([QUERY_KEYS.single, variables.contactId]);
        queryClient.invalidateQueries([QUERY_KEYS.list]);
        enqueueSnackbar('The contact note was successfully updated!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem updating the contact note.', {
          variant: 'error',
        });
      },
    }
  );
};
export const useDeleteContactNote = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ contactId, noteId }: { contactId: string; noteId: string }) =>
      contactNotesApi.deleteContactNote(contactId, noteId),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([QUERY_KEYS.single, variables.contactId]);
        queryClient.invalidateQueries([QUERY_KEYS.list]);
        enqueueSnackbar('The contact note was successfully deleted!', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('There was a problem deleting the contact note.', {
          variant: 'error',
        });
      },
    }
  );
};
export function useContactNotes({
  contactId,
  limit,
  page,
  search,
}: FindContactNotesParams) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    [QUERY_KEYS.list, contactId, limit, page, search],
    async () => {
      const response = await contactNotesApi.findContactNotes(
        contactId,
        page,
        limit,
        search
      );
      return response.data;
    },
    {
      onError: () => {
        enqueueSnackbar('There was a problem fetching your contact notes.', {
          variant: 'error',
        });
      },
    }
  );
}
