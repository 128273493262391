/* tslint:disable */
/* eslint-disable */
/**
 * Subflow API
 * Subflow Merchant API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CardInfoResponseDto } from '../models';
// @ts-ignore
import { PaginatedInvoiceHistoryResponseDto } from '../models';
/**
 * UsersSettingsApi - axios parameter creator
 * @export
 */
export const UsersSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a Session for the Account Portal on Stripe
         * @summary Create an Account Portal Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountPortalSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/settings/create/account/portal/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Session for the Customer Billing Portal on Stripe
         * @summary Create a Customer Portal Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingPortalSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/settings/create/billing/portal/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a Card Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCardInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/settings/card/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve list of invoices
         * @param {number} [limit] 
         * @param {string} [startingAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerInvoiceHistory: async (limit?: number, startingAfter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/settings/invoice/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startingAfter !== undefined) {
                localVarQueryParameter['startingAfter'] = startingAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersSettingsApi - functional programming interface
 * @export
 */
export const UsersSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a Session for the Account Portal on Stripe
         * @summary Create an Account Portal Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccountPortalSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccountPortalSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Session for the Customer Billing Portal on Stripe
         * @summary Create a Customer Portal Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBillingPortalSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBillingPortalSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a Card Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCardInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCardInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve list of invoices
         * @param {number} [limit] 
         * @param {string} [startingAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerInvoiceHistory(limit?: number, startingAfter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInvoiceHistoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCustomerInvoiceHistory(limit, startingAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersSettingsApi - factory interface
 * @export
 */
export const UsersSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersSettingsApiFp(configuration)
    return {
        /**
         * Creates a Session for the Account Portal on Stripe
         * @summary Create an Account Portal Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountPortalSession(options?: any): AxiosPromise<string> {
            return localVarFp.createAccountPortalSession(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Session for the Customer Billing Portal on Stripe
         * @summary Create a Customer Portal Session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingPortalSession(options?: any): AxiosPromise<string> {
            return localVarFp.createBillingPortalSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a Card Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCardInfo(options?: any): AxiosPromise<CardInfoResponseDto> {
            return localVarFp.findCardInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve list of invoices
         * @param {number} [limit] 
         * @param {string} [startingAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerInvoiceHistory(limit?: number, startingAfter?: string, options?: any): AxiosPromise<PaginatedInvoiceHistoryResponseDto> {
            return localVarFp.findCustomerInvoiceHistory(limit, startingAfter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersSettingsApi - object-oriented interface
 * @export
 * @class UsersSettingsApi
 * @extends {BaseAPI}
 */
export class UsersSettingsApi extends BaseAPI {
    /**
     * Creates a Session for the Account Portal on Stripe
     * @summary Create an Account Portal Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersSettingsApi
     */
    public createAccountPortalSession(options?: AxiosRequestConfig) {
        return UsersSettingsApiFp(this.configuration).createAccountPortalSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Session for the Customer Billing Portal on Stripe
     * @summary Create a Customer Portal Session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersSettingsApi
     */
    public createBillingPortalSession(options?: AxiosRequestConfig) {
        return UsersSettingsApiFp(this.configuration).createBillingPortalSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a Card Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersSettingsApi
     */
    public findCardInfo(options?: AxiosRequestConfig) {
        return UsersSettingsApiFp(this.configuration).findCardInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve list of invoices
     * @param {number} [limit] 
     * @param {string} [startingAfter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersSettingsApi
     */
    public findCustomerInvoiceHistory(limit?: number, startingAfter?: string, options?: AxiosRequestConfig) {
        return UsersSettingsApiFp(this.configuration).findCustomerInvoiceHistory(limit, startingAfter, options).then((request) => request(this.axios, this.basePath));
    }
}
